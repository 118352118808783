import React, { useLayoutEffect } from 'react'
import { View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'

import { HeaderNavigator, Preloader } from 'components/src/components'
import { useStyles } from './styles'
import { useFetchData } from './hooks'
import { SingleContent, HotelList, HotelInfo } from './components'

export const HotelContentHomeScreen = ({ route, navigation }) => {
  const { params } = route
  const { styles } = useStyles()

  const { content, isLoading, hotels } = useFetchData(params.itemUuid)

  const { t } = useTranslation()

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addBackButton addLogo {...props} />,
    })
  }, [navigation])

  if (params.hasOwnProperty('siteUuid')) {
    return (
      <View style={styles.container}>
        {isLoading ? (
          <Preloader />
        ) : (
          hotels && (
            <HotelInfo
              data={hotels.filter((item) => item.Uuid === params.siteUuid)[0]}
              hideAutoComplete={params?.hideAutoComplete}
            />
          )
        )}
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {isLoading || !content ? (
        <Preloader />
      ) : content.length !== 0 ? (
        content.length === 1 && content[0].ContentItemTypeId === 36 ? (
          <HotelList data={hotels} countryCode={params.countryCode || null} />
        ) : (
          <SingleContent data={content[0]} />
        )
      ) : (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyTitle}>{t('titles.empty_tiles')}</Text>
        </View>
      )}
    </View>
  )
}
