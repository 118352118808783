import { call, put, take } from 'redux-saga/effects'

import { CommonActions } from '@react-navigation/native'
// Local Dir
import { apiProfile } from '../../api'
// Local
import { profileActions } from 'components/src/bus/profile'
import { types } from '../../types'
import { uiActions } from 'components/src/bus/ui'

export function* createProfile(action) {
  try {
    yield put(profileActions.toggleLoading(true))

    // Api call or some async actions
    const response = yield call(apiProfile.createProfile, action.payload)

    if (response.data) {
      yield put(
        profileActions.signInAsync({
          Username: action.payload.Username,
          Password: action.payload.Password,
        })
      )
    } else {
      yield put(uiActions.emitError({ message: 'form.error.username_exists' }))
    }
  } catch (error) {
    if (error.message.includes('500')) {
      yield put(profileActions.toggleIsAccountAlreadyExist(true))
    }
    yield put(profileActions.toggleLoading(false))
    yield put(uiActions.emitError(error, 'Profile createProfile workers'))
  }
}

export function* createBookmarkedSites(action) {
  try {
    const { data } = yield call([apiProfile, 'createBookmarkedSites'], {
      Uuid: action.payload,
    })

    if (data && data.Status === 'OK') {
      yield put(profileActions.createBookmarkedSites(data.Item))
    }
  } catch (error) {
    console.log(error.response)
    yield put(uiActions.emitError(error, 'Profile createProfile workers'))
  }
}

export function* createRedirect(action) {
  try {
    yield take(types.END_SIGN_IN)
    yield call(
      action.payload.navigation.dispatch,
      CommonActions.navigate(action.payload.name, {
        params: action.payload.params,
      })
    )
  } catch (error) {
    yield put(uiActions.emitError(error, 'Profile createProfile workers'))
  } finally {
    yield put({ type: types.DELETE_REDIRECT })
  }
}
