import { createSlice } from '@reduxjs/toolkit'
import { types } from './types'

const initialState = {
  current: null,
  loading: false,
  bookmarkedSites: null,
  isWrongData: false,
  isAccountAlreadyExist: false,
}

const slice = createSlice({
  name: 'PROFILE',
  initialState,
  reducers: {
    toggleLoading: (state, action) => {
      state.loading = action.payload
    },
    saveCurrentProfile: (state, action) => {
      state.current = action.payload
    },
    clearCurrentProfile: (state) => {
      state.current = null
    },
    saveBookmarkedSites: (state, action) => {
      state.bookmarkedSites = action.payload
    },
    createBookmarkedSites: (state, action) => {
      state.bookmarkedSites = [...state.bookmarkedSites, action.payload]
    },
    deleteBookmarkedSites: (state, action) => {
      state.bookmarkedSites = state.bookmarkedSites
        ? state.bookmarkedSites.filter((item) => item.Uuid !== action.payload)
        : null
    },
    clearBookmarkedSites: (state) => {
      state.bookmarkedSites = null
    },
    toggleIsWrongData: (state, action) => {
      state.isWrongData = action.payload
    },
    toggleIsAccountAlreadyExist: (state, action) => {
      state.isAccountAlreadyExist = action.payload
    },
  },
})

export default slice.reducer

//Action Creators Async
export const profileActions = {
  ...slice.actions,
  //async
  signInAsync: (payload) => ({
    type: types.SIGN_IN,
    payload,
  }),
  createProfileAsync: (payload) => ({
    type: types.CREATE_PROFILE,
    payload,
  }),
  fetchProfileAsync: () => ({
    type: types.FETCH_PROFILE,
  }),
  forceLogoutAsync: () => ({
    type: types.FORCE_LOGOUT,
  }),
  autoLoginAsync: () => ({
    type: types.AUTO_LOGIN,
  }),
  updateProfileAsync: (payload) => ({
    type: types.UPDATE_PROFILE,
    payload,
  }),
  updatePasswordAsync: (payload) => ({
    type: types.UPDATE_PASSWORD,
    payload,
  }),
  resetPasswordAsync: (payload) => ({
    type: types.RESET_PASSWORD,
    payload,
  }),
  fetchBookmarkedSitesAsync: (payload) => ({
    type: types.FETCH_BOOKMARKED_SITES,
    payload,
  }),
  createBookmarkedSitesAsync: (payload) => ({
    type: types.CREATE_BOOKMARKED_SITES,
    payload,
  }),
  deleteBookmarkedSitesAsync: (payload) => ({
    type: types.DELETE_BOOKMARKED_SITES,
    payload,
  }),
  createRedirect: (payload) => ({
    type: types.CREATE_REDIRECT,
    payload,
  }),
  deleteRedirect: () => ({
    type: types.DELETE_REDIRECT,
  }),
}
