import { StyleSheet, Dimensions } from 'react-native'

import { useTheme } from 'components/src/hooks'
import {
  getStatusBarHeight,
  getBottomSpace,
} from 'components/src/services/IphoneXHelper'

const height = Dimensions.get('window').height
const containerHeight =
  height - 60 - getStatusBarHeight() - 18 - getBottomSpace() - 50

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        marginTop: 6,
        height: containerHeight,
      },
      pdfContainer: {
        flexGrow: 1,
        height: '100%',
      },
      preloadContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: [{ translateX: -15 }, { translateY: -15 }],
      },
      containerError: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: pallete.containerBackground,
      },
      errorText: {
        color: pallete.primary,
        fontSize: 20,
        textAlign: 'center',
        paddingLeft: 15,
        paddingRight: 15,
      },
      errorIcon: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        width: 50,
        borderRadius: 30,
        borderWidth: 2,
        marginBottom: 25,
        borderColor: pallete.primary,
      },
    }),
    stylesWeb: StyleSheet.create({
      closeButton: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
        right: 15,
        top: 15,
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: pallete.cardBackground,
      },
      pdfWrapper: {
        height: height,
        overflow: 'scroll',
        marginBottom: 40,
        alignItems: 'center',
      },
      pageWrapper: {
        borderBottomWidth: 1,
        borderTopWidth: 1,
      },
    }),
  }
}
