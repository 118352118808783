import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
//local
import { CardIcon } from 'components/src/components'
import { useStyles } from './styles'

export const BenefitCard = ({ name, icon, style, onPress }) => {
  const { styles } = useStyles()
  const { t } = useTranslation()
  return (
    <View style={[styles.container, style]}>
      <View style={styles.imageWrapper}>
        <CardIcon svg={icon} height={55} width={55} fill="#ffffff" />
      </View>
      <View style={styles.bodyWrapper}>
        <Text style={styles.title}>{name}</Text>
        <TouchableOpacity
          style={styles.button}
          activeOpacity={0.8}
          onPress={onPress}
        >
          <Text style={styles.buttonText}>{t('buttons.activate_now')}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

BenefitCard.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  style: PropTypes.object,
  onPress: PropTypes.func,
}

BenefitCard.defaultProps = {
  onPress: () => {},
}
