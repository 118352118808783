import { StyleSheet, Dimensions } from 'react-native'
//Local
import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'
import {
  getBottomSpace,
  getStatusBarHeight,
} from 'components/src/services/IphoneXHelper'

const { height } = Dimensions.get('screen')

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        paddingTop: 18,
        alignItems: 'center',
      },
      spacing: {
        height: 20,
      },
      cardsWrapper: {
        flexShrink: 1,
        width: '100%',
        maxWidth: 337,
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 20,
        paddingLeft: 6,
        paddingRight: 6,
      },
      preloader: {
        height: 200,
      },
      cardsWrapperSpacing: { marginTop: 60, width: '100%' },
      emptyContainer: {
        height: height - getStatusBarHeight() - getBottomSpace() - 280,
        paddingHorizontal: 16,
        alignItems: 'center',
        justifyContent: 'center',
      },
      emptyText: {
        ...fontFamilies.cl_regular,
        textAlign: 'center',
        fontSize: 24,
        backgroundColor: 'rgba(255,255,255,.65)',
      },
    }),
    pallete,
  }
}
