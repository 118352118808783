import { StyleSheet } from 'react-native'
import { useTheme } from 'components/src/hooks'
import { fontFamilies } from '../../configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()

  return {
    styles: StyleSheet.create({
      container: {
        width: 300,
        height: 120,
        backgroundColor: pallete.benefitsCardBackground,
        flexDirection: 'row',
        paddingHorizontal: 25,
      },
      imageWrapper: {
        flexGrow: 0,
        flexShrink: 0,
        alignItems: 'center',
        justifyContent: 'center',
      },
      bodyWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'center',
        justifyContent: 'space-around',
        marginLeft: 20,
      },
      title: {
        fontSize: 20,
        ...fontFamilies.cl_bold,
        color: pallete.benefitsCardColor,
      },
      button: {
        backgroundColor: pallete.benefitsCardButtonBackground,
        width: 160,
        height: 26,
        justifyContent: 'center',
      },
      buttonText: {
        color: pallete.benefitsCardButtonColor,
        fontSize: 16,
        textAlign: 'center',
      },
    }),
  }
}
