import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

//local
import { ButtonIcon, ModalChangeLanguage } from 'components/src/components'

export const ButtonChangeLanguage = () => {
  const { t } = useTranslation()
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <>
      <ButtonIcon
        icon="flag"
        label={t('buttons.select_language')}
        onPress={() => setModalVisible(true)}
      />
      <ModalChangeLanguage
        modalVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        onChange={() => setModalVisible(false)}
      />
    </>
  )
}
