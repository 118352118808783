import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        paddingTop: 4,
        backgroundColor: pallete.containerBackground,
      },
      content: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 500,
        marginTop: 30,
        paddingLeft: 10,
        paddingRight: 10,
      },
      divider: {
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 1,
        width: '95%',
        backgroundColor: pallete.divider,
        marginBottom: 15,
      },
      title: {
        fontSize: 22,
        color: pallete.actionText,
        ...fontFamilies.cl_bold,
        marginBottom: 26,
      },
      thanksText: {
        ...fontFamilies.os_light,
        fontSize: 18,
        color: pallete.defaultText,
        marginTop: 10,
        marginBottom: 30,
        maxWidth: 300,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
      },
      button: {
        width: '100%',
        maxWidth: 310,
        height: 42,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    }),
    cartItemStyles: StyleSheet.create({
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 15,
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 16,
        color: pallete.defaultText,
      },
      tax: {
        ...fontFamilies.cl_regular,
        fontSize: 14,
        color: pallete.defaultText,
        marginLeft: 8,
      },
      paragraph: {
        ...fontFamilies.os_light,
        fontSize: 16,
        color: pallete.defaultText,
      },
      count: {
        fontSize: 16,
        ...fontFamilies.cl_bold,
        marginLeft: 20,
        color: pallete.defaultText,
      },
      header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
      },
      priceContainer: {
        flexDirection: 'row',
        alignItems: 'baseline',
      },
      infoContainer: {
        marginBottom: 8,
      },
      countContainer: {
        flexDirection: 'row',
      },
      optionContainer: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch'
      },
      option: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'stretch'
      }
    }),
  }
}
