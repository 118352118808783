import { contentSelectors } from 'components/src/bus/content'

import { useStoreSelector } from 'components/src/hooks'

const storeSelector = (state) => ({
  homeMenuItems: contentSelectors.getHomeMenuItems(state),
  myLocationMenuItems: contentSelectors.getMyLocationMenuItems(state),
})

export const useSettings = (screen) => {
  const { homeMenuItems, myLocationMenuItems } = useStoreSelector(storeSelector)
  const _getTitle = (uuid) => {
    if (screen === 'home' && homeMenuItems) {
      return homeMenuItems.filter((item) => item.Uuid === uuid)[0].DisplayName
    }
    if (screen === 'location' && myLocationMenuItems) {
      return myLocationMenuItems.filter((item) => item.Uuid === uuid)[0]
        .DisplayName
    }
  }
  return {
    getTitle: _getTitle,
  }
}
