import { call, put } from 'redux-saga/effects'

// Local
import { themesActions } from 'components/src/bus/themes'

// Local Dir
import { apiThemes } from '../../api'

export function* fetchTheme() {
  try {
    const response = yield call(apiThemes.fetch)
    if (response) {
      yield put(themesActions.changeTheme(response))
    }
  } catch (error) {
    console.log(error, 'Themes fetch workers')
  }
}
