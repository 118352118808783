//components
import {
  Button,
  HeaderNavigator,
  PasswordInput,
  TextInput,
} from 'components/src/components'
import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useLayoutEffect } from 'react'
import { Text, View } from 'react-native'
//store
import { cartActions, cartSelectors } from 'components/src/bus/cart'
import { profileActions, profileSelectors } from 'components/src/bus/profile'
import { useDispatch, useSelector } from 'react-redux'

import { BaseLayout } from 'components/src/layouts'
import { resolver } from './resolver'
import { useStoreSelector } from 'components/src/hooks'
//local
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

const selectStore = (state) => ({
  cartItems: cartSelectors.getCartItems(state),
})

const profileStore = (state) => ({
  isLoading: profileSelectors.isLoading(state),
  isAccountAlreadyExist: profileSelectors.isAccountAlreadyExist(state),
})

export const SignUpScreen = ({ navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addLogo addBackButton {...props} />,
    })
  })
  const { cartItems } = useStoreSelector(selectStore)
  const { isLoading, isAccountAlreadyExist } = useStoreSelector(profileStore)
  const dispatch = useDispatch()
  const { styles, buttonProps } = useStyles()
  const { t } = useTranslation()

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      Username: '',
      FirstName: '',
      LastName: '',
      Email: '',
      Password: '',
    },
    resolver,
  })

  useEffect(() => {
    dispatch(profileActions.toggleIsAccountAlreadyExist(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleModal = (value) => {
    dispatch(cartActions.toggleModal())
  }

  const onUsernameFocus = () => {
    if (isAccountAlreadyExist) {
      dispatch(profileActions.toggleIsAccountAlreadyExist(false))
    }
  }

  const onSubmit = handleSubmit((data) => {
    dispatch(profileActions.createProfileAsync(data))
    cartItems && cartItems.length > 0 && toggleModal()
  })

  return (
    <BaseLayout>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <View style={styles.titleCardWrapper}>
            <View style={styles.titleCard}>
              <Text style={styles.titleCardText}>
                {t('profile.register.title').toUpperCase()}
              </Text>
            </View>
          </View>
          <View style={styles.titleWrapper}>
            <Text style={styles.title}>{t('profile.register.t_1')}</Text>
          </View>
          <View style={styles.form}>
            <Controller
              control={control}
              name="Username"
              render={({ value, onChange }) => (
                <TextInput
                  onFocus={onUsernameFocus}
                  style={styles.input}
                  autoCapitalize="none"
                  label={t('form.label.username')}
                  value={value}
                  onChange={onChange}
                  error={errors?.Username && t(errors?.Username.message)}
                />
              )}
            />
            {isAccountAlreadyExist && (
              <Text style={styles.textError}>
                {t('form.error.username_exists')}
              </Text>
            )}
            <Controller
              control={control}
              name="FirstName"
              render={({ value, onChange }) => (
                <TextInput
                  style={styles.input}
                  label={t('form.label.firstName')}
                  value={value}
                  onChange={onChange}
                  error={errors?.FirstName && t(errors?.FirstName.message)}
                />
              )}
            />
            <Controller
              control={control}
              name="LastName"
              render={({ value, onChange }) => (
                <TextInput
                  style={styles.input}
                  label={t('form.label.lastName')}
                  value={value}
                  onChange={onChange}
                  error={errors?.LastName && t(errors?.LastName.message)}
                />
              )}
            />
            <Controller
              control={control}
              name="Email"
              render={({ value, onChange }) => (
                <TextInput
                  style={styles.input}
                  label="E-Mail"
                  value={value}
                  autoCapitalize="none"
                  onChange={onChange}
                  error={errors?.Email && t(errors?.Email.message)}
                />
              )}
            />
            <Controller
              control={control}
              name="Password"
              render={({ value, onChange }) => (
                <PasswordInput
                  style={styles.input}
                  label={t('form.label.password')}
                  value={value}
                  onChange={onChange}
                  error={errors?.Password && t(errors?.Password.message)}
                />
              )}
            />
          </View>
          <View style={styles.messageContainer}>
            <Text style={styles.messageBold}>{t('profile.register.t_2')}</Text>
          </View>
          <View style={styles.messageContainer}>
            <Text style={styles.message}>{t('profile.register.t_3')}</Text>
          </View>
          <View style={styles.buttonContainer}>
            <Button
              loading={isLoading}
              style={styles.button}
              {...buttonProps}
              onPress={onSubmit}
            >
              {t('buttons.register')}
            </Button>
          </View>
        </View>
      </View>
    </BaseLayout>
  )
}
