import React from 'react'
import PropTypes from 'prop-types'
import { View, ScrollView } from 'react-native'

import { ContentHeader, ViewHtml } from 'components/src/components'
import { useUriImage } from 'components/src/hooks'

import { useStyles } from './styles'

export const SingleContent = ({ data }) => {
  const { styles } = useStyles()
  const { getUriImage } = useUriImage()

  return (
    <ScrollView>
      <View style={styles.container}>
        <ContentHeader
          title={data.DisplayName}
          source={getUriImage({ uuid: data.ImageDocumentUuid, width: 340 })}
        />
        <ViewHtml html={data.DisplayText} />
      </View>
    </ScrollView>
  )
}

SingleContent.propTypes = {
  data: PropTypes.object.isRequired,
}
