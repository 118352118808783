import { contentActions, contentSelectors } from 'components/src/bus/content'
import { settingsActions, settingsSelectors } from 'components/src/bus/settings'
import { useCallback, useEffect } from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { useDispatch } from 'react-redux'
import { useStoreSelector } from 'components/src/hooks'
import { useTranslation } from 'react-i18next'

const settingsStoreSelector = (state) => ({
  sites: settingsSelectors.getMemberSites(state),
  selectedSite: settingsSelectors.getSelectedSite(state),
})

const contentStoreSelector = (state) => ({
  loading: contentSelectors.isLoading(state),
  menuItems: contentSelectors.getMyLocationMenuItems(state),
})

export const useFetchData = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { sites, selectedSite } = useStoreSelector(settingsStoreSelector)
  const { loading, menuItems } = useStoreSelector(contentStoreSelector)
  useEffect(() => {
    !menuItems &&
      dispatch(
        contentActions.fetchMyLocationMenuItemsAsync({
          params: { maxRows: 1000 },
          headers: { 'X-Context-SiteUuid': selectedSite },
        })
      )
  }, [dispatch, menuItems, selectedSite])

  const onChangeLanguage = useCallback(() => {
    dispatch(
      contentActions.fetchMyLocationMenuItemsAsync({
        params: { maxRows: 1000 },
        headers: { 'X-Context-SiteUuid': selectedSite },
      })
    )
  }, [dispatch, selectedSite])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  const _handleSelectSite = (uuid) => {
    if (uuid !== selectedSite) {
      AsyncStorage.setItem('selectedSite', uuid)
      dispatch(settingsActions.saveSelectedSite(uuid))
      dispatch(
        contentActions.fetchMyLocationMenuItemsAsync({
          params: { maxRows: 1000 },
          headers: { 'X-Context-SiteUuid': uuid },
        })
      )
    }
  }

  const sitesWithContent = sites?.filter((site) => site.HasContent)

  return {
    sites: sitesWithContent,
    selectedSite,
    menuItems,
    loading,
    handleSelectSite: _handleSelectSite,
  }
}
