import axios from 'components/src/services/axios'
import { AuthService } from 'components/src/services/AuthService'

export const apiProfile = new (class API {
  async getHeaders() {
    const token = await AuthService.getToken()
    return { Authorization: `Bearer ${token}` }
  }

  signIn(data) {
    return axios({
      url: '/SignIn',
      method: 'post',
      data,
    })
  }

  createProfile(data) {
    return axios({
      url: '/UserProfiles/Create',
      method: 'post',
      data,
    })
  }

  resetPassword(data) {
    return axios({
      url: '/ResetPassword',
      method: 'post',
      data,
    })
  }

  async fetchProfile() {
    return axios({
      url: '/CurrentUserProfile',
      method: 'get',
      headers: await this.getHeaders(),
    })
  }

  async updateProfile(data) {
    return axios({
      url: '/CurrentUserProfile',
      method: 'put',
      headers: await this.getHeaders(),
      data,
    })
  }

  async updatePassword(data) {
    return axios({
      url: '/CurrentUserProfile/Password',
      method: 'post',
      headers: await this.getHeaders(),
      data,
    })
  }

  async fetchBookmarkedSites(params) {
    return axios({
      url: '/CurrentUserProfile/BookmarkedSites',
      method: 'get',
      headers: await this.getHeaders(),
      params,
    })
  }
  async createBookmarkedSites(data) {
    return axios({
      url: '/CurrentUserProfile/BookmarkedSites',
      method: 'post',
      headers: await this.getHeaders(),
      data,
    })
  }
  async deleteBookmarkedSites({ Uuid }) {
    return axios({
      url: `/CurrentUserProfile/BookmarkedSites/${Uuid}`,
      method: 'delete',
      headers: await this.getHeaders(),
    })
  }
})()
