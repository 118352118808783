import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, Text, ActivityIndicator } from 'react-native'
import { useStyles } from './styles'

export const Button = ({
  mode,
  children,
  width,
  height,
  textStyle,
  style,
  onPress,
  loading,
}) => {
  const { styles, loaderColor } = useStyles({ width, height, mode })

  return (
    <TouchableOpacity
      style={[styles.wrapper, style]}
      activeOpacity={0.8}
      onPress={onPress}
      disabled={loading}
    >
      {loading && (
        <ActivityIndicator
          style={styles.loader}
          size="small"
          color={loaderColor}
        />
      )}
      <Text style={[styles.text, textStyle]}>{children}</Text>
    </TouchableOpacity>
  )
}

Button.propType = {
  children: PropTypes.node,
  onPress: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  textStyle: PropTypes.object,
  mode: PropTypes.oneOf(['default', 'outline']),
  style: PropTypes.object,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  onPress: () => {},
  mode: 'default',
  loading: false,
}
