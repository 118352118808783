import React, { useMemo, useEffect } from 'react'
import { View, Animated, TouchableWithoutFeedback } from 'react-native'

import { Icon } from 'components/src/components'
import { tabItemStyles } from '../styles'

export const TabItem = ({
  isFocused,
  valueTo,
  onPress,
  onLongPress,
  options,
  iconName,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const iconAnim = useMemo(() => new Animated.Value(valueTo), [])

  useEffect(() => {
    const animation = Animated.spring(iconAnim, {
      toValue: valueTo,
      useNativeDriver: true,
    })
    animation.start()
  }, [iconAnim, valueTo])

  return (
    <TouchableWithoutFeedback
      accessibilityRole="button"
      accessibilityStates={isFocused ? ['selected'] : []}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      testID={options.tabBarTestID}
      onPress={onPress}
      onLongPress={onLongPress}
    >
      <View style={tabItemStyles.container}>
        <Animated.View
          style={{
            transform: [
              {
                scale: iconAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [1, 1.3],
                }),
              },
              {
                translateY: iconAnim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, -2],
                }),
              },
            ],
          }}
        >
          <Icon name={iconName} color="#E5235F" size={30} />
        </Animated.View>
      </View>
    </TouchableWithoutFeedback>
  )
}
