import { StyleSheet, Platform } from 'react-native'
import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

const fixWebInput = Platform.select({
  web: {
    zIndex: 1,
    outlineWidth: 0,
  },
})

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      wrapper: {
        width: '100%',
        borderWidth: 1,
        borderTopWidth: 0,
        borderColor: pallete.textInputBorder,
        height: 42,
        paddingVertical: 2,
        paddingHorizontal: 5,
      },
      input: {
        height: 38,
        width: '100%',
        textAlign: 'center',
        ...fontFamilies.os_light,
        fontSize: 20,
        color: pallete.textInputColor,
        ...fixWebInput,
      },
      label: {
        position: 'absolute',
        top: 0,
        left: 5,
        ...fontFamilies.cl_bold,
        fontSize: 14,
        color: pallete.textInputLabel,
      },
      affixContainer: {
        position: 'absolute',
        top: 0,
        bottom: 1,
        overflow: 'hidden',
      },
      affix: {
        height: 1,
        backgroundColor: pallete.textInputBorder,
      },
      error: {
        marginTop: 3,
        width: '100%',
      },
      errorText: {
        fontSize: 12,
        ...fontFamilies.cl_regular,
        color: pallete.textInputErrorColor,
        textAlign: 'left',
      },
    }),
    selectionColor: pallete.textInputBorder,
  }
}
