import { cartActions, cartSelectors } from 'components/src/bus/cart'

import { useDispatch } from 'react-redux'
import { useStoreSelector } from 'components/src/hooks'

const selectStore = (state) => ({
  cartItems: cartSelectors.getCartItems(state),
  cartModal: cartSelectors.getCartModal(state),
  cartOptionsFetchigFlag: cartSelectors.getFetchOptionsFlag(state),
  cartOptions: cartSelectors.getOptions(state),
})

export const useCart = () => {
  const { cartItems, cartOptions, cartOptionsFetchigFlag } =
    useStoreSelector(selectStore)
  const dispatch = useDispatch()
  const _handleRemoveCartItem = (uuid) => {
    dispatch(cartActions.removeItemCartMutation(uuid))
  }
  const _handleChangeCartItemCount = (uuid, count) => {
    dispatch(cartActions.changeItemCartCountMutation({ uuid, count }))
  }

  const _handleChangeOptionItem = (optionUuid, newValue) => {
    dispatch(cartActions.changeOptionsCartMutation({ optionUuid, newValue }))
  }

  const _getTotal = () => {
    let total = 0
    if (cartItems) {
      cartItems.map((item) => (total += item.count * item.data.GrossPrice))
    }
    if (cartOptions) {
      cartOptions.map((option) => {
        if (option.Selected) {
          total += option.GrossPrice
        }
      })
    }
    return total.toFixed(2).replace('.', ',')
  }
  return {
    cartItems,
    cartOptions,
    cartOptionsFetchigFlag,
    handleRemoveCartItem: _handleRemoveCartItem,
    handleChangeCartItemCount: _handleChangeCartItemCount,
    total: _getTotal(),
    handleChangeOptionItem: _handleChangeOptionItem,
  }
}
