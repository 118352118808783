import React from 'react'
import PropTypes from 'prop-types'
import { ActivityIndicator, View } from 'react-native'

import { useStyles } from './styles'

export const Preloader = ({ containerStyles, screen }) => {
  const { styles, pallete } = useStyles()
  const preloaderColor = () => {
    switch (screen) {
      // case 'location':
      //   return pallete.secondary
      default:
        return pallete.primary
    }
  }
  return (
    <View style={[styles.container, containerStyles]}>
      <ActivityIndicator size="large" color={preloaderColor()} />
    </View>
  )
}

Preloader.propTypes = {
  containerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  screen: PropTypes.oneOf(['location', 'home']),
}
