export class Storage {
  set(key, value) {
    return new Promise((resolve) => {
      resolve(localStorage.setItem(key, value))
    })
  }

  get(key) {
    return new Promise((resolve) => {
      resolve(localStorage.getItem(key))
    })
  }

  remove(key) {
    return new Promise((resolve) => {
      resolve(localStorage.removeItem(key))
    })
  }
}
