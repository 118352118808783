import { auth } from './auth'

export const profile = {
  Profile: {
    path: 'profile',
    screens: {
      Profile: '/',
      EditProfile: {
        path: '/edit-profile',
        screens: {
          ChangeProfile: '/change-profile',
          ChangePassword: '/change-password',
          ...auth,
        },
      },
      FavoriteProfile: {
        path: '/favorite',
        screens: {
          FavoriteHotel: '/',
          ...auth,
        },
      },
      BenefitsProfile: {
        path: '/benefits',
        screens: {
          Benefits: '/',
          ...auth,
        },
      },
    },
  },
}
