import { Document, Page, pdfjs } from 'react-pdf'
import { Image, Modal, TouchableOpacity, View } from 'react-native'
import React, { useState } from 'react'

import { Icon } from 'components/src/components'
import { SvgXml } from 'react-native-svg'
import { useFocusEffect } from '@react-navigation/core'
import { useStyles } from './styles'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const ViewPdf = ({ isOpen, setIsOpen, uuid }) => {
  const [pages, setPages] = useState(null)
  const { stylesWeb: styles } = useStyles()

  const _handleClose = () => {
    setIsOpen(false)
  }

  const _handleNumPages = ({ numPages }) => {
    setPages(numPages)
  }
  const _viewPdfPages = () => {
    const contentPages = []
    if (pages) {
      for (let i = 1; i <= pages; i++) {
        contentPages.push(
          <View key={i} style={styles.pageWrapper}>
            <Page renderTextLayer={false} pageNumber={i} scale={1} />
          </View>
        )
      }
      return contentPages
    }
    return null
  }

  return (
    <Modal
      ariaHideApp={false}
      animationType="slide"
      transparent={false}
      visible={isOpen}
      onDismiss={() => setIsOpen(false)}
    >
      {uuid ? (
        <>
          <TouchableOpacity style={styles.closeButton} onPress={_handleClose}>
            <Image
              resizeMode="contain"
              style={styles.closeButtonIcon}
              source={require('components/assets/images/close.svg')}
            />
          </TouchableOpacity>
          <View style={styles.pdfWrapper}>
            <Document
              file={`https://api.betterspace.de/v1/Documents/${uuid}/Data`}
              onLoadSuccess={_handleNumPages}
            >
              {_viewPdfPages()}
            </Document>
          </View>
        </>
      ) : (
        <View />
      )}
    </Modal>
  )
}
