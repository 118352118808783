import { Image, Text, TouchableOpacity } from 'react-native'

import React from 'react'
import { useStyles } from '../styles'

export const CheckBox = ({ isSelected, label, onPress }) => {
  const { styles, cartItemStyles } = useStyles()
  const checked_img = require('components/assets/images/checkbox.png')
  const unchecked_img = require('components/assets/images/empty-checkbox.png')

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={onPress}
      style={cartItemStyles.checkBoxContainer}
    >
      <Image
        source={isSelected ? checked_img : unchecked_img}
        style={cartItemStyles.checkBoxImage}
      />
      <Text style={cartItemStyles.selectBoxText}>{label}</Text>
    </TouchableOpacity>
  )
}

CheckBox.defaultProps = {
  onPress: () => {},
  isSelected: false,
  label: '',
}
