import { CommonActions, useNavigation } from '@react-navigation/native'
import { Linking, Platform } from 'react-native'

import PropTypes from 'prop-types'
// Local
import { settingsActions } from 'components/src/bus/settings'
import { useDispatch } from 'react-redux'
import { useFetchData } from '../../../hooks'
import { useTranslation } from 'react-i18next'

export const useHandler = ({ data }) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { _handleSelectSite } = useFetchData()
  const buttonTitle = data.HasContent
    ? t('buttons.guest_folder')
    : 'cooee ' + t('buttons.web_site')

  const handlerOnChangeAutocomplete = (country) => {
    if (country) {
      navigation.dispatch((state) => {
        const route = state.routes.filter(
          (_, index) => index === state.index - 1
        )[0]
        return {
          ...CommonActions.setParams({
            ...route.params,
            countryCode: country.Code,
          }),
          source: route.key,
        }
      })
      navigation.goBack()
    }
  }

  const handlerPressButton = () => {
    if (data.HasContent) {
      _handleSelectSite(data.Uuid)
      navigation.navigate('MyLocation')
    } else {
      Platform.OS === 'web'
        ? window.open(data.Website)
        : Linking.openURL(data.Website)
    }
  }

  return {
    buttonTitle,
    handlerOnChangeAutocomplete,
    handlerPressButton,
  }
}

useHandler.propTypes = {
  data: PropTypes.object.isRequired,
}
