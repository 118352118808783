import { Modal, Text, TouchableOpacity, View } from 'react-native'
import React, { useCallback } from 'react'

import { LanguageRemember } from 'components/src/services/LanguageRemember'
import PropTypes from 'prop-types'
//local
import { languages } from 'components/src/i18n'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

export const ModalChangeLanguage = ({ modalVisible, onChange, onClose }) => {
  const { styles } = useStyles()
  const { t, i18n } = useTranslation()

  const handleChange = useCallback(
    async (language) => {
      await LanguageRemember.set(language)
      i18n.changeLanguage(language)
      onChange(language)
    },
    [i18n, onChange]
  )
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={onClose}
    >
      <TouchableOpacity
        style={styles.centeredView}
        onPress={onClose}
        activeOpacity={1}
      >
        <View style={styles.container}>
          <Text style={styles.title}>{t('titles.select_language')}</Text>
          {languages.map((language, index) => (
            <TouchableOpacity
              style={[
                styles.item,
                { borderBottomWidth: index === languages.length - 1 ? 0 : 1 },
              ]}
              key={`language-${language}`}
              onPress={() => handleChange(language)}
            >
              <Text
                style={[
                  styles.itemText,
                  language === i18n.language && styles.itemTextSelected,
                ]}
              >
                {language.toUpperCase()}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </TouchableOpacity>
    </Modal>
  )
}

ModalChangeLanguage.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}
