import { StyleSheet } from 'react-native'

import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
        paddingTop: 18,
      },
      logoContainer: {
        marginBottom: 45,
      },
      preloadScreen: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: pallete.containerBackground,
      },
      cardContainer: {
        width: '100%',
        maxWidth: 337,
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 20,
        paddingLeft: 6,
        paddingRight: 6,
      },
    }),
    pallete,
  }
}
