import axios from 'components/src/services/axios'

export const apiSettings = new (class API {
  fetchSalutations() {
    return axios({
      url: '/Salutations',
      method: 'get',
    })
  }
  fetchCountries() {
    return axios({
      url: '/Countries',
      method: 'get',
      params: {
        citizenship: true,
        maxRows: 200,
      },
    })
  }
  fetchSites() {
    return axios({
      url: '/Sites',
      method: 'get',
    })
  }
  fetchMemberSites() {
    return axios({
      url: '/MemberSites',
      method: 'get',
      params: { maxRows: 1000 },
    })
  }
})()
