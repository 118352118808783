import { Text, View } from 'react-native'
import parse, { domToReact } from 'html-react-parser'

import PropTypes from 'prop-types'
import React from 'react'
import { useShortDescription } from './hooks'
import { useStyles } from './styles'

export const ViewShortDescription = ({ html, style }) => {
  const { pallete } = useStyles()
  const { shortHtml } = useShortDescription(html)

  return (
    <View style={[style]}>
      <Text style={{ whiteSpace: 'unset' }}>
        {parse(
          `
              <section>
                <style>
                  section {
                    width: 100%; color: ${
                      pallete.defaultText
                    }; font-family: 'OpenSans'; font-weight: 300; font-size: 12px;
                  }
                </style>
                ${shortHtml.replace(/(font-size:\w*,?)/gi, '')}
              </section>`,
          {
            replace: ({ type, name, children }) => {
              if (type === 'tag') {
                if (name === 'u') {
                  return <>{domToReact(children)}</>
                }
                if (name === 'br') {
                  return <span style={{ display: 'block' }}>{'\n'}</span>
                }
                if (name === 'strong') {
                  return (
                    <span
                      style={{ fontWeight: 300, marginTop: 0, marginBottom: 0 }}
                    >
                      {domToReact(children, {
                        replace: ({ type, name }) => {
                          if (type === 'tag') {
                            if (name === 'br') {
                              return (
                                <span style={{ display: 'block' }}>{'\n'}</span>
                              )
                            }
                          }
                        },
                      })}
                    </span>
                  )
                }
              }
            },
          }
        )}
      </Text>
    </View>
  )
}

ViewShortDescription.propTypes = {
  html: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
}
