import { StyleSheet, Dimensions } from 'react-native'

import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  const { width } = Dimensions.get('screen')

  const imageWidth = () => {
    if (width <= 500) {
      return width - 38
    }
    return 500
  }

  return {
    styles: StyleSheet.create({
      container: {
        width: '100%',
        maxWidth: 500,
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 16,
        color: pallete.actionText,
        marginBottom: 8,
      },
      button: {
        width: '100%',
        maxWidth: 280,
        height: 30,
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: 30,
      },
      fontFamilies: {
        fontSize: 16,
      },
      image: {
        height: imageWidth() / 1.5,
        marginBottom: 16,
      },
      scrollViewContainer: {
        marginTop: 20,
      },
    }),
  }
}
