import { persistReducer, persistStore } from 'redux-persist'

import AsyncStorage from '@react-native-community/async-storage'
import ENV from 'components/src/configs'
import { KEY_PREFIX } from '../constants/AuthService'
import Reactotron from './ReactronConfig'
import { configureStore } from '@reduxjs/toolkit'
import { rootReducer } from './rootReducer'
import rootSaga from './rootSaga'
import sagaMiddleware from './middleware/saga'

const middleware = [
  // ...getDefaultMiddleware({ thunk: false }),
  sagaMiddleware,
]

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  devTools: ENV.IS_DEVELOPMENT,
  middleware,
  enhancers: [Reactotron.createEnhancer()],
})

let persistor = persistStore(store)
sagaMiddleware.run(rootSaga)

export { store, persistor }

if (ENV.IS_DEVELOPMENT && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default

    store.replaceReducer(newRootReducer)
  })
}

const removeSpecificStorageItems = async () => {
  let keys = []
  try {
    keys = await AsyncStorage.getAllKeys()
    for (const key of keys) {
      if (
        [
          `${KEY_PREFIX}-t`,
          `${KEY_PREFIX}-l`,
          `${KEY_PREFIX}-p`,
          'cooee--lng',
          'selectedSite',
        ].indexOf(key) === -1
      ) {
        try {
          AsyncStorage.removeItem(key)
        } catch (error) {
          console.log(error)
        }
      }
    }
  } catch (e) {
    console.log(e)
  }
}

persistor.purge()

removeSpecificStorageItems()
