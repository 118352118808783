import {
  FlatList,
  Image,
  Modal,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { Icon, Logo } from 'components/src/components'
import React, { useState } from 'react'
import { uiActions, uiSelectors } from '../../bus/ui'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { StackActions } from '@react-navigation/native'
import { cartActions } from 'components/src/bus/cart'
import { cartSelectors } from 'components/src/bus/cart'
import { profileSelectors } from 'components/src/bus/profile'
import { useNavigationBack } from 'components/src/hooks'
import { useStoreSelector } from 'components/src/hooks'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

//store

const selectStore = (state) => ({
  cartItems: cartSelectors.getCartItems(state),
})
export const HeaderNavigator = ({
  navigation,
  addLogo,
  addBackButton,
  goBackCustom,
  isContentParent = false,
}) => {
  const shopping_icon = require('components/assets/images/shopping_basket.png')
  const isAuthenticated = useSelector(profileSelectors.getCurrent)
  const { cartItems } = useStoreSelector(selectStore)
  const { navigationBack } = useNavigationBack()
  const { styles } = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [showMessage, setShowMessage] = useState(false)

  const onViewRef = React.useRef(({ changed }) => {
    const readIndexes = changed.map((item) => item.index)
    dispatch(uiActions.readAlert(readIndexes))
  })
  const viewConfigRef = React.useRef({ viewAreaCoveragePercentThreshold: 50 })

  const countMessages = useSelector(uiSelectors.getCountMessages)
  const messages = useSelector(uiSelectors.getMessages)

  const _handleOpenMessage = () => setShowMessage(true)

  const _handleBack = () => {
    goBackCustom ? goBackCustom() : navigationBack(navigation)
  }

  const _handleCloseMessage = () => {
    setShowMessage(false)
    dispatch(uiActions.deleteReadAlert())
  }

  return (
    <View style={styles.container}>
      <View style={styles.headerLeft}>
        <TouchableOpacity
          style={styles.button}
          activeOpacity={0.75}
          onPress={_handleBack}
        >
          {addBackButton && (
            <Icon name="chevron-back" color="#E5235F" size={24} />
          )}
        </TouchableOpacity>
      </View>
      <View style={styles.headerCenter}>
        {addLogo && !isContentParent && (
          <TouchableOpacity
            activeOpacity={0.8}
            onPress={() => {
              navigation.dispatch(StackActions.popToTop())
              dispatch(cartActions.removeAllItems())
            }}
          >
            <Logo height={60} notFull />
          </TouchableOpacity>
        )}
      </View>
      <View style={styles.headerRight}>
        {isAuthenticated && cartItems && (
          <TouchableOpacity
            style={styles.button}
            activeOpacity={0.75}
            onPress={() => {
              navigation.navigate('Cart')
            }}
          >
            <Image source={shopping_icon} style={styles.shoppingBasket} />
            <View style={styles.cartCounter}>
              <Text style={styles.alertCounterText}>{cartItems.length}</Text>
            </View>
          </TouchableOpacity>
        )}
        {/* <TouchableOpacity
          style={styles.button}
          activeOpacity={0.75}
          onPress={_handleOpenMessage}
        >
          <Icon name="bell" color="#E5235F" size={24} />
          {countMessages > 0 && (
            <View style={styles.alertCounter}>
              <Text style={styles.alertCounterText}>{countMessages}</Text>
            </View>
          )}
        </TouchableOpacity> */}
      </View>
      <Modal visible={showMessage} transparent animationType="fade">
        <TouchableOpacity
          style={{ flex: 1 }}
          activeOpacity={1}
          onPress={_handleCloseMessage}
        >
          <FlatList
            style={styles.messageWrapper}
            onViewableItemsChanged={onViewRef.current}
            viewabilityConfig={viewConfigRef.current}
            data={messages}
            keyExtractor={(item, index) => `message-${index}`}
            renderItem={({ item }) => {
              return (
                <View style={styles.message}>
                  <Text style={styles.messageText}>{t(item.message)}</Text>
                </View>
              )
            }}
          />
        </TouchableOpacity>
      </Modal>
    </View>
  )
}

HeaderNavigator.propTypes = {
  navigation: PropTypes.object.isRequired,
  addLogo: PropTypes.bool,
  addBackButton: PropTypes.bool,
  goBackCustom: PropTypes.func,
}
