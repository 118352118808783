import firebase from 'firebase/app'
import 'firebase/messaging'

// TODO: Prod configs
const config = {
  apiKey: 'AIzaSyCyjwglX_Fdq6cDxOHW9_XmYxg7Cclfp3s',
  authDomain: 'iq-board.firebaseapp.com',
  databaseURL: 'https://iq-board.firebaseio.com',
  projectId: 'iq-board',
  storageBucket: 'iq-board.appspot.com',
  messagingSenderId: '810722732424',
  appId: '1:810722732424:web:4d60533e0dbf7ef6cc723d',
}

firebase.initializeApp(config)

export default firebase
