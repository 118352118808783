import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

// Locale
import {
  MainHomeScreen,
  ContentHomeScreen,
  MainMyLocationScreen,
  CartScreen,
  ThanksCartScreen,
  ListMyLocationScreen,
  ContentMyLocationScreen,
  ItemsHomeScreen,
  ListHomeScreen,
  HotelContentHomeScreen,
} from 'components/src/screens'
import { TabBar } from 'components/src/components'
import { useFetchData } from './hooks'
import { ProfileNavigator } from '../ProfileNavigator'

const Tab = createBottomTabNavigator()
const Home = createStackNavigator()
const MyLocation = createStackNavigator()

const HomeScreens = () => {
  return (
    <Home.Navigator backBehavior="initialRoute" headerMode="screen">
      <Home.Screen name="Start" component={MainHomeScreen} />
      <Home.Screen name="Items" component={ItemsHomeScreen} />
      <Home.Screen name="Content" component={ContentHomeScreen} />
      <Home.Screen name="List" component={ListHomeScreen} />
      <Home.Screen
        name="HotelContent"
        options={{ title: 'Home COOEE' }}
        component={HotelContentHomeScreen}
      />
    </Home.Navigator>
  )
}

const MyLocationScreens = () => {
  return (
    <MyLocation.Navigator headerMode="screen" backBehavior="initialRoute">
      <MyLocation.Screen name="Start" component={MainMyLocationScreen} />
      <MyLocation.Screen name="List" component={ListMyLocationScreen} />
      <MyLocation.Screen name="Content" component={ContentMyLocationScreen} />
      <MyLocation.Screen name="Cart" component={CartScreen} />
      <MyLocation.Screen name="ThanksCart" component={ThanksCartScreen} />
    </MyLocation.Navigator>
  )
}

export const TabNavigator = () => {
  const { isLoading } = useFetchData()
  return (
    <Tab.Navigator
      headerMode="screen"
      tabBar={(props) => <TabBar {...props} />}
    >
      <Tab.Screen
        name="Home"
        component={HomeScreens}
        options={{ tabIcon: 'house' }}
      />
      <Tab.Screen
        name="MyLocation"
        component={MyLocationScreens}
        options={{ tabIcon: 'marker' }}
      />
      <Tab.Screen
        name="Profile"
        component={ProfileNavigator}
        options={{ tabIcon: 'person-pin' }}
      />
    </Tab.Navigator>
  )
}
