const actionCreators = {
  MUTATION_ADD_ITEM: 'CART/MUTATION_ADD_ITEM',
  MUTATION_REMOVE_ITEM: 'CART/MUTATION_REMOVE_ITEM',
  MUTATION_CHANGE_COUNT_ITEM: 'Cart/MUTATION_CHANGE_COUNT_ITEM',
  MUTATION_REMOVE_ALL_ITEMS: 'Cart/MUTATION_REMOVE_ALL_ITEMS',
  SEND_GUEST_ORDERS: 'Cart/SEND_GUEST_ORDERS',
  FETCH_OPTIONS: 'Cart/FETCH_OPTIONS',
  MUTATION_SAVE_OPTIONS: 'Cart/MUTATION_SAVE_OPTIONS',
}

export const types = Object.freeze(actionCreators)
