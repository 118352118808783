import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
  },
  safeArea: {
    flex: 1,
  },
  fadeOut: {
    left: 0,
    right: 0,
    height: 20,
    position: 'absolute',
    bottom: 0,
  },
})
