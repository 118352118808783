import { call, put } from 'redux-saga/effects'

import { apiProfile } from '../../api'
import { forceLogout } from './auth.workers'
import { profileActions } from 'components/src/bus/profile'
import { uiActions } from 'components/src/bus/ui'

// Local

// Local Dir

// import { AuthService } from '../../../../services/AuthService'

export function* updateProfile(action) {
  try {
    yield put(profileActions.toggleLoading(true))
    const response = yield call(
      [apiProfile, 'updateProfile'],
      action.payload.data
    )
    if (response.data && response.data.Item) {
      yield put(profileActions.saveCurrentProfile(response.data.Item))
      yield put(uiActions.addAlert({ message: 'form.success.change_profile' }))
      yield call(action.payload.callBackSubmit)
    } else {
      yield put(
        uiActions.addAlert({ message: 'form.error.wrong_change_profile' })
      )
      yield call(action.payload.callBackError)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Profile update workers'))
  } finally {
    yield put(profileActions.toggleLoading(false))
  }
}

export function* updatePassword(action) {
  try {
    yield put(profileActions.toggleLoading(true))
    const response = yield call([apiProfile, 'updatePassword'], action.payload)
    if (response.data.Status === 'OK') {
      yield call(forceLogout)
      yield put(uiActions.addAlert({ message: 'form.success.change_profile' }))
    } else {
      yield put(
        uiActions.addAlert({ message: 'form.error.wrong_old_password' })
      )
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Profile update workers'))
  } finally {
    yield put(profileActions.toggleLoading(false))
  }
}
