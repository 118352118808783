import {
  Animated,
  FlatList,
  LogBox,
  SafeAreaView,
  Text,
  View,
} from 'react-native'
import React, { useEffect, useRef, useState } from 'react'

import { Icon } from 'components/src/components'
import PropTypes from 'prop-types'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { cartActions } from '../../bus/cart'
import { useDispatch } from 'react-redux'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

export const HotelsSelector = ({
  data,
  onChange,
  defaultValue,
  _setTilesShouldBeClickable,
}) => {
  const dispatch = useDispatch()
  const [animValue, setAnimValue] = useState(0)
  const [value, setValue] = useState(defaultValue)
  const { styles } = useStyles()
  const scaleAnim = useRef(new Animated.Value(0)).current
  useEffect(() => LogBox.ignoreLogs(['VirtualizedLists']), [])
  /*
  this issue appears because FlatList can't be inside ScrollView for performance issues,
  but this won't be an issue because:
  1. the FlatList is not too big, so performance won't be an issue
  2. the ScrollView is not inside HotelsSelector component, but inside MainMyLocationScreen
        and can't be moved.
  */

  const { t } = useTranslation()

  const _handleAnimation = () => {
    if (animValue === 0) {
      setAnimValue(1)
      _setTilesShouldBeClickable(false)
    } else {
      setAnimValue(0)
      _setTilesShouldBeClickable(true)
    }
  }

  useEffect(() => {
    const animation = Animated.timing(scaleAnim, {
      toValue: animValue,
      duration: 300,
      useNativeDriver: true,
    })
    animation.start()
  }, [animValue, scaleAnim])

  const _handleChange = (newValue) => {
    dispatch(cartActions.removeAllItems())
    setValue(newValue)
    _handleAnimation()
    onChange(newValue)
  }

  const _renderHotelTitle = () => {
    if (value) {
      return data.find((item) => item.Uuid === value)?.Name || ''
    }
    return t('my_location.not_selected')
  }

  const renderData = ({ item }) => {
    return (
      <TouchableOpacity
        style={[
          _renderHotelTitle() === item.Name
            ? styles.optionActive
            : styles.option,
        ]}
        onPress={() => _handleChange(item.Uuid)}
      >
        <Text
          style={[
            _renderHotelTitle() === item.Name
              ? styles.optionLabelActive
              : styles.optionLabel,
          ]}
        >
          {item.Name}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={
          animValue === 0
            ? styles.selectContainer
            : styles.selectContainerActive
        }
        onPress={_handleAnimation}
      >
        <Text style={styles.selectLabel} numberOfLines={1}>
          {_renderHotelTitle()}
        </Text>
        <Animated.View
          style={[
            styles.selectIcon,
            {
              transform: [
                {
                  rotate: scaleAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['270deg', '90deg'],
                  }),
                },
              ],
            },
          ]}
        >
          <Icon name="chevron-back" size={16} color="rgba(0,0,0,0.85)" />
        </Animated.View>
      </TouchableOpacity>
      <Animated.View
        style={{
          transform: [
            {
              translateY: scaleAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0],
              }),
            },
          ],
        }}
      >
        <Animated.View
          style={[
            styles.optionsContainer,
            {
              transform: [
                {
                  scaleY: scaleAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [0, 1],
                  }),
                },
              ],
            },
          ]}
        >
          {animValue === 1 && (
            <FlatList
              data={data}
              renderItem={renderData}
              keyExtractor={(item) => item.Uuid}
              scrollEnabled={false}
            />
          )}
        </Animated.View>
      </Animated.View>
    </View>
  )
}

HotelsSelector.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
}
