import React, { useLayoutEffect } from 'react'
import { ScrollView, View } from 'react-native'
// Locale
import {
  HeaderNavigator,
  Logo,
  Card,
  Preloader,
} from 'components/src/components'
import { MainLayout } from 'components/src/layouts'
import { useStyles } from './styles'
import { useGetData } from './hooks'

export const MainHomeScreen = ({ navigation }) => {
  const { homeMenuItems, isLoading } = useGetData()

  const { styles } = useStyles()
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator {...props} />,
      title: 'Home COOEE',
    })
  })

  if (isLoading) {
    return (
      <View style={styles.preloadScreen}>
        <Preloader />
      </View>
    )
  }

  const _nextScreen = (item) => {
    if (item.isParent) {
      navigation.navigate('Items', { itemUuid: item.Uuid })
    } else {
      navigation.navigate('HotelContent', { itemUuid: item.Uuid })
    }
  }

  return (
    <MainLayout>
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.logoContainer}>
            <Logo height={100} />
          </View>
          <View style={styles.cardContainer}>
            {homeMenuItems &&
              homeMenuItems.map((menuItem) => (
                <Card
                  key={menuItem.Uuid}
                  data={menuItem}
                  onPress={() => _nextScreen(menuItem)}
                />
              ))}
          </View>
        </View>
      </ScrollView>
    </MainLayout>
  )
}
