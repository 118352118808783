import { createSlice } from '@reduxjs/toolkit'
import { types } from './types'

const defaultAdditionalContent = {
  openingHours: null,
  contentImages: null,
  contentPdf: null,
  contentWebsite: null,
  onlyPdf: null,
  products: null,
}

const initialState = {
  homeMenuItems: null,
  myLocationMenuItems: null,
  listHome: null,
  listMyLocation: null,
  loading: false,
  additionalLoading: false,
  content: null,
  myContent: null,
  myContentUuid: null,
  myAdditionalContent: defaultAdditionalContent,
  additionalContent: defaultAdditionalContent,
  contentUuid: null,
  contentMenuItemUuid: null,
}

const slice = createSlice({
  name: 'CONTENT',
  initialState,
  reducers: {
    saveHomeMenuItems: (state, action) => {
      state.homeMenuItems = action.payload
    },
    clearHomeMenuItems: (state) => {
      state.homeMenuItems = null
    },
    saveMyLocationMenuItems: (state, action) => {
      state.myLocationMenuItems = action.payload
    },
    clearMyLocationMenuItems: (state) => {
      state.myLocationMenuItems = null
    },
    toggleLoading: (state, action) => {
      state.loading = action.payload
    },
    clearContent: (state) => {
      state.content = null
    },
    saveContent: (state, action) => {
      state.content = action.payload
    },
    saveMyContent: (state, action) => {
      state.myContent = action.payload
    },
    saveMyModContent: (state, action) => {
      state.myContent = action.payload.contents.map((content) => {
        return { ...content, ParentUuid: action.payload.uuid }
      })
    },
    saveContentMenuItemUuid: (state, action) => {
      state.contentMenuItemUuid = action.payload
    },
    saveAdditionalContent: (state, action) => {
      state.additionalContent = action.payload
    },
    saveMyAdditionalContent: (state, action) => {
      state.myAdditionalContent = action.payload
    },
    saveSinglePdf: (state, action) => {
      state.additionalContent = {
        ...state.additionalContent,
        singlePdf: action.payload,
      }
    },
    saveMySinglePdf: (state, action) => {
      state.myAdditionalContent = {
        ...state.myAdditionalContent,
        singlePdf: action.payload,
      }
    },
    saveContentUuid: (state, action) => {
      state.contentUuid = action.payload
    },
    saveMyContentUuid: (state, action) => {
      state.myContentUuid = action.payload
    },
    saveListHome: (state, action) => {
      state.listHome = action.payload
    },
    saveListMyLocation: (state, action) => {
      state.listMyLocation = action.payload
    },
    clearAdditionalContent: (state, action) => {
      state.additionalContent = defaultAdditionalContent
    },
    clearMyAdditionalContent: (state, action) => {
      state.myAdditionalContent = defaultAdditionalContent
    },
    toggleAdditionalLoading: (state, action) => {
      state.additionalLoading = action.payload
    },
  },
})

export default slice.reducer

//Action Creators Async
export const contentActions = {
  ...slice.actions,
  //Async
  fetchHomeMenuItemsAsync: (payload) => ({
    type: types.FETCH_HOME_MENU_ITEMS,
    payload,
  }),
  fetchContentAsync: (payload) => ({
    type: types.FETCH_CONTENT,
    payload,
  }),
  fetchAdditionalContentAsync: (payload) => ({
    type: types.FETCH_ADDITIONAL_CONTENT,
    payload,
  }),
  fetchMyLocationMenuItemsAsync: (payload) => ({
    type: types.FETCH_MY_LOCATION_MENU_ITEMS,
    payload,
  }),
  fetchSingleItem: (payload) => ({
    type: types.FETCH_SINGLE_ITEM,
    payload,
  }),
  fetchSinglePdf: (payload) => ({
    type: types.FETCH_CONTENT_SINGLE_PDF,
    payload,
  }),
  fetchOpenLink: (payload) => ({
    type: types.FETCH_OPEN_LINK,
    payload,
  }),
  fetchContentListAsync: (payload) => ({
    type: types.FETCH_CONTENT_LIST,
    payload,
  }),
}
