import React from 'react'
import { useSelector } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'

//navigators
import { EditProfileNavigator } from '../EditProfileNavigator'
import { FavoriteProfileNavigator } from '../FavoriteProfileNavigator'
import { BenefitsProfileNavigator } from '../BenefitsProfileNavigator'
import { AuthNavigator } from '../AuthNavigator'
//screens
import { ProfileStartScreen } from 'components/src/screens'

//store
import { profileSelectors } from 'components/src/bus/profile'

const ProfileStack = createStackNavigator()

export const ProfileNavigator = () => {
  const isAuthenticated = useSelector(profileSelectors.getCurrent)
  return (
    <ProfileStack.Navigator backBehavior="initialRoute" headerMode="screen">
      <ProfileStack.Screen name="Profile" component={ProfileStartScreen} />
      <ProfileStack.Screen
        name="EditProfile"
        component={EditProfileNavigator}
      />
      <ProfileStack.Screen
        name="FavoriteProfile"
        component={FavoriteProfileNavigator}
      />
      <ProfileStack.Screen
        name="BenefitsProfile"
        component={BenefitsProfileNavigator}
      />
      {!isAuthenticated && (
        <ProfileStack.Screen
          name="Authenticated"
          component={AuthNavigator}
          options={{
            headerShown: false,
          }}
        />
      )}
    </ProfileStack.Navigator>
  )
}
