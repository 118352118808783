//Core
import { all, call, takeLatest } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import { openedNotificationMessage } from './workers'

function* watchOpenedNotification() {
  yield takeLatest(types.OPEN_NOTIFICATION, openedNotificationMessage)
}

export function* watchUI() {
  yield all([call(watchOpenedNotification)])
}
