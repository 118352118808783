import { StyleSheet } from 'react-native'
//local
import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      wrapper: {
        alignItems: 'center',
        flex: 1,
      },
      container: {
        flex: 1,
        paddingTop: 45,
        width: 348,
      },
      form: {
        marginBottom: 20,
      },
      input: {
        marginTop: 10,
      },
      titleCardWrapper: {
        flexDirection: 'row',
        marginBottom: 22,
      },
      titleCard: {
        paddingHorizontal: 12,
        paddingVertical: 7,
        backgroundColor: pallete.titleCardBackground,
        flexGrow: 0,
        flexShrink: 0,
      },
      titleCardText: {
        fontSize: 22,
        ...fontFamilies.cl_bold,
        color: pallete.titleCardText,
      },
      titleWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
      title: {
        fontSize: 22,
        ...fontFamilies.cl_bold,
        textAlign: 'center',
        color: pallete.defaultText,
      },
      messageContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
      },
      messageBold: {
        color: pallete.defaultText,
        fontSize: 16,
        ...fontFamilies.os_bold,
        alignItems: 'center',
        marginBottom: 5,
      },
      message: {
        color: pallete.defaultText,
        fontSize: 16,
        ...fontFamilies.os_light,
        textAlign: 'center',
      },
      buttonContainer: {
        alignItems: 'center',
      },
      button: {
        marginTop: 30,
        marginBottom: 40,
      },
      textError: {
        ...fontFamilies.cl_bold,
        marginTop: 2,
        fontSize: 12,
        color: 'rgb(229, 35, 95)',
      },
    }),
    buttonProps: {
      width: 300,
      height: 42,
    },
  }
}
