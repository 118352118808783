import { all, call } from 'redux-saga/effects'

// import { watchAuth } from 'components/src/bus/auth/saga/watchers'
import { watchThemes } from 'components/src/bus/themes/saga/watchers'
import { watchSettings } from 'components/src/bus/settings/saga/watchers'
import { watchContent } from 'components/src/bus/content/saga/wathcers'
import { watchCart } from 'components/src/bus/cart/saga/wathcers'
import { watchProfile } from 'components/src/bus/profile/saga/watchers'
import { watchUI } from 'components/src/bus/ui/saga/watchers'

function* rootSaga() {
  try {
    yield all([
      //   call(watchAuth),
      call(watchSettings),
      call(watchContent),
      call(watchThemes),
      call(watchCart),
      call(watchProfile),
      call(watchUI),
    ])
  } catch (error) {
    console.error('⛔️ error', error)
  }
}

export default rootSaga
