export class LanguageStorage {
  constructor(key) {
    this.KEY_LNG = key
  }

  KEY_LNG = null

  set(language) {
    return new Promise((resolve) => {
      resolve(localStorage.setItem(this.KEY_LNG, language))
    })
  }

  get() {
    return new Promise((resolve) => {
      resolve(localStorage.getItem(this.KEY_LNG))
    })
  }
}
