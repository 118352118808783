export const apiThemes = new (class Api {
  fetch() {
    return new Promise(() => {
      return localStorage.getItem('theme')
    })
  }

  change(theme) {
    return new Promise(() => {
      return localStorage.setItem('theme', theme)
    })
  }
})()
