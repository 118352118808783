import { StyleSheet } from 'react-native'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      content: {
        flexGrow: 1,
        flexShrink: 0,
      },
      layout: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'space-between',
      },
      container: {
        paddingTop: 55,
        flexShrink: 0,
        flexGrow: 1,
        justifyContent: 'flex-start',
        width: 300,
      },
      preview: {
        marginBottom: 48,
      },
      input: {
        marginTop: 10,
      },
      button: {
        marginTop: 25,
      },
      control: {
        marginTop: 15,
        width: 300,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    }),
    buttonProps: {
      width: 300,
      height: 42,
    },
  }
}
