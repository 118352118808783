export const useDisplayHtml = (html) => {
  const hasDescription = html.search('<hr />') >= 0

  if (hasDescription) {
    const displayHtml = html.split('<hr />')[1]
    return {
      displayHtml,
    }
  }

  return {
    displayHtml: html,
  }
}
