//Core
import { all, call, takeEvery } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import {
  fetchHomeMenuItems,
  fetchContent,
  fetchAdditionalContent,
  fetchMyLocationMenuItems,
  fetchContentSinglePdf,
  fetchOpenLink,
  fetchContentList,
  fetchSingleMenuItemContent,
} from './workers'

function* watchFetchHomeMenuItems() {
  yield takeEvery(types.FETCH_HOME_MENU_ITEMS, fetchHomeMenuItems)
}

function* watchFetchContent() {
  yield takeEvery(types.FETCH_CONTENT, fetchContent)
}

function* watchFetchAdditionalContent() {
  yield takeEvery(types.FETCH_ADDITIONAL_CONTENT, fetchAdditionalContent)
}

function* watchFetchMyLocationMenuItems() {
  yield takeEvery(types.FETCH_MY_LOCATION_MENU_ITEMS, fetchMyLocationMenuItems)
}

function* watchFetchSingleItem() {
  yield takeEvery(types.FETCH_SINGLE_ITEM, fetchSingleMenuItemContent)
}

function* watchFetchSinglePdf() {
  yield takeEvery(types.FETCH_CONTENT_SINGLE_PDF, fetchContentSinglePdf)
}

function* watchFetchOpenLink() {
  yield takeEvery(types.FETCH_OPEN_LINK, fetchOpenLink)
}

function* watchFetchContentList() {
  yield takeEvery(types.FETCH_CONTENT_LIST, fetchContentList)
}

export function* watchContent() {
  yield all([
    call(watchFetchHomeMenuItems),
    call(watchFetchContent),
    call(watchFetchAdditionalContent),
    call(watchFetchMyLocationMenuItems),
    call(watchFetchSingleItem),
    call(watchFetchSinglePdf),
    call(watchFetchOpenLink),
    call(watchFetchContentList),
  ])
}
