import React from 'react'
import { View } from 'react-native'
import Svg, { Path, Rect } from 'react-native-svg'
import PropTypes from 'prop-types'

import logoPaths from './logoPath'

export const Logo = ({ height, style = {}, notFull }) => {
  const _renderPath = () => {
    const logo = logoPaths.logo.map((item, index) => (
      <Path key={index} fill="#E72960" d={item.path} />
    ))
    const transcription = (
      <>
        {logoPaths.transcription.map((item, index) => (
          <Path key={index} fill="#E72960" d={item.path} />
        ))}
        <Rect fill="#E72960" x={491} y={294.3} width={9.1} height={25} />
        <Rect fill="#E72960" x={491} y={282.5} width={9.1} height={7.7} />
      </>
    )
    if (notFull) {
      return logo
    } else {
      return (
        <>
          {logo}
          {transcription}
        </>
      )
    }
  }
  return (
    <View style={style}>
      <Svg
        height={height}
        width={height * 2.4}
        viewBox="108.1 147.6 423.8 182.6"
      >
        {_renderPath()}
      </Svg>
    </View>
  )
}

Logo.propTypes = {
  height: PropTypes.number.isRequired,
  style: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  notFull: PropTypes.bool,
}
