import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const resolver = yupResolver(
  yup.object().shape({
    Email: yup
      .string()
      .email('form.error.email')
      .required('form.error.required'),
    FirstName: yup.string().required('form.error.required'),
    LastName: yup.string().required('form.error.required'),
  })
)
