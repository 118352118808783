import { useEffect } from 'react'
import firebase from 'components/src/services/firebaseInit'

export const useNotificationPermission = () => {
  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      firebase
        .messaging()
        .requestPermission()
        .then(() => {
          return firebase.messaging().getToken()
        })
        .then((token) => {
          console.log('Token:', token)
        })
        .catch(function (err) {
          console.log('Unable to get permission to notify. ', err)
        })

      firebase.messaging().onMessage((payload) => console.log(payload))
    }
  }, [])
}
