import React from 'react'
import PropTypes from 'prop-types'
import { createIconSetFromFontello } from 'react-native-vector-icons'
import config from 'components/src/configs/fontello.json'

const FontelloIcon = createIconSetFromFontello(config)

export const IconNames = config.glyphs.map((icon) => icon.css)
console.log(IconNames)

export const Icon = ({ color, size, name, style }) => {
  return <FontelloIcon name={name} size={size} color={color} style={style} />
}

Icon.propTypes = {
  name: PropTypes.oneOf(IconNames).isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}
