import { ContentContainer, HeaderNavigator } from 'components/src/components'
import React, { useLayoutEffect } from 'react'

import { View } from 'react-native'
import { useFetchData } from './hooks'
import { useStyles } from './styles'

export const ContentMyLocationScreen = ({ navigation, route }) => {
  const isContentParent =
    route.params.hasOwnProperty('childItemUuid') &&
    route.params.childItemUuid &&
    route.params.hasOwnProperty('parentItemUuid') &&
    route.params.parentItemUuid &&
    route.params.childItemUuid === route.params.parentItemUuid &&
    !route.params.hasOwnProperty('contentUuid')

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => (
        <HeaderNavigator
          addBackButton
          addLogo
          {...props}
          isContentParent={isContentParent}
        />
      ),
      title: 'My location COOEE',
    })
  })
  const { styles } = useStyles()
  const { contentItems, isContentItemSummary } = useFetchData(route)

  return (
    <View style={styles.container}>
      {contentItems && (
        <ContentContainer
          screen="location"
          contentItems={contentItems}
          isContentItemSummary={isContentItemSummary}
        />
      )}
    </View>
  )
}
