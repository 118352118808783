import localForage from 'localforage'

localForage.createInstance({
  driver: localForage.INDEXEDDB,
  name: 'myCooee',
  storeName: 'cacheRequest',
})

class CacheStorage {
  getItem = async (key) => {
    try {
      const value = await localForage.getItem(key)
      return value
    } catch (error) {
      console.log(error)
      return null
    }
  }
  setItem = async (key, value) => {
    try {
      await localForage.setItem(key, value)
    } catch (error) {
      console.log(error)
    }
  }
  removeItem = async (key) => {
    try {
      await localForage.removeItem(key)
    } catch (error) {
      console.log(error)
    }
  }
}

const cacheStorage = new CacheStorage()
export default cacheStorage
