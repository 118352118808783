import {
  FlatList,
  Platform,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { Icon, Select } from 'components/src/components'
import React, { useEffect, useRef, useState } from 'react'

import { CheckBox } from './CheckBox'
import { Checkbox } from 'react-native-paper'
import { Description } from '.'
import { Dimensions } from 'react-native'
import { Html5Entities } from 'html-entities'
import PropTypes from 'prop-types'
import { fontFamilies } from '../../../../../configs/fontFamilies'
import { useStyles } from '../styles'
import { useTranslation } from 'react-i18next'

export const CartItem = ({
  data,
  count,
  removeItem,
  changeCountItem,
  options,
  changeOptionItem,
  errors,
  sendHeight,
}) => {
  const [peopleNumber, setPeopleNumber] = useState(count)
  const { cartItemStyles: styles, optionsStyles, pallete } = useStyles()
  const { t } = useTranslation()
  const freeText = useRef('')

  const getSplitedOptionsArray = () => {
    let splitedArray = []
    let optionsSetUuid = new Set(
      options
        .filter((option) => {
          return option.DisplayName
        })
        .map((option) => {
          return option.ProductOptionSetUuid
        })
    )

    for (const setUuid of optionsSetUuid) {
      splitedArray.push(
        options.filter((option) => {
          return option.ProductOptionSetUuid === setUuid
        })
      )
    }

    return splitedArray
  }

  const __getOptions = () => {
    const maxNumber = data.MaximumNumberOfPersons
      ? data.MaximumNumberOfPersons
      : 20
    let arr = []
    for (let i = 0; i < maxNumber; i++) {
      const value = i + 1
      arr.push({ label: value.toString(), value: value.toString() })
    }
    return arr
  }

  const _handleRemoveItem = () => {
    removeItem(data.Uuid)
  }

  const _handleChangeCountItem = (newCount) => {
    changeCountItem(data.Uuid, +newCount)
  }

  const renderFreeText = ({ item }) => {
    const isErrorHere = errors.includes(item.ProductOptionSetUuid)
    freeText.current = item.Text
    return (
      <View style={optionsStyles.box}>
        <Text style={optionsStyles.label}>{t('cart.additional_comments')}</Text>
        <TextInput
          multiline={true}
          style={optionsStyles.textArea}
          defaultValue={freeText.current ?? ''}
          textAlignVertical="top"
          onChangeText={(text) => {
            freeText.current = text
          }}
          onEndEditing={() => {
            if (freeText.current !== item.Text) {
              changeOptionItem(item.Uuid, freeText.current)
            }
          }}
        />
        {item.IsMandatory && (
          <Text
            style={{
              color: isErrorHere ? '#E5235F' : 'black',
            }}
          >
            {`${t('cart.mandatory_field')}`}
          </Text>
        )}
      </View>
    )
  }

  const renderOption = ({ item }) => {
    return (
      <View>
        <CheckBox
          onPress={() => {
            changeOptionItem(item.Uuid, !item.Selected)
          }}
          label={item.DisplayName + ' +' + item.GrossPrice + '€'}
          isSelected={item.Selected}
        />
      </View>
    )
  }

  const renderOptionsSet = (optionsSet) => {
    const isMandatoryText = optionsSet.item[0].IsMandatory
      ? `${t('cart.mandatory_field')}`
      : null
    const isMultipleAllowedText = optionsSet.item[0].MultipleAllowed
      ? `${t('cart.multiple_allowed')}`
      : null
    let subText = ''
    if (!isMandatoryText || !isMultipleAllowedText) {
      subText = isMandatoryText
        ? isMandatoryText
        : '' + isMultipleAllowedText
        ? isMultipleAllowedText
        : ''
    } else {
      subText = isMandatoryText + ' | ' + isMultipleAllowedText
    }
    const isErrorHere = errors.includes(optionsSet.item[0].ProductOptionSetUuid)
    return (
      <View>
        <FlatList
          data={optionsSet.item}
          renderItem={renderOption}
          keyExtractor={(item) => item.Uuid.toString()}
        />
        <View>
          <Text
            style={{
              color: isErrorHere ? '#E5235F' : 'black',
              margin: subText ? 10 : null,
            }}
          >
            {' '}
            {subText}{' '}
          </Text>
        </View>
        <View
          style={{
            borderBottomColor: isErrorHere ? '#E5235F' : 'black',
            borderBottomWidth: 1,
          }}
        />
      </View>
    )
  }

  const sendErrorOrigin = (y) => {
    const checkboxOptions = getSplitedOptionsArray()
    const commentOptions = options?.filter((optionItem) => {
      return optionItem.isCommentary
    })
    let firstError = (firstError = commentOptions.find(
      (elem) =>
        elem.Uuid == errors[0] ||
        elem.ProductOptionSetUuid == errors[0] ||
        elem.ProductUuid == errors[0]
    ))

    if (firstError == undefined) {
      for (let option of checkboxOptions) {
        firstError = option.find(
          (elem) =>
            elem.Uuid == errors[0] ||
            elem.ProductOptionSetUuid == errors[0] ||
            elem.ProductUuid == errors[0]
        )
        if (firstError != undefined) {
          break
        }
      }
    }
    if (firstError != undefined) {
      sendHeight(y)
    }
  }

  const entities = new Html5Entities()

  return (
    <View
      onLayout={(event) => {
        sendErrorOrigin(event.nativeEvent.layout.y)
      }}
      style={styles.container}
    >
      <View style={styles.header}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{data.DisplayName}</Text>
        </View>
        <View style={styles.priceContainer}>
          <Text style={styles.title}>{`${data.GrossPrice} €`}</Text>
          <Text style={styles.subTitle}>{t('product.tax')}</Text>
        </View>
      </View>
      <Description data={data} />
      {data.DisplaySmallPrint && (
        <>
          <View style={styles.additionalContainer}>
            <Text style={styles.title}>{`${t(
              'cart.additional_information'
            )}:`}</Text>
            <Text style={styles.paragraph}>
              {`${entities.decode(data.DisplaySmallPrint).slice(0, 220)}...`}
            </Text>
          </View>
        </>
      )}
      {options && (
        <View style={styles.additionalContainer}>
          <Text style={styles.title}>{`${t('cart.options')}`}</Text>
          <FlatList
            data={getSplitedOptionsArray()}
            renderItem={renderOptionsSet}
            keyExtractor={(item) => item[0].ProductOptionSetUuid.toString()}
            listKey={(item, index) => 'D' + index.toString()}
          />
          <FlatList
            data={options?.filter((optionItem) => {
              return optionItem.isCommentary
            })}
            renderItem={renderFreeText}
            keyExtractor={(item) => item.Uuid.toString()}
            listKey={(item, index) => 'D' + index.toString()}
          />
        </View>
      )}

      <View style={styles.actionContainer}>
        <View style={styles.selectAction}>
          <Text style={[styles.title, styles.titleNumber]}>{`${t(
            'cart.quantity'
          )}`}</Text>
          <Select
            defaultValue={peopleNumber.toString()}
            options={__getOptions()}
            onChange={(value) => _handleChangeCountItem(value)}
          />
        </View>
        <TouchableOpacity onPress={_handleRemoveItem}>
          <Icon
            name="trash"
            color={pallete.primary}
            size={20}
            style={styles.trashIcon}
          />
        </TouchableOpacity>
      </View>
    </View>
  )
}

CartItem.propTypes = {
  data: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  removeItem: PropTypes.func.isRequired,
  changeCountItem: PropTypes.func.isRequired,
}
