import axios from 'axios'
import ENV from 'components/src/configs'
import { LanguageRemember } from './LanguageRemember'
import { cacheAdapter } from './cacheAdapter'

const axiosInstance = axios.create({
  adapter: cacheAdapter,
  baseURL: ENV.BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-Context-Code': 'cooee',
  },
  timeout: 120000,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const language = await LanguageRemember.get()
    if (language) {
      config.headers['Accept-Language'] = language
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
