import { Platform } from 'react-native'

export const fontFamilies = {
  cl_regular: { fontFamily: 'ChampagneLimousines' },
  cl_regular_italic: { fontFamily: 'ChampagneLimousinesItalic' },
  cl_bold: { fontFamily: 'ChampagneLimousinesBold' },
  cl_bold_italic: { fontFamily: 'ChampagneLimousinesBoldItalic' },
  os_regular: { fontFamily: 'OpenSans' },
  os_light:
    Platform.OS === 'web'
      ? { fontFamily: 'OpenSans', fontWeight: '200' }
      : { fontFamily: 'OpenSans-Light' },
  os_bold:
    Platform.OS === 'web'
      ? { fontFamily: 'OpenSans', fontWeight: 'bold' }
      : { fontFamily: 'OpenSans-Bold' },
}
