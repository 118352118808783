import { contentActions, contentSelectors } from 'components/src/bus/content'
import { useCallback, useEffect, useState } from 'react'

import { settingsSelectors } from 'components/src/bus/settings'
import { useDispatch } from 'react-redux'
import { useStoreSelector } from 'components/src/hooks'
import { useTranslation } from 'react-i18next'

const contentStoreSelector = (state) => ({
  isLoading: contentSelectors.isLoading(state),
  menuItems: contentSelectors.getMyLocationMenuItems(state),
  listMyLocation: contentSelectors.getListMyLocation(state),
  myContent: contentSelectors.getMyContent(state),
})

const settingsStoreSelector = (state) => ({
  sites: settingsSelectors.getSites(state),
  selectedSite: settingsSelectors.getSelectedSite(state),
})

export const useFetchData = (uuid) => {
  const [params, setParams] = useState(null)
  const dispatch = useDispatch()
  const { menuItems, isLoading, listMyLocation, myContent } =
    useStoreSelector(contentStoreSelector)
  const { sites, selectedSite } = useStoreSelector(settingsStoreSelector)
  const { i18n } = useTranslation()
  const parent = menuItems.filter((item) => item.Uuid === uuid)[0]
  const isContentItemSummary = parent?.ShowContentItemSummary

  const parentUuid = parent.Uuid
  const parentTitle = menuItems.filter((item) => item.Uuid === uuid)[0]
    .DisplayName
  let children = menuItems.filter((item) => item.ParentUuid === uuid)

  if (children.length === 0) {
    children = myContent.filter((item) => item.ParentUuid === uuid)
  }

  useEffect(() => {
    if (menuItems) {
      if (!params || uuid !== params.parentUuid) {
        setParams({
          screen: 'location',
          parentUuid: uuid,
          menuItems: [
            menuItems.filter((item) => item.Uuid === uuid)[0].Uuid,
            ...menuItems
              .filter((item) => item.ParentUuid === uuid)
              .map((item) => item.Uuid),
            ,
          ],
        })
      }
    }
  }, [menuItems, params, uuid])

  const onChangeLanguage = useCallback(() => {
    if (params) {
      dispatch(contentActions.fetchContentListAsync(params))
    }
  }, [dispatch, params])

  useEffect(() => {
    if (menuItems && !listMyLocation && params) {
      dispatch(contentActions.fetchContentListAsync(params))
    }
  }, [menuItems, dispatch, listMyLocation, params])

  useEffect(() => {
    if (listMyLocation && listMyLocation.parentItemUuid !== uuid && params) {
      dispatch(contentActions.fetchContentListAsync(params))
    }
  }, [dispatch, listMyLocation, params, uuid])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  return {
    isLoading,
    parentUuid,
    parentTitle,
    sites,
    selectedSite,
    listMyLocation,
    children,
    isContentItemSummary,
  }
}
