// Local
import { Button, ViewHtml } from 'components/src/components'
import { Image, ScrollView, Text, View } from 'react-native'

import PropTypes from 'prop-types'
import React from 'react'
import { useHandler } from './hooks'
// Local Dir
import { useStyles } from './styles'
import { useUriImage } from 'components/src/hooks'

export const HotelInfo = ({ data }) => {
  const { styles } = useStyles()
  const { handlerPressButton, buttonTitle } = useHandler({ data })

  const { getUriImage } = useUriImage()

  return (
    <>
      <ScrollView style={styles.scrollViewContainer}>
        <View style={styles.container}>
          <Text style={styles.title}>{data.Name}</Text>
          {data.ImageDocumentUuid && (
            <Image
              source={getUriImage({ uuid: data.ImageDocumentUuid, width: 500 })}
              style={styles.image}
            />
          )}
          <ViewHtml html={data.Description} />
          <Button
            style={styles.button}
            textStyle={styles.buttonText}
            onPress={handlerPressButton}
          >
            {buttonTitle}
          </Button>
        </View>
      </ScrollView>
    </>
  )
}

HotelInfo.propTypes = {
  data: PropTypes.object.isRequired,
  hideAutoComplete: PropTypes.bool,
}

HotelInfo.defaultProps = {
  hideAutoComplete: false,
}
