import { baseEnv } from './base'

const env = baseEnv()

const developmentEnv = {
  ...env,
  IS_PRODUCTION: false,
  IS_DEVELOPMENT: true,
}

export default developmentEnv
