export const myLocation = {
  MyLocation: {
    path: 'my-location',
    screens: {
      Start: '/',
      Content: '/content',
      List: '/content-items',
    },
  },
}
