import { call, put } from 'redux-saga/effects'
// Local
import { uiActions } from '../../index'

export function* openedNotificationMessage({ payload }) {
  try {
    yield put(uiActions.closeStartScreen())
    yield call(uiActions.addAlert, {
      message: `${payload.title}\n${payload.body}`,
    })
  } catch (error) {
    console.log(error, 'Themes change workers')
  }
}
