export const useUriImage = () => {
  const _getUriImage = ({ uuid, width }) => {
    if (uuid) {
      return {
        uri: `https://api.betterspace.de/v1/Documents/${uuid}/Image?width=${width}`,
      }
    }
    return false
  }

  return {
    getUriImage: _getUriImage,
  }
}
