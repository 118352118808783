//Core
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import {
  fetchCountries,
  fetchSalutations,
  fetchSites,
  fetchMemberSites,
} from './workers'

function* watchFetchSalutations() {
  yield takeLatest(types.FETCH_SALUTATION_ASYNC, fetchSalutations)
}

function* watchFetchCountries() {
  yield takeEvery(types.FETCH_COUNTRIES_ASYNC, fetchCountries)
}

function* watchFetchSites() {
  yield takeEvery(types.FETCH_SITES_ASYNC, fetchSites)
}

function* watchFetchMemberSites() {
  yield takeEvery(types.FETCH_MEMBER_SITES_ASYNC, fetchMemberSites)
}

export function* watchSettings() {
  yield all([
    call(watchFetchSalutations),
    call(watchFetchCountries),
    call(watchFetchSites),
    call(watchFetchMemberSites),
  ])
}
