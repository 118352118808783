import PropTypes from 'prop-types'
// Local
import { useTheme } from './useTheme'

export const useColorByScreen = (screen) => {
  const { pallete } = useTheme()
  switch (screen) {
    // case 'location':
    //   return pallete.secondary
    default:
      return pallete.primary
  }
}

useColorByScreen.defaultProps = 'home'

useColorByScreen.propTypes = PropTypes.oneOf(['location', 'home'])
