import { call, put } from 'redux-saga/effects'

// Local
import { themesActions } from 'components/src/bus/themes'

import { apiThemes } from '../../api'
// Local Dir

export function* changeTheme(action) {
  try {
    yield call(apiThemes.change, action.payload)
    yield put(themesActions.changeTheme(action.payload))
  } catch (error) {
    console.log(error, 'Themes change workers')
  }
}
