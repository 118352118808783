import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const resolver = yupResolver(
  yup.object().shape({
    OldPassword: yup.string().required('form.error.required'),
    NewPassword: yup
      .string()
      .required('form.error.required')
      .notOneOf(
        [yup.ref('OldPassword')],
        'form.error.password_not_must_confirm'
      ),
    NewPasswordConfirm: yup
      .string()
      .required('form.error.required')

      .oneOf(
        [yup.ref('NewPassword'), null],
        'form.error.password_must_confirm'
      ),
  })
)
