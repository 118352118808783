// #region Global Imports
import { combineReducers } from '@reduxjs/toolkit'
// #endRegion Global Imports

// #region Local Imports
// import { authReducer } from 'components/src/bus/auth'
import { uiReducer } from 'components/src/bus/ui'
import { settingsReducer } from 'components/src/bus/settings'
import { themesReducer } from 'components/src/bus/themes'

// #region Entities Modules
import { contentReducer } from 'components/src/bus/content'
import { cartReducer } from 'components/src/bus/cart'
import { profileReducer } from 'components/src/bus/profile'
// #endRegion Entities Modules

const entitiesReducer = combineReducers({
  content: contentReducer,
  cart: cartReducer,
  profile: profileReducer,
})

export const rootReducer = combineReducers({
  //   auth: authReducer,
  themes: themesReducer,
  entities: entitiesReducer,
  ui: uiReducer,
  settings: settingsReducer,
})
