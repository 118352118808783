import { StyleSheet } from 'react-native'

import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      textStyle: {
        fontSize: 12,
        color: pallete.defaultText,
        ...fontFamilies.os_light,
        lineHeight: 18,
      },
      tagBr: {
        fontSize: 1,
      },
    }),
    pallete,
  }
}
