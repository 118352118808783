import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import de from './locale/de.json'
import en from './locale/en.json'
import ENV from 'components/src/configs'

// const languageDetector = {
//   type: 'languageDetector',
//   async: true,
//   detect: cb => cb(DEFAULT_LOCALE),
//   init: () => { },
//   cacheUserLanguage: () => { },
// }

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
}

export const languages = Object.keys(resources)

i18n
  // .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: ENV.DEFAULT_LOCALE,
    fallbackLng: ENV.DEFAULT_LOCALE,
    keySeparator: '.',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
