import {
  Button,
  ContentHeader,
  HeaderNavigator,
} from 'components/src/components'
import React, { useLayoutEffect } from 'react'
import { ScrollView, Text, View } from 'react-native'

import { CartItem } from './components'
import { cartActions } from 'components/src/bus/cart'
import { useCart } from './hooks'
import { useDispatch } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useUriImage } from 'components/src/hooks'

export const ThanksCartScreen = ({ navigation, route }) => {
  const { styles, cartItemStyles } = useStyles()
  const { cartItems } = useCart()
  const { getUriImage } = useUriImage()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let backTrigger = false

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => (
        <HeaderNavigator
          addBackButton
          addLogo
          {...props}
          goBackCustom={() => {
            navigation.pop(2)
            backTrigger = true
          }}
        />
      ),
      title: 'Cart COOEE',
    })
  })

  useFocusEffect(
    React.useCallback(() => {
      const unsubscribe = () => {
        dispatch(cartActions.removeAllItems())
        if (!backTrigger) navigation.pop(2)
      }

      return () => unsubscribe()
    }, [navigation])
  )

  return (
    <ScrollView style={styles.container}>
      <View style={styles.content}>
        <ContentHeader
          hotelName={route.params.hotelName}
          source={getUriImage({
            uuid: route.params.imageDocumentUuid,
            width: 340,
          })}
          title={t('cart.title')}
        />
        <Text style={styles.title}>{t('cart.thanks_title')}</Text>
        {cartItems &&
          cartItems.map((item, index) => (
            <View key={item.data.Uuid}>
              <CartItem
                data={item.data}
                count={item.count}
                options={route.params.cartOptions?.filter((option) => {
                  return item.data.Uuid === option.ProductUuid
                })}
              />
              {index !== cartItems.length - 1 && (
                <View style={styles.divider} />
              )}
            </View>
          ))}
        <View style={styles.divider} />
        <View style={cartItemStyles.container}>
          <View style={cartItemStyles.header}>
            <Text style={cartItemStyles.title}>{t('cart.total')}</Text>
            <View style={cartItemStyles.priceContainer}>
              <Text
                style={cartItemStyles.title}
              >{`${route.params.total} €`}</Text>
              <Text style={cartItemStyles.tax}>{t('product.tax')}</Text>
            </View>
          </View>
        </View>
        <Text style={styles.thanksText}>{t('cart.thanks_text')}</Text>
        <Button
          style={styles.button}
          onPress={() => {
            navigation.navigate('Start')
            backTrigger = true
          }}
        >
          {t('cart.back_button')}
        </Button>
      </View>
    </ScrollView>
  )
}
