import { createContext } from 'react'

//themes
import * as palletes from './palletes'

export const themes = {
  cooee: {
    pallete: palletes.cooee,
    name: 'cooee',
  },
}

export const ThemeContext = createContext(themes.light)
