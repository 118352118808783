import axios from 'components/src/services/axios'

export const apiCart = new (class API {
  fetchItems(params) {
    return axios({
      url: '/domain',
      method: 'get',
      params,
    })
  }
  sendGuestOrders({ data, headers }) {
    return axios({
      url: '/GuestOrders',
      method: 'post',
      data,
      headers,
    })
  }
  fetchOptions({ params, headers }) {
    return axios({
      url: '/ProductOptionSets',
      method: 'get',
      params,
      headers,
    })
  }
  fetchOptionBox({ params, headers }) {
    return axios({
      url: '/ProductOptions',
      method: 'get',
      params,
      headers,
    })
  }
})()
