import React, { useState, useCallback, useEffect } from 'react'
import { View, Text } from 'react-native'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { uiActions } from 'components/src/bus/ui'

//layout
import { WelcomeLayout } from 'components/src/layouts'

//components
import { Logo, Button, ModalChangeLanguage } from 'components/src/components'

//service
import { LanguageRemember } from 'components/src/services/LanguageRemember'
import { languages } from 'components/src/i18n'
//local
import { useStyles } from './styles'

export const WelcomeScreen = () => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { styles } = useStyles()

  const [modalLanguage, setModalLanguage] = useState(false)

  const checkLanguageRemember = useCallback(async () => {
    const language = await LanguageRemember.get()
    if (!language || !languages.includes(language)) {
      setModalLanguage(true)
    }
  }, [])

  useEffect(() => {
    checkLanguageRemember()
  }, [checkLanguageRemember])

  const handlerCloseStartScreen = useCallback(
    () => dispatch(uiActions.closeStartScreen()),
    [dispatch]
  )
  const handlerCloseModalLanguage = useCallback(async () => {
    LanguageRemember.set(i18n.language)
    setModalLanguage(false)
  }, [i18n.language])

  return (
    <WelcomeLayout>
      <View style={styles.container}>
        <Logo height={94} />
        <Text style={styles.text}>{t('titles.welcome')}</Text>
        <Button
          width={275}
          height={55}
          style={styles.button}
          textStyle={styles.buttonText}
          onPress={handlerCloseStartScreen}
        >
          {t('buttons.enter')}
        </Button>
      </View>
      <ModalChangeLanguage
        modalVisible={modalLanguage}
        onChange={handlerCloseModalLanguage}
        onClose={handlerCloseModalLanguage}
      />
    </WelcomeLayout>
  )
}
