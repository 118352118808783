import { Text, TouchableOpacity, View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'

import { CustomImage } from 'components/src/components'
import PropTypes from 'prop-types'
import React from 'react'
import { profileSelectors } from 'components/src/bus/profile'
import { useActions } from './hooks'
import { useSelector } from 'react-redux'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useUriImage } from 'components/src/hooks'

export const ProductItem = ({ data, imageUuid, onPressAction }) => {
  const isAuthenticated = useSelector(profileSelectors.getCurrent)
  const { styles } = useStyles()
  const { t } = useTranslation()
  const { getUriImage } = useUriImage()
  const { addToCart } = useActions(data)
  const navigation = useNavigation()
  const route = useRoute()

  const _handlePress = () => {
    addToCart()
    if (isAuthenticated) {
      onPressAction(data)
    } else {
      navigation.navigate('Profile', {
        screen: 'Authenticated',
        params: {
          redirect: route,
        },
      })
    }
  }

  return (
    <View style={styles.container}>
      <CustomImage
        style={styles.image}
        source={getUriImage({ uuid: imageUuid, width: 65 })}
      />
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{data.DisplayName}</Text>
      </View>
      <View style={styles.priceContainer}>
        <Text style={styles.price}>{`${data.GrossPrice.toFixed(2).replace(
          '.',
          ','
        )} ${data.CurrencyCode}`}</Text>
        <Text style={styles.tax}>{t('product.tax')}</Text>
      </View>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={styles.button} onPress={_handlePress}>
          <Text style={styles.buttonLabel}>{data.DisplayOrderTypeName}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
ProductItem.defaultProps = {
  onPressAction: (data) => {
    console.log(data)
  },
}

ProductItem.propTypes = {
  data: PropTypes.object.isRequired,
  imageUuid: PropTypes.string.isRequired,
  onPressAction: PropTypes.func.isRequired,
}
