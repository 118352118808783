import { all, call, put, select, take } from 'redux-saga/effects'
import { profileActions, profileSelectors } from 'components/src/bus/profile'

import { AuthService } from 'components/src/services/AuthService'
//local
import { apiProfile } from '../../api'
import { cartActions } from '../../../cart'
import { types } from '../../types'
import { uiActions } from 'components/src/bus/ui'

export function* signIn(action) {
  try {
    yield put(profileActions.toggleLoading(true))
    const response = yield call(apiProfile.signIn, action.payload)

    if (response.data && response.data.Token) {
      yield call([AuthService, 'setToken'], response.data.Token)
      yield put(profileActions.fetchProfileAsync())
      yield take(types.END_FETCH_PROFILE)
      const profile = yield select(profileSelectors.getCurrent)
      if (profile) {
        yield call([AuthService, 'setAuthData'], action.payload)
        yield put(cartActions.toggleModal())
      } else {
        yield put(cartActions.removeAllItems())
        yield put(
          uiActions.addAlert({ message: 'form.error.email_or_password_wrong' })
        )
      }
    } else {
      // yield put(
      //   uiActions.addAlert({ message: 'form.error.email_or_password_wrong' })
      // )
      yield put(profileActions.toggleIsWrongData(true))
      throw new Error()
    }
  } catch (error) {
    yield put(profileActions.forceLogoutAsync())
    yield take(types.END_FORCE_LOGOUT)
    yield put(uiActions.emitError(error, 'Profile signIn workers'))
  } finally {
    yield put(profileActions.toggleLoading(false))
    yield put({ type: types.END_SIGN_IN })
  }
}

export function* autoLogin() {
  try {
    const authData = yield call([AuthService, 'getAuthData'])
    if (authData.Password || authData.Username) {
      yield put(profileActions.signInAsync(authData))
      yield take(types.END_SIGN_IN)
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Profile autoLogin workers'))
  } finally {
    yield put(uiActions.deleteAlert())
  }
}

export function* forceLogout() {
  try {
    yield call([AuthService, 'deleteToken'])
    yield call([AuthService, 'deleteAuthData'])
    yield all([
      yield put(profileActions.clearCurrentProfile()),
      yield put(profileActions.clearBookmarkedSites()),
    ])
  } catch (error) {
    yield put(uiActions.emitError(error, 'Profile forceLogout workers'))
  } finally {
    yield put({ type: types.END_FORCE_LOGOUT })
  }
}

export function* resetPassword(action) {
  //TODO reset password messages
  try {
    yield put(profileActions.toggleLoading(true))
    const response = yield call(apiProfile.resetPassword, action.payload)
    console.log(JSON.stringify(response))
  } catch (error) {
    console.log('Er:', JSON.stringify(error))
    yield put(uiActions.emitError(error, 'Profile resetPassword workers'))
  } finally {
    yield put(profileActions.toggleLoading(false))
  }
}
