import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
      },
      container: {
        alignItems: 'center',
        backgroundColor: '#ffffff',
        paddingTop: 30,
        paddingBottom: 40,
        width: 300,
        borderWidth: 1,
        borderColor: '#e5e5e5',
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowColor: '#000000',
        shadowRadius: 10,
        shadowOpacity: 0.3,
        elevation: 10,
      },
      item: {
        paddingHorizontal: 10,
        paddingVertical: 6,
        borderBottomColor: '#e5e5e5',
      },
      itemText: {
        textAlign: 'center',
        fontSize: 18,
        ...fontFamilies.cl_bold,
      },
      title: {
        fontSize: 22,
        ...fontFamilies.cl_bold,
        marginBottom: 30,
        paddingHorizontal: 25,
        textAlign: 'center',
      },
      button: {
        marginTop: 10,
      },
    }),
  }
}
