import React from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-native'
import SvgXml from './SvgXml'
// import Svg, { Path } from 'react-native-svg'
// import ReactHtmlParser from 'react-html-parser'

export const CardIcon = ({ uuid, svg, height, width, fill }) => {
  // Secure parse svg
  //   const _svgParse = (node, index) => {
  //     if (node.name === 'svg') {
  //       return (
  //         <Svg
  //           key={index}
  //           height={height}
  //           width={width}
  //           fill={fill}
  //           viewBox={node.attribs.viewbox}
  //           strokeLinejoin={node.attribs.strokelinejoin}
  //         >
  //           {node.children.map(
  //             (child, index) =>
  //               child.name === 'path' && <Path key={index} {...child.attribs} />
  //           )}
  //         </Svg>
  //       )
  //     }
  //   }

  //   return ReactHtmlParser(svg, { transform: _svgParse })
  // end Secure parse svg
  // return <SvgXml xml={svg} heigh={height} width={width} fill={fill} />
  if (uuid) {
    return (
      <Image
        style={{ height: height, width: width }}
        resizeMode="contain"
        source={{
          uri: `https://api.betterspace.de/v1/Documents/${uuid}/Image`,
          cache: 'force-cache',
        }}
      />
    )
  }

  if (svg) {
    return <SvgXml xml={svg} height={height} width={width} fill={fill} />
  }

  return null
}

CardIcon.propTypes = {
  uuid: PropTypes.string,
  svg: PropTypes.string,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
}
