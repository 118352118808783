import { useColorByScreen, useTheme } from 'components/src/hooks'

import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = (screen) => {
  const { pallete } = useTheme()
  const color = useColorByScreen(screen)
  return {
    styles: StyleSheet.create({
      wrapper: {
        marginBottom: 15,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
      container: {
        width: 150,
        height: 150,
        justifyContent: 'flex-end',
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 18,
        color: pallete.cardText,
        textAlign: 'center',
      },
      titleWrapper: {
        backgroundColor: pallete.primary,
        width: '100%',
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 8,
        paddingBottom: 8,
        opacity: 0.95,
      },
    }),
  }
}
