const actionCreators = {
  SIGN_IN: 'PROFILE/SIGN_IN',
  END_SIGN_IN: 'PROFILE/END_SIGN_IN',
  CREATE_PROFILE: 'PROFILE/CREATE_PROFILE',
  FETCH_PROFILE: 'PROFILE/FETCH_PROFILE',
  END_FETCH_PROFILE: 'PROFILE/END_FETCH_PROFILE',
  FORCE_LOGOUT: 'PROFILE/FORCE_LOGOUT',
  END_FORCE_LOGOUT: 'PROFILE/END_FORCE_LOGOUT',
  AUTO_LOGIN: 'PROFILE/AUTO_LOGIN',
  UPDATE_PROFILE: 'PROFILE/UPDATE_PROFILE',
  UPDATE_PASSWORD: 'PROFILE/UPDATE_PASSWORD',
  RESET_PASSWORD: 'PROFILE/RESET_PASSWORD',
  FETCH_BOOKMARKED_SITES: 'PROFILE/FETCH_BOOKMARKED_SITES',
  CREATE_BOOKMARKED_SITES: 'PROFILE/CREATE_BOOKMARKED_SITES',
  DELETE_BOOKMARKED_SITES: 'PROFILE/DELETE_BOOKMARKED_SITES',
  CREATE_REDIRECT: 'PROFILE/CREATE_REDIRECT',
  DELETE_REDIRECT: 'PROFILE/DELETE_REDIRECT',
}

export const types = Object.freeze(actionCreators)
