import { contentActions, contentSelectors } from 'components/src/bus/content'
import { useCallback, useEffect } from 'react'

import AsyncStorage from '@react-native-async-storage/async-storage'
import { cartActions } from 'components/src/bus/cart'
import { settingsActions } from 'components/src/bus/settings'
import { settingsSelectors } from 'components/src/bus/settings'
import { useDispatch } from 'react-redux'
import { useStoreSelector } from 'components/src/hooks'
import { useTranslation } from 'react-i18next'

const storeSelector = (state) => ({
  content: contentSelectors.getContent(state),
  isLoading: contentSelectors.isLoading(state),
  contentMenuItemUuid: contentSelectors.getContentMenuItemUuid(state),
  hotels: settingsSelectors.getMemberSitesWithBookmark(state),
})

export const useFetchData = (itemUuid) => {
  const dispatch = useDispatch()
  const { content, count, isLoading, contentMenuItemUuid, hotels } =
    useStoreSelector(storeSelector)
  const { i18n } = useTranslation()

  const onChangeLanguage = useCallback(() => {
    dispatch(
      contentActions.fetchContentAsync({
        uuid: itemUuid,
        screen: 'home',
      })
    )
  }, [dispatch, itemUuid])

  const _handleSelectSite = (uuid) => {
    if (uuid !== itemUuid) {
      AsyncStorage.setItem('selectedSite', uuid)
      dispatch(settingsActions.saveSelectedSite(uuid))
      dispatch(
        contentActions.fetchMyLocationMenuItemsAsync({
          params: { maxRows: 1000 },
          headers: { 'X-Context-SiteUuid': uuid },
        })
      )
    }
  }

  useEffect(() => {
    itemUuid &&
      contentMenuItemUuid !== itemUuid &&
      dispatch(
        contentActions.fetchContentAsync({
          uuid: itemUuid,
          screen: 'home',
        })
      )
  }, [dispatch, itemUuid, contentMenuItemUuid])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  return {
    content,
    count,
    isLoading,
    hotels,
    _handleSelectSite,
  }
}
