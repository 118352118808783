import { call, put } from 'redux-saga/effects'
// Local
import { settingsActions } from 'components/src/bus/settings'
import { uiActions } from 'components/src/bus/ui'
// Local Dir
import { apiSettings } from '../../api'

export function* fetchSalutations(action) {
  try {
    yield put(uiActions.toggleLoading(true))

    // Api call or some async actions
    const response = yield call(apiSettings.fetchSalutations, action.payload)

    if (response.data.Status === 'OK') {
      yield put(settingsActions.saveSalutations(response.data.Items))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'settings fetchSalutations workers'))
  } finally {
    yield put(settingsActions.toggleLoading(false))
  }
}

export function* fetchCountries(action) {
  try {
    yield put(settingsActions.toggleLoading(true))

    // Api call or some async actions
    const response = yield call(apiSettings.fetchCountries, action.payload)

    if (response.data.Status === 'OK') {
      yield put(settingsActions.saveCountries(response.data.Items))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(
        error.response.data,
        'settings fetchCountries workers'
      )
    )
  } finally {
    yield put(settingsActions.toggleLoading(false))
  }
}

export function* fetchSites(action) {
  try {
    yield put(settingsActions.toggleLoading(true))
    const response = yield call(apiSettings.fetchSites)
    if (response.data?.Status === 'OK') {
      yield put(settingsActions.saveSites(response.data.Items))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'settings fetchSites workers'))
  } finally {
    yield put(settingsActions.toggleLoading(false))
  }
}

export function* fetchMemberSites(action) {
  try {
    yield put(settingsActions.toggleLoading(true))
    const response = yield call(apiSettings.fetchMemberSites)
    if (response.data.Items) {
      yield put(settingsActions.saveMemberSites(response.data.Items))
      yield put(listOfCurrentCountryCode(response.data.Items))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'settings fetchMemberSites workers'))
  } finally {
    yield put(settingsActions.toggleLoading(false))
  }
}

function* listOfCurrentCountryCode(payload) {
  const countriesArray = payload.map((item) => item.CountryCode)
  yield put(
    settingsActions.saveCurrentCountries(Array.from(new Set(countriesArray)))
  )
}
