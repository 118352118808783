import { call, delay, put, select } from 'redux-saga/effects'
import { cartActions, cartSelectors } from 'components/src/bus/cart'

import { apiCart } from '../../api'

// Local

export function* changeOptionsCart(action) {
  let cartOptions = yield select(cartSelectors.getOptions)
  const optionUuid = action.payload.optionUuid
  const newValue = action.payload.newValue

  const selectedOption = cartOptions.find((option) => {
    if (option.Uuid === optionUuid) {
      return option
    }
  })

  const isMultipleSelectionAllowed = selectedOption.MultipleAllowed
  const productSetUuid = selectedOption.ProductOptionSetUuid

  if (selectedOption.DisplayName && !isMultipleSelectionAllowed) {
    cartOptions = cartOptions.map((option) => {
      if (
        option.ProductOptionSetUuid &&
        option.ProductOptionSetUuid === productSetUuid &&
        option.DisplayName
      ) {
        //this option is select-box
        return {
          ...option,
          Selected: false,
        }
      } else {
        //this option is free-text
        return option
      }
    })
  }

  cartOptions = cartOptions.map((option) => {
    if (option.Uuid === optionUuid) {
      if (option.DisplayName) {
        //this option is select-box
        return {
          ...option,
          Selected: newValue,
        }
      } else {
        //this option is free-text
        return {
          ...option,
          Text: newValue,
          isCommentary: true,
        }
      }
    } else {
      return option
    }
  })

  yield put(cartActions.saveOptions(cartOptions))
}

export function* addItemToCart(action) {
  const cartItems = yield select(cartSelectors.getCartItems)
  if (!cartItems) {
    yield put(cartActions.saveItems([{ data: action.payload, count: 1 }]))
  } else {
    const repeatItem =
      cartItems.filter((item) => item.data === action.payload).length > 0
    if (!repeatItem) {
      yield put(
        cartActions.saveItems([
          ...cartItems,
          ...[{ data: action.payload, count: 1 }],
        ])
      )
    } else {
      yield put(
        cartActions.saveItems(
          cartItems.map((item) =>
            item.data === action.payload
              ? {
                  ...item,
                  count:
                    ++Object.keys(item).length <= 20
                      ? ++Object.keys(item).length
                      : 20,
                }
              : item
          )
        )
      )
    }
  }
}

export function* removeItemFromCart(action) {
  const cartItems = yield select(cartSelectors.getCartItems)
  const newItems = cartItems.filter((item) => item.data.Uuid !== action.payload)
  yield put(cartActions.saveItems(newItems.length >= 1 ? newItems : null))
}

export function* changeItemCartCount(action) {
  const cartItems = yield select(cartSelectors.getCartItems)
  yield put(
    cartActions.saveItems(
      cartItems.map((item) =>
        item.data.Uuid === action.payload.uuid
          ? { ...item, count: action.payload.count }
          : item
      )
    )
  )
}

export function* removeAllItems(action) {
  yield put(cartActions.saveItems(null))
}

export function* sendGuestOrders(action) {
  yield call(apiCart.sendGuestOrders, {
    data: action.payload.data,
    headers: action.payload.headers,
  })
}
