import { Platform, StyleSheet } from 'react-native'

import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        width: '100%',
        maxWidth: 440,
        height: Platform.OS === 'web' ? 172 / 1.5 : 172 / 1.42,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingRight: 8,
        paddingLeft: 8,
        marginBottom: 16,
      },
      title: {
        fontSize: 16,
        color: pallete.actionText,
        ...fontFamilies.cl_bold,
      },
      imageContainer: {
        flexDirection: 'row',
        minHeight: 110,
        alignSelf: 'stretch',
      },
      contentImage: {
        width: 170,
        height: Platform.OS === 'web' ? 172 / 1.5 : 172 / 1.42,
        alignSelf: 'stretch',
        resizeMode: 'cover',
      },
      content: {
        flexDirection: 'row',
        position: 'relative',
        height: Platform.OS === 'web' ? 172 / 1.5 : 172 / 1.42,
      },
      shortText: {
        ...fontFamilies.cl_regular,
        color: pallete.defaultText,
        fontSize: 14,
        flex: 1,
      },
      contentDescription: {
        justifyContent: 'space-between',
        flex: 1,
        marginLeft: 12,
      },
      buttonLabel: {
        color: pallete.primary,
        fontSize: 16,
        ...fontFamilies.cl_regular,
      },
      button: {
        marginRight: 'auto',
      },
      favourite: {
        position: 'absolute',
        zIndex: 10,
        left: 6,
        top: 6,
      },
      shortDescription: {
        overflow: 'hidden',
        height: 200,
      },
      shortDescriptionWrapper: {
        overflow: 'hidden',
        height: Platform.OS === 'web' ? 172 / 1.5 - 16 : 172 / 1.42 - 14,
      },
    }),
  }
}
