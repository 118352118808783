import {
  HeaderNavigator,
  ListContentCard,
  Logo,
  Preloader,
} from 'components/src/components'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import { useFetchData, useSettings } from './hooks'

import { Card } from '../../../components/Card'
import { MainLayout } from '../../../layouts/MainLayout'
import { useStyles } from './styles'

export const ListMyLocationScreen = ({ navigation, route }) => {
  const [loading, setLoading] = useState(true)
  const { styles, pallete } = useStyles()
  const {
    isLoading,
    parentUuid,
    parentTitle,
    sites,
    selectedSite,
    listMyLocation,
    isContentItemSummary,
    children,
  } = useFetchData(route.params.uuid)
  const { getHotelName } = useSettings()

  const isContentParent =
    route.params.hasOwnProperty('isContentParent') &&
    route.params.isContentParent

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addBackButton {...props} />,
      title: 'My location COOEE',
    })
  }, [navigation])

  useEffect(() => {
    if (loading !== isLoading) {
      setLoading(isLoading)
    }
  }, [isLoading, loading])

  const _goNext = (props) => {
    navigation.navigate('Content', props)
  }

  useEffect(() => {
    if (isContentParent) {
      // just to be sure that data has been loaded... :(
      setTimeout(() => {
        navigation.pop()
        navigation.navigate('Content', {
          childItemUuid: route.params.uuid,
          parentItemUuid: route.params.uuid,
        })
      }, 100)
    }
  }, [route.params])
  return (
    <MainLayout>
      <ScrollView style={isContentItemSummary && styles.wrapper}>
        <View style={styles.container}>
          <Logo style={styles.logo} height={100} />
          {isContentParent ? (
            <Preloader screen="location" />
          ) : (
            <>
              <Text style={styles.hotelTitle}>
                {getHotelName(sites, selectedSite)}
              </Text>
              <View style={styles.titleWrapper}>
                <Text style={styles.title}>{parentTitle}</Text>
              </View>
              {isLoading ? (
                <Preloader screen="location" />
              ) : isContentItemSummary ? (
                listMyLocation && (
                  <>
                    {listMyLocation.listItems.map(
                      (item) =>
                        item.contents.length > 0 && (
                          <ListContentCard
                            key={item.menuItemUuid}
                            screen="location"
                            data={item}
                            onPress={() =>
                              _goNext({
                                parentItemUuid: parentUuid,
                                childItemUuid: item.menuItemUuid,
                              })
                            }
                          />
                        )
                    )}
                  </>
                )
              ) : (
                children && (
                  <View style={styles.cardsWrapper}>
                    {children.map((item) => {
                      return (
                        <Card
                          key={item.Uuid}
                          data={item}
                          background={pallete.secondary}
                          onPress={() =>
                            _goNext({
                              parentItemUuid: parentUuid,
                              childItemUuid: item.Uuid,
                            })
                          }
                        />
                      )
                    })}
                  </View>
                )
              )}
            </>
          )}
        </View>
      </ScrollView>
    </MainLayout>
  )
}
