import React from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
// Local
import { TabNavigator } from '../TabNavigator'
import { uiSelectors } from 'components/src/bus/ui'
import { useNotificationPermission } from 'components/src/hooks'

//screens
import { WelcomeScreen } from 'components/src/screens'

const App = createStackNavigator()

export const AppNavigation = () => {
  const isStartScreen = useSelector((state) => uiSelectors.isStartScreen(state))

  useNotificationPermission()

  return (
    <App.Navigator headerMode="none">
      {isStartScreen ? (
        <App.Screen
          name="Welcome"
          component={WelcomeScreen}
          options={{ headerShown: false, title: 'Welcome to COOEE' }}
        />
      ) : (
        <App.Screen
          name="Tabs"
          component={TabNavigator}
          options={{ headerShown: false }}
        />
      )}
    </App.Navigator>
  )
}
