import truncate from 'truncate-html'

import { useStyles } from '../styles'

export const useShortDescription = (html) => {
  const { pallete } = useStyles()
  if (!html) {
    return {
      shortHtml: '',
    }
  }

  const containDescription = html.search('<hr />') >= 0

  if (containDescription) {
    const descriptionHtml = html
      .split('<hr />')[0]
      .replace(
        /&gt;/g,
        `<span style="color: ${pallete.actionText}">&gt;</span>`
      )

    return {
      shortHtml: descriptionHtml,
    }
  }

  return {
    shortHtml: truncate(html, {
      length: 200,
      stripTags: false,
    }),
  }
}
