import React from 'react'
import { SafeAreaView } from 'react-native'
import { KeyboardAware } from 'components/src/components'
//local
import { useStyles } from './styles'

export const BaseLayout = (props) => {
  const { styles } = useStyles()

  return (
    <SafeAreaView style={styles.safeArea}>
      <KeyboardAware {...props} style={[styles.container, props?.style]} />
    </SafeAreaView>
  )
}
