import { AppRegistry } from 'react-native'
import * as Sentry from '@sentry/react'
// Local
import { sentryConfig } from './sentryConfig'
import { registerServiceWorker } from './firebaseServiceWorker'

import App from 'components/src/App'

Sentry.init(sentryConfig)

AppRegistry.registerComponent('mycooee', () => App)
AppRegistry.runApplication('mycooee', {
  rootTag: document.getElementById('root'),
})

registerServiceWorker()
