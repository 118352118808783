import { useState, useEffect } from 'react'
import getDistance from 'geolib/es/getDistance'

import { useStoreSelector } from 'components/src/hooks'
import { settingsSelectors } from 'components/src/bus/settings'

const storeSelector = (state) => ({
  sites: settingsSelectors.getSites(state),
  selectedSites: settingsSelectors.getSelectedSite(state),
})

export const useDistanceCalculate = () => {
  const [origin, setOrigin] = useState(null)
  const { sites, selectedSites } = useStoreSelector(storeSelector)

  useEffect(() => {
    if (sites && selectedSites) {
      const currentSiteArr = sites.filter((site) => site.Uuid === selectedSites)
      const currentSite = currentSiteArr.length > 0 ? currentSiteArr[0] : null
      setOrigin({
        lat: currentSite?.Latitude || 0,
        lng: currentSite?.Longitude || 0,
      })
    }
  }, [selectedSites, sites])

  const _handleCalculate = (destination) => {
    if (!origin) {
      return null
    }
    const distance = getDistance(
      { latitude: origin.lat, longitude: origin.lng },
      { latitude: destination.lat, longitude: destination.lng }
    )
    return (distance / 1000).toFixed(2)
  }
  return {
    distanceCalculate: _handleCalculate,
  }
}
