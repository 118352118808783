import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useStyles } from './styles'

export const Select = ({ defaultValue, options, selectStyle, onChange }) => {
  const [value, setValue] = useState(defaultValue)
  const { styles } = useStyles()

  const _handleChange = (onValueChange) => {
    setValue(onValueChange)
    onChange && onChange(onValueChange)
  }

  return (
    <>
      <select
        style={{ height: 25 }}
        onChange={(item) => _handleChange(item.target.value)}
        defaultValue={value}
      >
        {options.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </>
  )
}

Select.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}
