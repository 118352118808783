import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { View, Text, TouchableOpacity, Modal } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { useStyles } from './styles'

export const PreviewCartModal = ({ isOpen, setIsOpen }) => {
  const { styles } = useStyles()
  const { t } = useTranslation()
  const navigation = useNavigation()

  const _handleCloseModal = () => {
    setIsOpen(false)
  }

  const _goToCart = () => {
    _handleCloseModal()
    navigation.navigate('Cart')
  }

  return (
    <Modal animationType="fade" transparent={true} visible={isOpen}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <View style={styles.box}>
            <Text style={styles.title}>{t('cart.title')}</Text>
            <View style={styles.description}>
              <Text style={styles.textDescription}>
                {t('cart.success_added_alert')}
              </Text>
            </View>
            <View style={styles.buttonsContainer}>
              <TouchableOpacity
                style={[styles.button]}
                onPress={_handleCloseModal}
              >
                <Text style={styles.buttonLabel}>{t('cart.continue')}</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={_goToCart}>
                <Text style={styles.buttonLabel}>{t('cart.go_to_cart')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

PreviewCartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
}
