import { StyleSheet, Platform } from 'react-native'

import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()

  return {
    styles: StyleSheet.create({
      container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 5,
      },
      containerLabel: {
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      label: {
        ...fontFamilies.os_light,
        fontSize: 10,
        color: pallete.iconButtonText,
        textAlign: 'center',
        lineHeight: 11,
        marginTop: 2,
      },
    }),
    iconButtonIconColor: pallete.iconButtonIcon,
  }
}
