import { createSelector } from 'reselect'
import { settingsSelectors } from 'components/src/bus/settings'

export const getCurrent = (state) => state.entities.profile.current
export const isLoading = (state) => state.entities.profile.loading
export const isAccountAlreadyExist = (state) =>
  state.entities.profile.isAccountAlreadyExist
export const getBookmarkedSites = (state) =>
  state.entities.profile.bookmarkedSites
export const isWrongData = (state) => state.entities.profile.isWrongData
export const getFavoriteSites = createSelector(
  [settingsSelectors.getMemberSites, getBookmarkedSites],
  (members, bookmarkeds) => {
    return members
      ? members
          .filter((member) =>
            bookmarkeds
              ? bookmarkeds.find(
                  (bookmarked) => bookmarked.Uuid === member.Uuid
                )
              : false
          )
          .map((member) => ({ ...member, IsFavorite: true }))
      : null
  }
)
