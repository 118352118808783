import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      container: {
        marginTop: 55,
        marginBottom: 40,
      },
      title: {
        fontSize: 28,
        ...fontFamilies.cl_bold,
        textAlign: 'center',
        marginBottom: 30,
      },
      listWrapper: {
        alignItems: 'center',
      },
      benefitCard: {
        marginTop: 30,
      },
    }),
  }
}
