import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useStoreSelector } from 'components/src/hooks'
//store
import { contentActions, contentSelectors } from 'components/src/bus/content'
import { settingsActions, settingsSelectors } from 'components/src/bus/settings'

const storeSelector = (state) => ({
  homeMenuItems: contentSelectors.getHomeMenuItems(state),
  isLoading: contentSelectors.isLoading(state),
  sites: settingsSelectors.getSites(state),
  selectedSite: settingsSelectors.getSelectedSite(state),
  countries: settingsSelectors.getCountries(state),
  memberSites: settingsSelectors.getMemberSites(state),
})

export const useFetchData = () => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const {
    homeMenuItems,
    isLoading,
    sites,
    selectedSite,
    countries,
    memberSites,
  } = useStoreSelector(storeSelector)

  useEffect(() => {
    !homeMenuItems &&
      dispatch(contentActions.fetchHomeMenuItemsAsync({ maxRows: 10000 }))
  }, [dispatch, homeMenuItems])

  const onChangeLanguage = useCallback(() => {
    dispatch(contentActions.fetchHomeMenuItemsAsync({ maxRows: 10000 }))
    dispatch(settingsActions.fetchSites())
    dispatch(settingsActions.fetchCountries())
    dispatch(settingsActions.fetchMemberSites())
  }, [dispatch])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  useEffect(() => {
    !sites && dispatch(settingsActions.fetchSites())
  }, [dispatch, sites])

  useEffect(() => {
    !countries && dispatch(settingsActions.fetchCountries())
  }, [countries, dispatch])

  // useEffect(() => {
  //   if (!selectedSite && memberSites) {
  //     const sitesHasContent = memberSites.filter((site) => site.HasContent)
  //     dispatch(settingsActions.saveSelectedSite(sitesHasContent[0].Uuid))
  //   }
  // }, [dispatch, selectedSite, memberSites])

  useEffect(() => {
    !memberSites && dispatch(settingsActions.fetchMemberSites())
  }, [dispatch, memberSites])

  return {
    homeMenuItems,
    isLoading,
  }
}
