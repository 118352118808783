import { call, put } from 'redux-saga/effects'
// Local
import { profileActions } from 'components/src/bus/profile'
import { uiActions } from 'components/src/bus/ui'
// Local Dir
import { apiProfile } from '../../api'

export function* deleteBookmarkedSites(action) {
  try {
    const { data } = yield call([apiProfile, 'deleteBookmarkedSites'], {
      Uuid: action.payload,
    })
    if (data && data.Status === 'OK') {
      yield put(profileActions.deleteBookmarkedSites(action.payload))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error, 'Profile deleteBookmarkedSites workers')
    )
  }
}
