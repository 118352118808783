import { StyleSheet } from 'react-native'

import { useTheme } from 'components/src/hooks'

export const useStyles = ({ height, width }) => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {},
      imageSlider: {
        marginLeft: 10,
        marginRight: 10,
        position: 'relative',
      },
      dot: {
        width: 5,
        height: 5,
        borderRadius: 2.5,
        borderWidth: 1,
        borderColor: pallete.dot,
        marginLeft: 5,
        marginRight: 5,
      },
      dotActive: {
        backgroundColor: pallete.dot,
      },

      //CustomImage styles
      imageContainer: {
        height: height - 30,
        width: width - 15,
        position: 'relative',
      },
      image: {
        width: width - 20,
        height: height - 35,
        marginLeft: 5,
        position: 'relative',
        zIndex: 10,
      },
      imageBackground: {
        width: width - 80,
        height: height - 60,
        backgroundColor: pallete.primary,
        position: 'absolute',
        bottom: 0,
      },
      singleImage: {
        marginBottom: 20,
      },
    }),
  }
}
