import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View, Text, ImageBackground, TouchableOpacity } from 'react-native'

import { useUriImage } from 'components/src/hooks'
import { Preloader } from 'components/src/components'

import { useStyles } from './styles'

export const ContentCard = ({ data, onPress, screen }) => {
  const [loading, setLoading] = useState(true)
  const { styles } = useStyles(screen)
  const { getUriImage } = useUriImage()
  const _endLoad = () => {
    setLoading(false)
  }
  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.wrapper}
      activeOpacity={0.8}
    >
      <ImageBackground
        style={styles.container}
        source={getUriImage({ uuid: data.ImageDocumentUuid, width: 200 })}
        onLoadEnd={_endLoad}
      >
        {loading && <Preloader {...{ screen }} />}
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{data.DisplayName}</Text>
        </View>
      </ImageBackground>
    </TouchableOpacity>
  )
}

ContentCard.defaultValue = {
  screen: 'home',
}

ContentCard.propTypes = {
  data: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
  screen: PropTypes.oneOf(['location', 'home']),
}
