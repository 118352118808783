export const useNavigationBack = () => {
  const _navigationBack = (navigation) => {
    const checkIsLast = (name, state, nav) => {
      const routeIndex = state.routeNames.findIndex(
        (routeName) => routeName === name
      )
      const parentNav = nav.dangerouslyGetParent()

      if (routeIndex !== 0 && !!parentNav) {
        const parentState = parentNav.dangerouslyGetState()
        const parentType = parentState.type
        if (parentType !== 'tab') {
          nav.reset({
            index: 0,
            routes: [{ name: parentState.routes[0].name }],
          })
        } else {
          nav.reset({
            index: parentState.index,
            routes: [{ name: parentState.routeNames[parentState.index] }],
          })
        }
      } else {
        if (parentNav.dangerouslyGetState().type !== 'tab') {
          stackBack(parentNav)
        }
      }
    }

    const stackBack = (nav) => {
      const state = nav.dangerouslyGetState()
      const index = state.index
      if (index !== 0) {
        navigation.navigate({
          ...state.routes[index - 1],
        })
      } else {
        checkIsLast(state.routes[index].name, state, nav)
      }
    }

    stackBack(navigation)
  }

  return {
    navigationBack: _navigationBack,
  }
}
