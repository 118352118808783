import { call, delay, put } from 'redux-saga/effects'

import { apiCart } from '../../api'
import { cartActions } from 'components/src/bus/cart'
import { uiActions } from 'components/src/bus/ui'

// Local

// Local Dir

export function* fetchItems(action) {
  try {
    if (action.payload.searchString) {
      yield delay(600)
    }

    yield put(cartActions.toggleLoading(true))

    // Api call or some async actions
    const response = yield call(apiCart.fetchItems, action.payload)

    if (response.data) {
      yield put(
        cartActions.saveItems({
          items: response.data.items,
          count: response.data.count,
        })
      )
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Cart fetchItems workers'))
  } finally {
    yield put(cartActions.toggleLoading(false))
  }
}

export function* fetchDetail(action) {
  try {
    yield put(cartActions.toggleLoading(true))

    // Api call or some async actions
    const response = yield call(apiCart.fetchDetail, action.payload)

    if (response.data) {
      yield put(cartActions.saveDetail(response.data))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error.response.data, 'Cart fetchDetail workers')
    )
  } finally {
    yield put(cartActions.toggleLoading(false))
  }
}

export function* fetchOptions(action) {
  try {
    console.log('Fetching options for current products...')

    const cartItems = action.payload.data
    const selectedSite = action.payload.selectedSite
    let options = []

    for (const item of cartItems) {
      const response = yield call(apiCart.fetchOptions, {
        params: {
          productUuid: item.data.Uuid,
        },
        headers: { 'X-Context-SiteUuid': selectedSite },
      })
      let items = response.data.Items
      console.log(JSON.stringify(items))
      //get items only with type Select-box for current product
      items = items.filter((currentItem) => {
        if (currentItem.ProductOptionTypeId === 2) {
          return true
        } else {
          //save freetext info
          const freeTextOption = {
            Uuid: currentItem.Uuid,
            ProductUuid: currentItem.ProductUuid,
            ProductOptionSetUuid: currentItem.Uuid,
            isCommentary: true,
            IsMandatory: currentItem.IsMandatory,
          }
          options.push(freeTextOption)
        }
      })

      //get option boxes info
      for (const optionBoxItem of items) {
        const optionBox = yield call(apiCart.fetchOptionBox, {
          params: {
            productOptionSetUuid: optionBoxItem.Uuid,
          },
          headers: action.payload.headers,
        })
        let optionBoxItems = optionBox.data.Items

        //save select-box options info
        for (const item of optionBoxItems) {
          const boxResponse = {
            DisplayName: item.DisplayName,
            Uuid: item.Uuid,
            GrossPrice: item.GrossPrice,
            ProductOptionSetUuid: item.ProductOptionSetUuid,
            ProductUuid: item.ProductUuid,
            Selected: false,
            IsMandatory: optionBoxItem.IsMandatory,
            MultipleAllowed: optionBoxItem.MultipleAllowed,
          }
          options.push(boxResponse)
        }
      }
    }
    yield put(cartActions.saveOptions(options))
  } catch (error) {
    console.log('Error while fetching options')
  } finally {
  }
}
