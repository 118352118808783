import React, { useLayoutEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
//navigators
import { AuthNavigator } from '../AuthNavigator'

//screens
import {
  FavoriteHotelScreen,
  HotelContentHomeScreen,
} from 'components/src/screens'

//store
import { profileSelectors } from 'components/src/bus/profile'

const FavoriteProfile = createStackNavigator()

export const FavoriteProfileNavigator = ({ route, navigation }) => {
  const isAuthenticated = useSelector(profileSelectors.getCurrent)
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  })

  return (
    <FavoriteProfile.Navigator backBehavior="initialRoute" headerMode="screen">
      {isAuthenticated ? (
        <>
          <FavoriteProfile.Screen
            name="FavoriteHotel"
            component={FavoriteHotelScreen}
          />
          <FavoriteProfile.Screen
            name="FavoriteHotelContent"
            component={HotelContentHomeScreen}
          />
        </>
      ) : (
        <FavoriteProfile.Screen
          name="Authenticated"
          component={AuthNavigator}
          options={{
            headerShown: false,
          }}
        />
      )}
    </FavoriteProfile.Navigator>
  )
}
