//components

import {
  Button,
  ButtonChangeLanguage,
  ButtonIcon,
  HeaderNavigator,
  ProfilePreview,
  TextInput,
} from 'components/src/components'
import { Controller, useForm } from 'react-hook-form'
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Text, View } from 'react-native'
import { profileActions, profileSelectors } from 'components/src/bus/profile'
import { useDispatch, useSelector } from 'react-redux'

import { BaseLayout } from 'components/src/layouts'
import { ModalLogout } from './components'
import { cartActions } from '../../../bus/cart'
import { resolver } from './resolver'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

//local

export const EditProfileScreen = ({ navigation }) => {
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false)
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addLogo addBackButton {...props} />,
    })
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const { styles, buttonProps } = useStyles()

  const { t } = useTranslation()

  useEffect(() => {
    let update
    if (isSaved) {
      update = setTimeout(() => {
        setIsSaved(false)
      }, 4000)
    }
    return () => {
      clearTimeout(update)
    }
  }, [isSaved])

  const profile = useSelector(profileSelectors.getCurrent)

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      Email: profile?.Email || '',
      FirstName: profile?.FirstName || '',
      LastName: profile?.LastName || '',
    },
    resolver,
  })

  const _callbackSubmit = () => {
    setIsSaved(true)
    setIsLoading(false)
  }

  const _callbackError = () => {
    setIsLoading(false)
  }

  const onSubmit = (data) => {
    if (!isLoading) {
      setIsLoading(true)
      setIsSaved(false)
      dispatch(
        profileActions.updateProfileAsync({
          data,
          callBackSubmit: _callbackSubmit,
          callBackError: _callbackError,
        })
      )
    }
  }

  const handleChange = (onChange) => {
    return (value) => {
      onChange(value)
    }
  }

  const handlerConfirmLogout = useCallback(() => {
    setModalVisible(false)
    dispatch(cartActions.removeAllItems())
    dispatch(profileActions.forceLogoutAsync())
  }, [dispatch])

  return (
    <BaseLayout contentContainerStyle={styles.content}>
      <View style={styles.layout}>
        <View style={styles.container}>
          <ProfilePreview style={styles.preview} />
          {isSaved && (
            <Text style={styles.successUpdate}>{t('titles.saved')}</Text>
          )}
          <Controller
            name="Email"
            control={control}
            render={({ value, onChange }) => (
              <TextInput
                autoCapitalize="none"
                style={styles.input}
                label={t('form.label.email')}
                value={value}
                onChange={handleChange(onChange)}
                error={errors?.Email && t(errors?.Email.message)}
              />
            )}
          />
          <Controller
            name="FirstName"
            control={control}
            render={({ value, onChange }) => (
              <TextInput
                style={styles.input}
                label={t('form.label.firstName')}
                value={value}
                onChange={handleChange(onChange)}
                error={errors?.FirstName && t(errors?.FirstName.message)}
              />
            )}
          />
          <Controller
            name="LastName"
            control={control}
            render={({ value, onChange }) => (
              <TextInput
                style={styles.input}
                label={t('form.label.lastName')}
                value={value}
                onChange={handleChange(onChange)}
                error={errors?.LastName && t(errors?.LastName.message)}
              />
            )}
          />
          <Button
            loading={isLoading}
            style={styles.button}
            {...buttonProps}
            onPress={handleSubmit(onSubmit)}
          >
            Save
          </Button>
          <Button
            style={styles.button}
            {...buttonProps}
            onPress={() => navigation.goBack()}
          >
            {t('buttons.back_overview')}
          </Button>
          <Button
            style={styles.button}
            {...buttonProps}
            onPress={() => navigation.navigate('ChangePassword')}
          >
            {t('buttons.change_password')}
          </Button>
        </View>
        <View style={styles.control}>
          <ButtonChangeLanguage />
          <ButtonIcon
            onPress={() => setModalVisible(true)}
            icon="logout"
            label={t('buttons.logout')}
          />
        </View>
      </View>
      <ModalLogout
        modalVisible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onConfirm={handlerConfirmLogout}
      />
    </BaseLayout>
  )
}
