import React, { useLayoutEffect } from 'react'
import { View, Text } from 'react-native'
import { useTranslation } from 'react-i18next'
//components
import { HeaderNavigator, BenefitCard } from 'components/src/components'
import { BaseLayout } from 'components/src/layouts'

//local
import { useStyles } from './styles'
import { useData } from './hooks'

export const BenefitsProfileScreen = ({ navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addLogo addBackButton {...props} />,
    })
  })

  const { t } = useTranslation()

  const { styles } = useStyles()

  const { items } = useData()

  return (
    <BaseLayout>
      <View style={styles.container}>
        <Text style={styles.title}>{t('profile.menu.my_benefits')}</Text>
        <View style={styles.listWrapper}>
          {items.map((item, index) => (
            <BenefitCard
              key={`benefit-${index}`}
              name={item.name}
              icon={item.icon.replace('fill="#000000"', '')}
              style={styles.benefitCard}
            />
          ))}
        </View>
      </View>
    </BaseLayout>
  )
}
