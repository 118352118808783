/* eslint-disable react-hooks/rules-of-hooks */
import {
  Button,
  ContactCard,
  ContainerPdf,
  ContentCard,
  ContentHeader,
  ImageSlider,
  Preloader,
  PreviewCartModal,
  ProductItem,
  RoutePlaneButton,
  ViewHtml,
  ViewPdf,
} from 'components/src/components'
import {
  FlatList,
  Linking,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native'
import React, { useState } from 'react'
import { cartActions, cartSelectors } from 'components/src/bus/cart'
import { useDistanceCalculate, useUriImage } from 'components/src/hooks'

import { ListContentCard } from '../ListContentCard'
import { Logo } from '../Logo'
import { MainLayout } from '../../layouts/MainLayout'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useFetchData } from './hooks'
import { useRoute } from '@react-navigation/native'
import { useStoreSelector } from 'components/src/hooks'
import { useStyles } from './styles'
import { useStyles as useStylesMyLocationScreens } from '../../screens/MyLocationScreens/ListMyLocationScreen/styles'

const selectStore = (state) => ({
  cartModal: cartSelectors.getCartModal(state),
})

export const ContentContainer = ({
  contentItems,
  screen,
  isContentItemSummary = false,
}) => {
  const dispatch = useDispatch()
  const [openPdf, setOpenPdf] = useState(false)
  const [pdfUuid, setPdfUuid] = useState('')
  const { cartModal } = useStoreSelector(selectStore)
  const toggleModal = (value) => {
    dispatch(cartActions.toggleModal(value || !cartModal))
  }
  const { styles } = useStyles(screen)
  const stylesMyLocationScreens = useStylesMyLocationScreens().styles
  const {
    isLoading,
    actionContentCard,
    singlePdf,
    contentImages,
    contentPdf,
    contentWebsite,
    contentGeoPosition,
    contentProducts,
    contentContacts,
    myLocationMenuItems,
  } = useFetchData(contentItems)
  const { getUriImage } = useUriImage()
  const { distanceCalculate } = useDistanceCalculate()

  const _handleTitle = () => {
    // if (contentGeoPosition) {
    //   const distance = distanceCalculate(contentGeoPosition)
    //   return distance
    //     ? `${contentItems[0].DisplayName} (${distance} KM)`
    //     : contentItems[0].DisplayName
    // }

    return contentItems && contentItems.length > 0
      ? contentItems[0].DisplayName
      : ''
  }

  if (!contentItems || isLoading) {
    return <Preloader {...{ screen }} />
  }

  const _handleLink = (url) => {
    Platform.OS === 'web' ? window.open(url, '_blank') : Linking.openURL(url)
  }

  const _handlePdf = (uuid) => {
    if (uuid) {
      setPdfUuid(uuid)
      setOpenPdf(true)
    } else {
      console.log('empty')
    }
  }

  const cardItem = ({ item }) =>
    isContentItemSummary ? (
      <ListContentCard
        key={item.Uuid}
        screen="location"
        data={{ contents: [item] }}
        onPress={() => actionContentCard(item.Uuid)}
      />
    ) : (
      <ContentCard
        screen={screen}
        key={item.Uuid}
        data={item}
        onPress={() => actionContentCard(item)}
      />
    )

  const route = useRoute()

  const isContentParent =
    route.params.hasOwnProperty('childItemUuid') &&
    route.params.childItemUuid &&
    route.params.hasOwnProperty('parentItemUuid') &&
    route.params.parentItemUuid &&
    route.params.childItemUuid === route.params.parentItemUuid

  if (contentItems && contentItems.length > 1) {
    const Content = () => (
      <FlatList
        style={styles.container}
        data={contentItems}
        renderItem={cardItem}
        numColumns={isContentItemSummary ? 1 : 2}
        keyExtractor={(item) => item.Uuid}
      />
    )

    const parentUuid =
      route.params.hasOwnProperty('parentItemUuid') &&
      route.params.parentItemUuid
        ? route.params.parentItemUuid
        : ''

    const parentMenuItem = myLocationMenuItems.find(
      (i) => i.Uuid === parentUuid
    )

    let title = null

    if (
      parentMenuItem &&
      parentMenuItem.DisplayName &&
      parentMenuItem.DisplayName.length > 0
    ) {
      title = parentMenuItem.DisplayName
    }

    return isContentParent ? (
      <MainLayout>
        <ScrollView
          style={isContentItemSummary && stylesMyLocationScreens.wrapper}
        >
          <View style={stylesMyLocationScreens.container}>
            <Logo style={stylesMyLocationScreens.logo} height={100} />
            {title && (
              <View style={stylesMyLocationScreens.titleWrapper}>
                <Text style={stylesMyLocationScreens.title}>{title}</Text>
              </View>
            )}
            <Content />
          </View>
        </ScrollView>
      </MainLayout>
    ) : (
      <Content />
    )
  }

  if (
    contentItems &&
    contentItems.length > 0 &&
    contentItems[0].ContentItemTypeId === 4 &&
    !!singlePdf
  ) {
    return <ContainerPdf uuid={singlePdf} />
  }

  return (
    <ScrollView>
      {contentItems && contentItems.length > 0 ? (
        <View style={styles.container}>
          <ContentHeader
            screen={screen}
            title={_handleTitle()}
            source={getUriImage({
              uuid: contentItems[0].ImageDocumentUuid,
              width: 300,
            })}
          />
          {contentItems[0].DisplayText && (
            <ViewHtml screen={screen} html={contentItems[0].DisplayText} />
          )}
          <View style={styles.additionalWrapper}>
            {contentImages && (
              <View style={styles.sliderWrapper}>
                <ImageSlider images={contentImages} />
              </View>
            )}
            {contentPdf && (
              <View style={styles.pdfLinkWrapper}>
                {contentPdf.map((pdf, index) => (
                  <Button
                    key={index}
                    style={styles.button}
                    textStyle={styles.buttonLabel}
                    onPress={() => _handlePdf(pdf.Translations[0].DocumentUuid)}
                  >
                    {pdf.DisplayName}
                  </Button>
                ))}
                <ViewPdf
                  isOpen={openPdf}
                  setIsOpen={setOpenPdf}
                  uuid={pdfUuid}
                />
              </View>
            )}
            {contentItems[0].Website && (
              <Button
                style={styles.button}
                onPress={() => _handleLink(contentItems[0].Website)}
              >
                {contentWebsite?.Caption || 'Website'}
              </Button>
            )}
            {!contentItems[0].Website && contentWebsite && (
              <Button
                style={styles.button}
                onPress={() => _handleLink(contentWebsite.Website)}
              >
                {contentWebsite?.Caption || 'Website'}
              </Button>
            )}
            {contentGeoPosition && (
              <RoutePlaneButton screen={screen} position={contentGeoPosition} />
            )}
            {contentContacts && (
              <ContactCard screen={screen} data={contentContacts} />
            )}
            {contentProducts && contentProducts.length > 0 && (
              <>
                <View style={styles.productsContainer}>
                  <View style={styles.productsDivider} />
                  {contentProducts.map((product) => (
                    <ProductItem
                      key={product.Uuid}
                      data={product}
                      imageUuid={contentItems[0].ImageDocumentUuid}
                      onPressAction={toggleModal}
                    />
                  ))}
                </View>
                <PreviewCartModal isOpen={cartModal} setIsOpen={toggleModal} />
              </>
            )}
          </View>
        </View>
      ) : (
        <></>
      )}
    </ScrollView>
  )
}

ContentContainer.defaultProps = {
  screen: 'home',
}

ContentContainer.propTypes = {
  contentItems: PropTypes.array.isRequired,
  screen: PropTypes.oneOf(['location', 'home']),
}
