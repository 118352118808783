import React, { useLayoutEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
//navigators
import { AuthNavigator } from '../AuthNavigator'

//screens
import { EditProfileScreen, ChangePasswordScreen } from 'components/src/screens'

//store
import { profileSelectors } from 'components/src/bus/profile'

const EditProfileStack = createStackNavigator()
const UpdateProfileStack = createStackNavigator()

const UpdateProfileNavigator = ({ navigation }) => {
  return (
    <UpdateProfileStack.Navigator headerMode="screen">
      <UpdateProfileStack.Screen
        name="ChangeProfile"
        component={EditProfileScreen}
      />
      <UpdateProfileStack.Screen
        name="ChangePassword"
        component={ChangePasswordScreen}
      />
    </UpdateProfileStack.Navigator>
  )
}

export const EditProfileNavigator = ({ navigation }) => {
  const isAuthenticated = useSelector(profileSelectors.getCurrent)
  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  })

  return (
    <EditProfileStack.Navigator backBehavior="initialRoute" headerMode="none">
      {isAuthenticated ? (
        <EditProfileStack.Screen
          name="UpdateProfile"
          component={UpdateProfileNavigator}
        />
      ) : (
        <EditProfileStack.Screen
          name="Authenticated"
          component={AuthNavigator}
        />
      )}
    </EditProfileStack.Navigator>
  )
}
