import { StyleSheet } from 'react-native'
import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      content: {
        flexGrow: 1,
        flexShrink: 0,
      },
      layout: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
      },
      container: {
        width: 300,
        paddingTop: 55,
        flex: 1,
        justifyContent: 'space-between',
      },
      messageContainer: {
        paddingHorizontal: 24,
        paddingTop: 9,
        paddingBottom: 12,
        borderWidth: 1,
        borderColor: pallete.actionBorder,
        marginBottom: 10,
      },
      messageTitle: {
        fontSize: 22,
        ...fontFamilies.cl_bold,
        color: pallete.defaultText,
        textAlign: 'center',
      },
      messageText: {
        marginVertical: 12,
        fontSize: 16,
        fontFamily: 'OpenSans-Light',
        color: pallete.defaultText,
        textAlign: 'center',
      },
      button: {
        marginTop: 10,
      },

      footer: {
        marginTop: 15,
        alignItems: 'flex-start',
      },
    }),
    buttonProps: {
      width: 300,
      height: 42,
    },
  }
}
