import axios from 'components/src/services/axios'

export const apiContent = new (class API {
  fetchMenuItems({ params, headers }) {
    return axios({
      url: '/menuItems',
      method: 'get',
      headers,
      params,
    })
  }
  fetchContent({ params, headers }) {
    params = { ...params, extras: 'Translations' }
    return axios({
      url: '/ContentItems',
      method: 'get',
      headers,
      params,
    })
  }
  fetchOpeningHours(uuid) {
    return axios({
      url: `/ContentItems/${uuid}/OpeningHoursText`,
      method: 'get',
    })
  }
  fetchContentItemsDocuments(params) {
    return axios({
      url: '/ContentItemDocuments',
      method: 'get',
      params,
    })
  }
  fetchContentPdf(documentUuid) {
    return axios({
      url: `/ContentItemDocuments/${documentUuid}`,
      params: {
        extras: 'Translations',
      },
    })
  }
  fetchWebSite(contentUuid) {
    return axios({
      url: `/ContentItems/${contentUuid}/WebsiteData`,
      method: 'get',
    })
  }
  fetchSinglePdf(uuid) {
    return axios({
      url: `/ContentItems/${uuid}/DocumentUuid`,
      method: 'get',
    })
  }
  fetchProducts(uuid) {
    return axios({
      url: '/Products',
      method: 'get',
      params: {
        contentItemUuid: uuid,
        maxRows: 1000,
      },
    })
  }
})()
