import { Dimensions, StyleSheet } from 'react-native'

import { getStatusBarHeight } from 'components/src/services/IphoneXHelper'
import { useTheme } from 'components/src/hooks'

const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flexGrow: 1,
        backgroundColor: pallete.containerBackground,
        paddingTop: getStatusBarHeight(true),
      },
      closeButton: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
        right: 15,
        top: getStatusBarHeight(true) + 15,
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: pallete.primary,
        tintColor: 'white',
      },
      pdfContainer: {
        flexGrow: 1,
        height: '100%',
      },
      preloadContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: [{ translateX: -15 }, { translateY: -15 }],
      },
      containerError: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: pallete.cardBackground,
      },
      errorText: {
        color: 'white',
        fontSize: 20,
        textAlign: 'center',
        paddingLeft: 15,
        paddingRight: 15,
      },
      errorIcon: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        width: 50,
        borderRadius: 30,
        borderWidth: 2,
        marginBottom: 25,
        borderColor: 'red',
      },
    }),
    stylesWeb: StyleSheet.create({
      closeButton: {
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
        right: 15,
        top: 15,
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: pallete.cardBackground,
      },
      closeButtonIcon: {
        height: 14,
        width: 14,
        tintColor: 'white',
      },
      pdfWrapper: {
        height: height,
        overflow: 'scroll',
        marginBottom: 40,
        alignItems: width < 600 ? 'flex-start' : 'center',
      },
      pageWrapper: {
        borderBottomWidth: 1,
        borderTopWidth: 1,
      },
    }),
  }
}
