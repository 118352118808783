import { ContentContainer, HeaderNavigator } from 'components/src/components'
import React, { useLayoutEffect } from 'react'

import { View } from 'react-native'
import { useFetchData } from './hooks'
import { useStyles } from './styles'

export const ContentHomeScreen = ({ navigation, route }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addBackButton addLogo {...props} />,
      title: 'My location COOEE',
    })
  })
  const { styles } = useStyles()
  const { contentItems } = useFetchData(route)

  return (
    <View style={styles.container}>
      {contentItems && <ContentContainer contentItems={contentItems} />}
    </View>
  )
}
