import React, { useLayoutEffect } from 'react'
import { View, Text } from 'react-native'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
//components
import {
  TextInput,
  Button,
  ButtonChangeLanguage,
  HeaderNavigator,
} from 'components/src/components'
import { BaseLayout } from 'components/src/layouts'

//local
import { useStyles } from './styles'
import { resolver } from './resolver'
import { profileActions, profileSelectors } from 'components/src/bus/profile'

export const RestorePassword = ({ navigation }) => {
  const { styles, buttonProps } = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isLoading = useSelector(profileSelectors.isLoading)

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addLogo addBackButton {...props} />,
    })
  })

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      Username: '',
    },
    resolver,
  })

  const onSubmit = handleSubmit((data) => {
    dispatch(profileActions.resetPasswordAsync(data))
  })

  return (
    <BaseLayout contentContainerStyle={styles.content}>
      <View style={styles.layout}>
        <View style={styles.container}>
          <View>
            <View style={styles.messageContainer}>
              <Text style={styles.messageTitle}>
                {t('profile.restore_password.t_1')}
              </Text>
              <Text style={styles.messageText}>
                {t('profile.restore_password.t_2')}
              </Text>
              <Text style={styles.messageTitle}>
                {t('profile.restore_password.t_3')}
              </Text>
            </View>
            <Controller
              name="Username"
              control={control}
              render={({ value, onChange }) => (
                <TextInput
                  label={t('form.label.username')}
                  autoCapitalize="none"
                  value={value}
                  onChange={onChange}
                  error={errors?.Username && t(errors?.Username.message)}
                />
              )}
            />

            <Button
              loading={isLoading}
              style={styles.button}
              {...buttonProps}
              onPress={onSubmit}
            >
              {t('buttons.request_new_password')}
            </Button>
          </View>
          <View style={styles.footer}>
            <ButtonChangeLanguage />
          </View>
        </View>
      </View>
    </BaseLayout>
  )
}
