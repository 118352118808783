import { contentSelectors } from 'components/src/bus/content'
import { useStoreSelector } from 'components/src/hooks'

const storeSelector = (state) => ({
  menuItems: contentSelectors.getHomeMenuItems(state),
  listHome: contentSelectors.getListHome(state),
})

export const useGetData = (uuid) => {
  const { menuItems, listHome } = useStoreSelector(storeSelector)

  const checkItem = (item, arr) => {
    const isParent =
      menuItems.filter((menuItem) => menuItem.ParentUuid === item.Uuid).length >
      0
    if (item.MenuItemTypeId === 5) {
      arr.push({ ...item, actionType: 'link' })
    }
    if (
      (item.MenuItemTypeId === 6 && isParent) ||
      (!item.MenuItemTypeId && isParent)
    ) {
      const childrenItem = menuItems.filter(
        (menuItem) => menuItem.ParentUuid === item.Uuid
      )
      const childrenIsParent =
        childrenItem.filter(
          (child) =>
            menuItems.filter((menuItem) => menuItem.ParentUuid === child.Uuid)
              .length > 0
        ).length > 0
      if (childrenIsParent) {
        arr.push({ ...item, actionType: 'item-list' })
      } else {
        arr.push({ ...item, actionType: 'list' })
      }
    }
    if (item.MenuItemTypeId === 6 && !isParent) {
      arr.push({ ...item, actionType: 'single' })
    }
  }

  const structureItems = () => {
    const items = menuItems.filter((item) => item.ParentUuid === uuid)
    const customArr = []
    items.map((item) => checkItem(item, customArr))
    return customArr
  }

  return {
    menuItems: structureItems(),
    listHome,
  }
}
