import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

export const useData = () => {
  const navigation = useNavigation()
  const { t } = useTranslation()
  const MenuItems = [
    {
      DisplayName: t('profile.menu.my_profile'),
      onPress: () => {
        navigation.navigate('EditProfile')
      },
      IconSvg:
        '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="#e5235f"/><path d="M12 2c-4.97 0-9 4.03-9 9 0 4.17 2.84 7.67 6.69 8.69L12 22l2.31-2.31C18.16 18.67 21 15.17 21 11c0-4.97-4.03-9-9-9zm0 2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.3c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>',
    },
    {
      DisplayName: t('profile.menu.my_favorite_hotels'),
      onPress: () => {
        navigation.navigate('FavoriteProfile')
      },
      IconSvg:
        '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="204.000000pt" height="282.000000pt" viewBox="0 0 204.000000 282.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,282.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M795 2800 c-374 -85 -669 -374 -766 -748 -47 -181 -34 -383 37 -603 112 -347 391 -863 662 -1224 147 -197 212 -239 336 -221 65 10 116 50 201 156 269 336 577 892 701 1265 65 196 77 273 71 434 -6 162 -28 254 -92 388 -139 286 -405 493 -718 557 -106 22 -327 20 -432 -4z m437 -154 c384 -97 657 -439 658 -823 0 -107 -17 -197 -66 -342 -107 -322 -370 -806 -620 -1146 -174 -236 -186 -238 -340 -34 -281 371 -574 916 -669 1245 -50 171 -56 282 -26 429 69 335 337 608 666 678 113 25 285 21 397 -7z"></path><path d="M947 2209 c-101 -24 -188 -95 -233 -193 -19 -41 -24 -67 -24 -136 0 -69 5 -95 24 -136 70 -151 231 -230 387 -190 107 28 181 88 227 186 22 46 27 69 27 140 0 71 -5 94 -27 140 -45 96 -120 158 -223 185 -65 17 -101 18 -158 4z m151 -135 c174 -72 174 -316 0 -388 -114 -48 -247 21 -280 147 -41 158 128 305 280 241z"></path></g></svg>',
    },
  ]
  return {
    MenuItems,
  }
}
