import React from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { NavigationContainer } from '@react-navigation/native'
import { Platform } from 'react-native'
import { PersistGate } from 'redux-persist/integration/react'
// Locale
import AppNavigation, { linking } from './navigation'
import { persistor, store } from './store'
import { navigationRef } from './services/RootNavigation'
import { WebFonts } from './components'
import { ThemeContext, themes } from 'components/src/themes'
import { useBootstrap } from 'components/src/hooks'
//store
import { themesSelectors } from 'components/src/bus/themes'

const App = () => {
  const themeType = themesSelectors.getTheme(store.getState())

  useBootstrap(store)

  return (
    <StoreProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {Platform.OS === 'web' ? <WebFonts /> : null}
        <ThemeContext.Provider value={themes[themeType] || themes.cooee}>
          <NavigationContainer ref={navigationRef} linking={linking}>
            <AppNavigation />
          </NavigationContainer>
        </ThemeContext.Provider>
      </PersistGate>
    </StoreProvider>
  )
}

export default App
