import React from 'react'
import PropTypes from 'prop-types'
import { View, Text, FlatList } from 'react-native'
import { Html5Entities } from 'html-entities'
import { useTranslation } from 'react-i18next'

import { useStyles } from '../styles'

export const CartItem = ({ data, count, options }) => {
  const { cartItemStyles: styles } = useStyles()
  const { t } = useTranslation()
  const entities = new Html5Entities()

  var renderData = ({item}) => {
    if(item.Selected)
      return <View >
        <Text style={[styles.title, {textAlign: 'right', marginRight: 50}]}>{item.GrossPrice + '€'}</Text>
      </View>

  }
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>{data.DisplayName}</Text>
        <View style={styles.priceContainer}>
          <Text style={styles.title}>{`${data.GrossPrice * count} €`}</Text>
          <Text style={styles.tax}>{t('product.tax')}</Text>
        </View>
      </View>
      {data.DisplaySmallPrint && (
        <View style={styles.infoContainer}>
          <Text style={styles.title}>{`${t(
            'cart.additional_information'
          )}:`}</Text>
          <Text style={styles.paragraph}>
            {`${entities.decode(data.DisplaySmallPrint).slice(0, 220)}...`}
          </Text>
        </View>
      )}
      <View style={styles.countContainer}>
        <Text style={styles.title}>{t('cart.quantity')}</Text>
        <Text style={styles.count}>{count}</Text>
      </View>
      <View style={styles.optionContainer}>
        <Text style={styles.title}>{t('cart.options')}</Text>
        <FlatList
          data={options?.filter((optionItem) => {
                return optionItem.DisplayName
          })}
          renderItem={renderData}
          keyExtractor={(item) => item.Uuid.toString()}/>
      </View>
    </View>
  )
}

CartItem.propTypes = {
  data: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
}
