import { call, put, all } from 'redux-saga/effects'
// Local
import { navigate } from 'components/src/services/RootNavigation'
import { contentActions } from 'components/src/bus/content'
import { uiActions } from 'components/src/bus/ui'
// Local Dir
import {
  fetchGeneralContent,
  fetchSiteContent,
  fetchWebsite,
  fetchContentListItem,
} from './fetch.workers'

/**
 * @param {
 *    screen: "home" | "location"
 *    parentUuid: Uuid
 *    menuItems: array[Uuid]
 * }
 */
export function* fetchContentList(action) {
  try {
    yield put(contentActions.toggleLoading(true))
    if (action.payload.screen === 'home') {
      const listItems = yield all(
        action.payload.menuItems.map((item) =>
          call(fetchContentListItem, {
            screen: action.payload.screen,
            uuid: item,
          })
        )
      )
      yield put(
        contentActions.saveListHome({
          parentItemUuid: action.payload.parentUuid,
          listItems,
        })
      )
    }
    if (action.payload.screen === 'location') {
      const listItems = yield all(
        action.payload.menuItems.map((item) =>
          call(fetchContentListItem, {
            screen: action.payload.screen,
            uuid: item,
          })
        )
      )
      yield put(
        contentActions.saveListMyLocation({
          parentItemUuid: action.payload.parentUuid,
          listItems,
        })
      )
    }
    return
  } catch (error) {
    yield put(uiActions.emitError(error, 'Content fetchContentList workers'))
  } finally {
    yield put(contentActions.toggleLoading(false))
  }
}

export function* fetchSingleMenuItemContent(action) {
  try {
    yield put(contentActions.toggleLoading(true))
    let contents
    if (action.payload.screen === 'home') {
      contents = yield call(fetchGeneralContent, action.payload.uuid)
    }
    if (action.payload.screen === 'location') {
      contents = yield call(fetchSiteContent, action.payload.uuid)
    }
    if (contents && contents.length > 0) {
      if (contents.length === 1 && contents[0].ContentItemTypeId === 3) {
        yield call(fetchWebsite, contents[0].Uuid)
      } else {
        if (action.payload.screen === 'home') {
          yield put(contentActions.saveContentUuid(action.payload.uuid))
          yield put(contentActions.saveContent(contents))
          navigate('Content', { singleItemUuid: action.payload.uuid })
        }
        if (action.payload.screen === 'location') {
          yield put(contentActions.saveMyContentUuid(action.payload.uuid))
          yield put(contentActions.saveMyModContent({contents, uuid: action.payload.uuid}))
          if(contents.length > 1) {
            navigate('List', { uuid: action.payload.uuid,isContentParent: true })
          } else {
            navigate('Content', { singleItemUuid: action.payload.uuid })
          }
        }
      }
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error, 'Content fetchSingleMenuItemContent workers')
    )
  } finally {
    yield put(contentActions.toggleLoading(false))
  }
}
