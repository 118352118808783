import React from 'react'
import { View } from 'react-native'

import { TabItem } from './components'
import { tabBarStyles as styles } from './styles'

export const TabBar = ({ state, descriptors, navigation }) => {
  return (
    <View style={styles.container}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key]

        const isFocused = state.index === index

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          })

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate(route.name)
          }
        }

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key,
          })
        }

        return (
          <TabItem
            key={index}
            isFocused={isFocused}
            onPress={onPress}
            onLongPress={onLongPress}
            options={options}
            valueTo={isFocused ? 1 : 0}
            iconName={
              isFocused ? options.tabIcon : `${options.tabIcon}-outlined`
            }
          />
        )
      })}
    </View>
  )
}
