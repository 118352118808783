import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

import { useStyles } from './styles'

export const ContactCard = ({ data, screen }) => {
  const { styles } = useStyles(screen)
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('content.contact.title')}</Text>
      {data.street && <Text style={styles.item}>{data.street}</Text>}
      {data.city && (
        <Text style={styles.item}>{`${data.postalCode} ${data.city}`}</Text>
      )}
      {data.phone && (
        <Text style={styles.item}>{`${t('content.contact.phone')}: ${
          data.phone
        }`}</Text>
      )}
      {data.email && (
        <Text style={styles.item}>{`${t('content.contact.email')}: ${
          data.email
        }`}</Text>
      )}
    </View>
  )
}

ContactCard.defaultValue = {
  screen: 'home',
}

ContactCard.propTypes = {
  data: PropTypes.object.isRequired,
  screen: PropTypes.oneOf(['location', 'home']),
}
