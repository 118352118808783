import { StyleSheet } from 'react-native'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
        paddingTop: 18,
      },
      logoContainer: {
        marginBottom: 45,
      },
      cardsWrapper: {
        width: '100%',
        maxWidth: 337,
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 20,
        paddingLeft: 6,
        paddingRight: 6,
      },
    }),
  }
}
