import { contentActions, contentSelectors } from 'components/src/bus/content'
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useStoreSelector } from 'components/src/hooks'
import { useTranslation } from 'react-i18next'

const storeSelector = (state) => ({
  myContent: contentSelectors.getMyContent(state),
  myContentUuid: contentSelectors.getMyContentUuid(state),
  listMyLocation: contentSelectors.getListMyLocation(state),
  myLocationMenuItems: contentSelectors.getMyLocationMenuItems(state),
})

export const useFetchData = ({ params }) => {
  const [contentItems, setContentItems] = useState(null)
  const [isContentItemSummary, setIsContentItemSummary] = useState(false)
  const { myContent, listMyLocation, myLocationMenuItems } =
    useStoreSelector(storeSelector)
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const _setChildContents = useCallback(
    (routeParams, itemsList) => {
      if (itemsList.listItems.length > 0) {
        if (params.hasOwnProperty('contentUuid')) {
          if (params['contentUuid'].hasOwnProperty('Uuid')) {
            const getContent = itemsList.listItems
              .filter(
                (item) => item.menuItemUuid === routeParams.childItemUuid
              )[0]
              .contents.filter(
                (item) => item.Uuid === routeParams.contentUuid.Uuid
              )
            setContentItems(getContent)
          } else {
            const getContent = itemsList.listItems
              .filter(
                (item) => item.menuItemUuid === routeParams.childItemUuid
              )[0]
              .contents.filter((item) => item.Uuid === routeParams.contentUuid)
            setContentItems(getContent)
          }
          return
        }

        let filteredItems = itemsList.listItems.filter(
          (item) => item.menuItemUuid === routeParams.childItemUuid
        )

        // const getContent = itemsList.listItems.filter(
        //   (item) => item.menuItemUuid === routeParams.childItemUuid~
        // )[0].contents

        setContentItems(
          filteredItems.length > 0
            ? filteredItems[0].contents
            : itemsList.listItems[0].contents
        )
      }
      setIsContentItemSummary(
        myLocationMenuItems.find(
          (item) => item.Uuid === routeParams.childItemUuid
        )?.ShowContentItemSummary || false
      )
    },
    [params]
  )

  const onChangeLanguage = useCallback(() => {
    if (params.hasOwnProperty('singleItemUuid')) {
      dispatch(
        contentActions.fetchContentAsync({
          uuid: params.singleItemUuid,
          screen: 'location',
        })
      )
    }
  }, [dispatch, params])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  useEffect(() => {
    if (params.hasOwnProperty('singleItemUuid')) {
      setContentItems(myContent)
      setIsContentItemSummary(
        myLocationMenuItems.find((item) => item.Uuid === params.singleItemUuid)
          ?.ShowContentItemSummary || false
      )
    } else {
      if (listMyLocation && !myContent) {
        _setChildContents(params, listMyLocation)
      }
    }
  }, [_setChildContents, listMyLocation, myContent, params])

  return {
    contentItems,
    isContentItemSummary,
  }
}
