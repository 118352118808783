import React, { useLayoutEffect } from 'react'
import { createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'

//navigators
import { AuthNavigator } from '../AuthNavigator'

//screens
import { BenefitsProfileScreen } from 'components/src/screens'

//store
import { profileSelectors } from 'components/src/bus/profile'

const Stack = createStackNavigator()

export const BenefitsProfileNavigator = ({ navigation }) => {
  const isAuthenticated = useSelector(profileSelectors.getCurrent)

  useLayoutEffect(() => {
    navigation.setOptions({
      headerShown: false,
    })
  })

  return (
    <Stack.Navigator backBehavior="initialRoute" headerMode="screen">
      {isAuthenticated ? (
        <>
          <Stack.Screen name="Benefits" component={BenefitsProfileScreen} />
        </>
      ) : (
        <Stack.Screen
          name="Authenticated"
          options={{ headerShown: false }}
          component={AuthNavigator}
        />
      )}
    </Stack.Navigator>
  )
}
