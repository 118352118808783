import { contentSelectors } from 'components/src/bus/content'

import { useStoreSelector } from 'components/src/hooks'

const storeSelect = (state) => ({
  homeMenuItems: contentSelectors.getHomeMenuItems(state),
  isLoading: contentSelectors.isLoading(state),
})

export const useGetData = () => {
  const { homeMenuItems, isLoading } = useStoreSelector(storeSelect)
  const filterMenuItems = () => {
    if (!homeMenuItems) {
      return null
    }

    const mainMenuItems = homeMenuItems.filter((item) => !item.ParentUuid)
    return mainMenuItems.map((item) => ({
      ...item,
      isParent:
        homeMenuItems.filter((homeItem) => homeItem.ParentUuid === item.Uuid)
          .length > 0,
    }))
  }
  return {
    homeMenuItems: filterMenuItems(),
    isLoading,
  }
}
