import { StyleSheet, Dimensions } from 'react-native'

import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()

  const { height, width } = Dimensions.get('window')

  return {
    styles: StyleSheet.create({
      container: {
        width: 60,
        height: 25,
        paddingRight: 15,
        borderWidth: 1,
        borderColor: pallete.cartBoxBorder,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      },
      label: {
        ...fontFamilies.cl_bold,
        fontSize: 16,
      },
      selectBoxIcon: {
        position: 'absolute',
        top: '50%',
        marginTop: -8,
        right: 8,
        transform: [{ rotate: '-90deg' }],
        color: pallete.defaultText,
      },
      androidModalBG: {
        backgroundColor: 'rgba(0,0,0,0.4)',
        flex: 1,
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      androidModalContainer: {
        backgroundColor: 'white',
        position: 'absolute',
        justifyContent: 'center',
        alignContent: 'center',
        paddingTop: 20,
        paddingBottom: 20,
        height: 200,
        width: 300,
        left: parseInt(width / 2 - 150),
        top: parseInt(height / 2 - 100),
      },
      notActiveItem: {
        ...fontFamilies.cl_bold,
        fontSize: 18,
        color: pallete.defaultText,
        textAlign: 'center',
        opacity: 0.3,
      },
      activeItem: {
        ...fontFamilies.cl_bold,
        fontSize: 24,
        color: pallete.defaultText,
        textAlign: 'center',
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: 'rgba(0,0,0,0.3)',
      },
      androidList: {
        paddingLeft: 15,
        paddingRight: 15,
      },
      webContainer: {
        width: 60,
        height: 25,
        textAlign: 'center',
      },
    }),
  }
}
