import { useNavigation } from '@react-navigation/native'
import { Platform, Linking } from 'react-native'
import { useDispatch } from 'react-redux'

import { contentActions } from 'components/src/bus/content'

export const useActions = (menuItems) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const _handleSingleItem = (uuid) => {
    dispatch(contentActions.fetchSingleItem({ screen: 'home', uuid }))
  }

  const _nextScreen = (data) => {
    if (!menuItems) {
      return
    }

    if (data.actionType === 'single') {
      _handleSingleItem(data.Uuid)
      return
    }
    const isWebsite = data.MenuItemTypeName === 'Webseite'

    if (data.actionType === 'item-list') {
      navigation.push('Items', { itemUuid: data.Uuid })
    }

    if (data.actionType === 'list') {
      navigation.navigate('List', { uuid: data.Uuid })
      return
    }
    if (isWebsite) {
      Platform.OS === 'web'
        ? window
            .open(
              data.Website.search('http') >= 0
                ? data.Website
                : `https://${data.Website}`,
              '_blank'
            )
            .focus()
        : Linking.openURL(
            data.Website.search('http') >= 0
              ? data.Website
              : `https://${data.Website}`
          )
    }
  }
  return {
    nextScreen: _nextScreen,
  }
}
