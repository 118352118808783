export const auth = {
  Authenticated: {
    path: '/auth',
    screens: {
      SignIn: '/sign-in',
      SignUp: '/sign-up',
      RestorePasswordEmail: '/restore-password',
    },
  },
}
