import { StyleSheet } from 'react-native'

import { useTheme, useColorByScreen } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = (screen) => {
  const { pallete } = useTheme()
  const color = useColorByScreen(screen)
  return {
    styles: StyleSheet.create({
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 300,
        paddingTop: 15,
        paddingLeft: 10,
        paddingBottom: 15,
        paddingRight: 10,
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: pallete.primary,
      },
      title: {
        ...fontFamilies.cl_bold,
        color: pallete.cardText,
        fontSize: 20,
        marginBottom: 8,
      },
      item: {
        ...fontFamilies.cl_regular,
        marginBottom: 4,
        fontSize: 18,
        color: pallete.cardText,
      },
    }),
  }
}
