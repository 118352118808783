import React from 'react'
import { View, unstable_createElement } from 'react-native-web'

const Svg = React.forwardRef((props, ref) => {
  return unstable_createElement('svg', { ref: ref, ...props })
})

const SvgXml = React.forwardRef(({ xml, ...props }, fowardRef) => {
  const { attributes, innerSVG } = parseSVG(xml)
  const camelAttributes = kebabToCamel(attributes)
  const svgRef = React.createRef()
  React.useLayoutEffect(() => {
    if (!svgRef.current) {
      return
    }
    svgRef.current.innerHTML = innerSVG
  }, [innerSVG, svgRef])

  const {
    height,
    width,
    viewBox,
    preserveAspectRatio,
    color,
    title,
    opacity,
    fill,
    fillOpacity,
    fillRule,
    transform,
    stroke,
    strokeWidth,
    strokeOpacity,
    strokeDasharray,
    strokeDashoffset,
    strokeLinecap,
    strokeLinejoin,
    strokeMiterlimit,
    clipRule,
    clipPath,
    vectorEffect,
    pointerEvents,
    id,
    markerStart,
    markerMid,
    markerEnd,
    mask,
    originX,
    originY,
    translate,
    scale,
    rotation,
    skewX,
    skewY,
    style,
    // props that should be applyed to the View container
    ...containerProps
  } = props

  const transformArr = []

  if (originX != null || originY != null) {
    transformArr.push(`translate(${originX || 0}, ${originY || 0})`)
  }
  if (translate != null) {
    transformArr.push(`translate(${translate})`)
  }
  if (scale != null) {
    transformArr.push(`scale(${scale})`)
  }
  // rotation maps to rotate, not to collide with the text rotate attribute (which acts per glyph rather than block)
  if (rotation != null) {
    transformArr.push(`rotate(${rotation})`)
  }
  if (skewX != null) {
    transformArr.push(`skewX(${skewX})`)
  }
  if (skewY != null) {
    transformArr.push(`skewY(${skewY})`)
  }
  if (originX != null || originY != null) {
    transformArr.push(`translate(${-originX || 0}, ${-originY || 0})`)
  }

  // these props should override the xml props
  const overrideProps = {
    transform: transformArr.length ? transformArr.join(' ') : transform,
    viewBox,
    preserveAspectRatio,
    color,
    title,
    opacity,
    fill,
    fillOpacity,
    fillRule,
    stroke,
    strokeWidth,
    strokeOpacity,
    strokeDasharray,
    strokeDashoffset,
    strokeLinecap,
    strokeLinejoin,
    strokeMiterlimit,
    clipRule,
    clipPath,
    vectorEffect,
    pointerEvents,
    id,
    markerStart,
    markerMid,
    markerEnd,
    mask,
    height,
    width,
  }

  const finalProps = {
    ...camelAttributes,
    ...removeUndefined(overrideProps),
    height,
    width,
  }

  const containerDefaultStyles = {
    width,
    height,
  }
  return (
    <View
      ref={fowardRef}
      {...containerProps}
      style={{ ...style, containerDefaultStyles }}
    >
      <Svg ref={svgRef} {...finalProps} />
    </View>
  )
})

SvgXml.displayName = 'Svg'

export default SvgXml

/** polyfill for Node < 12 */
function matchAll(str) {
  return (re) => {
    const matches = []
    let groups
    while ((groups = re.exec(str))) {
      matches.push(groups)
    }
    return matches
  }
}

function parseSVG(svg) {
  const content = svg.match(/<svg(.*)<\/svg>/ims)[1]
  const [, attrs, innerSVG] = content.match(/(.*?)>(.*)/ims)
  const attributes = [
    ...matchAll(attrs)(/([a-z0-9]+)(=['"](.*?)['"])?/gims),
  ].map(([, key, , value]) => ({ [key]: value }))
  return { attributes, innerSVG }
}

function kebabToCamel(attrs) {
  const camelObj = {}
  attrs.forEach((attr) => {
    const key = Object.keys(attr)[0]
    camelObj[key.replace(/-./g, (x) => x.toUpperCase()[1])] = attr[key]
  })
  return camelObj
}

function removeUndefined(obj) {
  const finalObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined) {
      finalObj[key] = obj[key]
    }
  })
  return finalObj
}
