import React from 'react'
import PropTypes from 'prop-types'
import { View, Text } from 'react-native'

import { CustomImage } from 'components/src/components'

import { useStyles } from './styles'

export const ContentHeader = ({ hotelName, title, source, screen }) => {
  const { styles } = useStyles(screen)

  return (
    <View style={styles.container}>
      {hotelName && (
        <View style={styles.hotelBox}>
          <Text style={styles.hotelLabel}>{hotelName}</Text>
        </View>
      )}
      {source ? (
        <CustomImage style={styles.image} source={source} />
      ) : (
        <View style={styles.emptyImage} />
      )}
      <View style={styles.titleBox}>
        <Text style={styles.titleLabel}>{title}</Text>
      </View>
    </View>
  )
}

ContentHeader.defaultValue = {
  screen: 'home',
}

ContentHeader.propTypes = {
  hotelName: PropTypes.string,
  title: PropTypes.string.isRequired,
  source: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])])
    .isRequired,
  screen: PropTypes.oneOf(['location', 'home']),
}
