//Core
import {
  all,
  call,
  take,
  fork,
  cancel,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import {
  createProfile,
  signIn,
  fetchProfile,
  forceLogout,
  autoLogin,
  updateProfile,
  updatePassword,
  resetPassword,
  createBookmarkedSites,
  fetchBookmarkedSites,
  deleteBookmarkedSites,
} from './workers'
import { createRedirect } from './workers/create.workers'

function* watchCreateProfile() {
  yield takeLatest(types.CREATE_PROFILE, createProfile)
}

function* watchSignIn() {
  yield takeLatest(types.SIGN_IN, signIn)
}

function* watchFetchProfile() {
  yield takeLatest(types.FETCH_PROFILE, fetchProfile)
}

function* watchForceLogout() {
  yield takeLatest(types.FORCE_LOGOUT, forceLogout)
}

function* watchAutoLogin() {
  yield takeLatest(types.AUTO_LOGIN, autoLogin)
}

function* watchUpdateProfile() {
  yield takeLatest(types.UPDATE_PROFILE, updateProfile)
}

function* watchUpdatePassword() {
  yield takeLatest(types.UPDATE_PASSWORD, updatePassword)
}

function* watchResetPassword() {
  yield takeLatest(types.RESET_PASSWORD, resetPassword)
}

function* watchFetchBookmarkedSites() {
  yield takeLatest(types.FETCH_BOOKMARKED_SITES, fetchBookmarkedSites)
}

function* watchCreateBookmarkedSites() {
  yield takeLatest(types.CREATE_BOOKMARKED_SITES, createBookmarkedSites)
}

function* watchDeleteBookmarkedSites() {
  yield takeLatest(types.DELETE_BOOKMARKED_SITES, deleteBookmarkedSites)
}

function* watchCreateRedirect() {
  while (true) {
    const action = yield take(types.CREATE_REDIRECT)
    const task = yield fork(createRedirect, action)
    yield take(types.DELETE_REDIRECT)
    yield cancel(task)
  }
}

export function* watchProfile() {
  yield all([
    call(watchCreateProfile),
    call(watchSignIn),
    call(watchFetchProfile),
    call(watchForceLogout),
    call(watchAutoLogin),
    call(watchUpdateProfile),
    call(watchUpdatePassword),
    call(watchResetPassword),
    call(watchFetchBookmarkedSites),
    call(watchCreateBookmarkedSites),
    call(watchDeleteBookmarkedSites),
    call(watchCreateRedirect),
  ])
}
