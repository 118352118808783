import i18n from 'components/src/i18n'
import { LanguageRemember } from 'components/src/services/LanguageRemember'
import { themesActions } from 'components/src/bus/themes'
import { profileActions } from 'components/src/bus/profile'

export const bootstrap = async (store) => {
  store.dispatch(themesActions.fetchThemeAsync())
  store.dispatch(profileActions.autoLoginAsync())
  const language = await LanguageRemember.get() //TODO save language to redux for request API CONTENT WITH CORRECT language
  i18n.changeLanguage(language)
}
