import { StyleSheet } from 'react-native'
// Local
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      container: { flex: 1, backgroundColor: '#ffffff' },
      content: {
        marginTop: 20,
      },
      emptyList: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: 400,
      },
      emptyListLabel: {
        ...fontFamilies.cl_bold,
        fontSize: 18,
        textAlign: 'center',
      },
    }),
  }
}
