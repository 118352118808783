import { StyleSheet } from 'react-native'

import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderColor: pallete.cartBoxBorder,
        paddingBottom: 8,
        marginBottom: 12,
      },
      image: {
        width: 65,
        height: 40,
      },
      titleContainer: {
        flex: 1,
        paddingLeft: 10,
      },
      title: {
        ...fontFamilies.cl_bold,
        color: pallete.defaultText,
        fontSize: 14,
      },
      priceContainer: {
        alignItems: 'center',
        width: 68,
      },
      price: {
        ...fontFamilies.cl_bold,
        color: pallete.defaultText,
        fontSize: 14,
      },
      tax: {
        ...fontFamilies.cl_regular,
        fontSize: 12,
        color: pallete.defaultText,
      },
      buttonContainer: {
        paddingLeft: 10,
        justifyContent: 'center',
        alignItems: 'flex-end',
      },
      button: {
        width: 74,
        height: 25,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: pallete.buttonBackgroundDefault,
      },
      buttonLabel: {
        textTransform: 'uppercase',
        ...fontFamilies.cl_bold,
        fontSize: 12,
        color: pallete.buttonTextDefault,
      },
    }),
  }
}
