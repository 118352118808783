import { useDispatch } from 'react-redux'
import { cartActions } from 'components/src/bus/cart'

export const useActions = (data) => {
  const dispatch = useDispatch()

  const _addToCart = () => {
    dispatch(cartActions.addItemCartMutation(data))
  }

  return {
    addToCart: _addToCart,
  }
}
