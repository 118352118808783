import { StyleSheet } from 'react-native'
import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
        paddingTop: 90,
        alignItems: 'center',
      },
      button: {
        marginTop: 55,
      },
      buttonText: {
        fontSize: 35,
      },
      text: {
        marginTop: 41,
        width: 230,
        fontSize: 40,
        textAlign: 'center',
        color: pallete.actionText,
        ...fontFamilies.cl_regular,
      },
    }),
  }
}
