import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
        backgroundColor: pallete.containerBackground,
      },
      buttonsContainer: {
        alignItems: 'center',
        marginTop: 24,
      },
      button: {
        width: '100%',
        maxWidth: 306,
        marginBottom: 10,
        marginLeft: 15,
        marginRight: 15,
        height: 42,
      },
      wrapper: {
        width: '100%',
        maxWidth: 500,
        paddingLeft: 12,
        paddingRight: 12,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      totalContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderColor: pallete.cartBoxBorder,
        marginTop: 10,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 6,
      },
      priceContainer: {
        flexDirection: 'row',
        alignItems: 'baseline',
      },
      title: {
        ...fontFamilies.cl_bold,
        color: pallete.defaultText,
        fontSize: 22,
      },
      taxPrice: {
        marginLeft: 6,
        ...fontFamilies.cl_regular,
        color: pallete.defaultText,
        fontSize: 18,
      },
      box: {
        marginTop: 15,
      },
      label: {
        ...fontFamilies.cl_bold,
        fontSize: 16,
        color: pallete.defaultText,
        marginBottom: 6,
      },
      textArea: {
        height: 60,
        borderWidth: 1,
        borderColor: pallete.defaultText,
      },
      textRoomArea: {
        height: 40,
        borderWidth: 1,
        borderColor: pallete.defaultText,
      },
      selectStyle: {
        width: '100%',
        height: 25,
        paddingLeft: 4,
        alignItems: 'flex-start',
      },
    }),
    optionsStyles: StyleSheet.create({
      box: {
        marginTop: 5,
        marginBottom: 5,
      },
      label: {
        ...fontFamilies.cl_regular,
        fontSize: 16,
        color: pallete.defaultText,
        marginBottom: 6,
      },
      textArea: {
        height: 60,
        borderWidth: 1,
        borderColor: pallete.defaultText,
      },
    }),
    cartItemStyles: StyleSheet.create({
      container: {
        borderWidth: 1,
        borderColor: pallete.defaultText,
        paddingTop: 4,
        paddingBottom: 10,
        paddingLeft: 4,
        paddingRight: 4,
        marginBottom: 8,
        width: '100%',
      },
      header: {
        flexDirection: 'row',
      },
      titleContainer: {
        flex: 1,
      },
      priceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 16,
        color: pallete.defaultText,
      },
      subTitle: {
        ...fontFamilies.cl_regular,
        fontSize: 14,
        color: pallete.defaultText,
        marginLeft: 8,
      },
      paragraph: {
        ...fontFamilies.os_light,
        fontSize: 16,
        color: pallete.defaultText,
      },
      divider: {
        width: 277,
        height: 1,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: pallete.defaultText,
        opacity: 0.75,
      },
      selectAction: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      selectBox: {
        width: 60,
        height: 25,
        borderWidth: 1,
        borderColor: pallete.defaultText,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 20,
        paddingRight: 18,
      },
      actionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      selectBoxText: {
        fontSize: 16,
        ...fontFamilies.cl_bold,
      },
      selectBoxIcon: {
        position: 'absolute',
        top: 4,
        right: 8,
        transform: [{ rotate: '-90deg' }],
        color: pallete.defaultText,
      },
      trashIcon: {
        color: pallete.primary,
        marginRight: 26,
      },
      titleNumber: {
        marginRight: 26,
      },
      selectIcon: {
        top: 6,
        position: 'absolute',
      },
      containerDescription: {
        paddingTop: 4,
        paddingRight: 4,
        marginBottom: 8,
        width: '100%',
      },
      descriptionTitle: {
        ...fontFamilies.cl_regular,
        fontSize: 16,
        color: pallete.defaultText,
      },
      descriptionTextContainer: {
        marginBottom: 10,
      },
      mandatory: {
        ...fontFamilies.cl_regular,
        fontSize: 16,
        color: pallete.defaultText,
      },
      checkBoxContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 8,
      },
      checkBoxImage: {
        position: 'relative',
        width: 20,
        height: 20,
        tintColor: pallete.primary,
        marginRight: 10,
      },
    }),

    pallete,
  }
}
