import { createSelector } from 'reselect'

export const getSalutations = (state) => state.settings.salutations
export const getCountries = (state) => state.settings.countries
export const isLoading = (state) => state.settings.loading
export const getSites = (state) => state.settings.sites
export const getSelectedSite = (state) => state.settings.selectedSite
export const getMemberSites = (state) => state.settings.memberSites
export const getCurrentCountries = (state) => state.settings.getCurrentCountries

const getBookmarkedSites = (state) => state.entities.profile.bookmarkedSites // for local

export const getMemberSitesWithBookmark = createSelector(
  [getMemberSites, getBookmarkedSites],
  (members, bookmarkeds) =>
    members.map((member) =>
      bookmarkeds &&
      bookmarkeds.find((bookmarked) => bookmarked.Uuid === member.Uuid)
        ? { ...member, IsFavorite: true }
        : { ...member, IsFavorite: false }
    )
)
