import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { View, ScrollView } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'

import { HotelCard, HotelAutocomplete } from 'components/src/components'
import { profileActions, profileSelectors } from 'components/src/bus/profile'

export const HotelList = ({ data, countryCode }) => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(profileSelectors.getCurrent)
  const [hotels, setHotels] = useState(data)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const navigation = useNavigation()
  const route = useRoute()

  useEffect(() => {
    selectedCountry
      ? setHotels(data.filter((item) => item.CountryCode === selectedCountry))
      : setHotels(data)
  }, [data, selectedCountry])

  useEffect(() => {
    if (route.params.hasOwnProperty('countryCode')) {
      setSelectedCountry(route.params.countryCode)
    }
  }, [route.params])

  useEffect(() => {
    if (!route.params.hasOwnProperty('countryCode')) {
      setSelectedCountry(countryCode)
    }
  }, [countryCode, route.params])

  const handleFavorite = useCallback(
    (id, isFavorite) => {
      if (isAuthenticated) {
        if (isFavorite) {
          dispatch(profileActions.deleteBookmarkedSitesAsync(id))
        } else {
          dispatch(profileActions.createBookmarkedSitesAsync(id))
        }
      } else {
        navigation.navigate('Profile', {
          screen: 'Authenticated',
          params: {
            redirect: route,
          },
        })
      }
    },
    [dispatch, isAuthenticated, navigation, route]
  )

  return (
    <>
      <HotelAutocomplete
        defaultValue={selectedCountry}
        onChange={(country) => {
          navigation.push('HotelContent', {
            ...route.params,
            countryCode: !country ? null : country.Code,
          })
          setSelectedCountry(!country ? null : country.Code)
        }}
        data={data}
      />
      <ScrollView
        style={{
          marginTop: 20,
        }}
      >
        <View>
          {hotels.map((content) => (
            <HotelCard
              key={content.Uuid}
              content={content}
              nextScreen={() =>
                navigation.push('HotelContent', {
                  ...route.params,
                  siteUuid: content.Uuid,
                  countryCode: selectedCountry,
                })
              }
              handleFavorite={() =>
                handleFavorite(content.Uuid, content.IsFavorite)
              }
            />
          ))}
        </View>
      </ScrollView>
    </>
  )
}

HotelList.propTypes = {
  data: PropTypes.array.isRequired,
}
