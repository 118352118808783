import { StyleSheet, Dimensions } from 'react-native'

import { useTheme, useColorByScreen } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = (screen) => {
  const { pallete } = useTheme()
  const { width } = Dimensions.get('screen')
  const maxWidthImage = () => {
    if (width <= 480) {
      return width
    }
    return 480
  }
  const color = useColorByScreen(screen)
  return {
    styles: StyleSheet.create({
      container: {
        position: 'relative',
        marginTop: 4,
        marginBottom: 30,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
      },
      image: {
        maxWidth: maxWidthImage() - 36,
        height: maxWidthImage() / 1.7,
        marginLeft: 20,
        // resizeMode: 'contain',
      },
      hotelBox: {
        position: 'absolute',
        backgroundColor: pallete.containerBackground,
        paddingTop: 5,
        paddingBottom: 5,
        zIndex: 100,
        right: 0,
      },
      hotelLabel: {
        ...fontFamilies.cl_bold,
        fontSize: 21,
        color: pallete.defaultText,
      },
      titleBox: {
        position: 'absolute',
        bottom: -8,
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 8,
        paddingBottom: 8,
        backgroundColor: pallete.primary,
        opacity: 0.92,
      },
      titleLabel: {
        color: pallete.cardText,
        fontSize: 22,
        ...fontFamilies.cl_bold,
      },
      emptyImage: {
        height: 100,
      },
    }),
  }
}
