import { useDispatch } from 'react-redux'
import { contentActions, contentSelectors } from 'components/src/bus/content'
import { useNavigation } from '@react-navigation/native'

import { useStoreSelector } from 'components/src/hooks'

const storeSelector = (state) => ({
  loading: contentSelectors.isLoading(state),
  contentItemUuid: contentSelectors.getMyContentUuid(state),
  myContent: contentSelectors.getMyContent(state),
})

export const useChildrenSettings = (menuItems) => {
  const { loading, contentItemUuid, myContent } = useStoreSelector(storeSelector)
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const checkItem = (item, arr) => {

    const isParent =
      menuItems.filter((menuItem) => menuItem.ParentUuid === item.Uuid).length >
      0 || myContent?.filter((c) => c.ParentUuid === item.Uuid).length >
      0

    if (item.MenuItemTypeId === 5) {
      arr.push({ ...item, actionType: 'link' })
    }
    if (
      (item.MenuItemTypeId === 6 && isParent) ||
      (!item.MenuItemTypeId && isParent)
    ) {
      arr.push({ ...item, actionType: 'list' })
    }
    if (item.MenuItemTypeId === 6 && !isParent) {
      arr.push({ ...item, actionType: 'single' })
    }
  }

  const structureItems = (items) => {
    const customArr = []
    items.map((item) => checkItem(item, customArr))
    return customArr
  }

  const _handleSingleItem = (uuid) => {
    if (contentItemUuid !== uuid) {
      dispatch(contentActions.fetchSingleItem({ uuid, screen: 'location' }))
    } else {
      navigation.navigate('Content', { singleItemUuid: uuid })
    }
  }

  return {
    loading,
    structureItems,
    handleSingleItem: _handleSingleItem,
  }
}
