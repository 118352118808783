import { Linking, Platform } from 'react-native'
import { all, call, put, select } from 'redux-saga/effects'

// Local
// Local Dir
import { apiContent } from '../../api'
import { contentActions } from 'components/src/bus/content'
import { settingsSelectors } from 'components/src/bus/settings'
import { uiActions } from 'components/src/bus/ui'

export function* fetchHomeMenuItems(action) {
  try {
    yield put(contentActions.toggleLoading(true))

    // Api call or some async actions
    const response = yield call(apiContent.fetchMenuItems, {
      params: action.payload,
    })

    if (response.data) {
      yield put(contentActions.saveHomeMenuItems(response.data.Items))
    }
  } catch (error) {
    yield put(uiActions.emitError(error, 'Content fetchHomeMenuItems workers'))
  } finally {
    yield put(contentActions.toggleLoading(false))
  }
}

export function* fetchMyLocationMenuItems(action) {
  try {
    yield put(contentActions.toggleLoading(true))
    const response = yield call(apiContent.fetchMenuItems, {
      ...action.payload,
    })

    if (response.data) {
      yield put(contentActions.saveMyLocationMenuItems(response.data.Items))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error, 'Content fetchMyLocationMenuItems workers')
    )
  } finally {
    yield put(contentActions.toggleLoading(false))
  }
}

export function* fetchGeneralContent(payload) {
  try {
    const response = yield call(apiContent.fetchContent, {
      params: { menuItemUuid: payload },
    })
    if (response.data.Items) {
      return response.data.Items
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchSiteContent(uuid) {
  try {
    const siteSelected = yield select(settingsSelectors.getSelectedSite)
    const response = yield call(apiContent.fetchContent, {
      params: { menuItemUuid: uuid, maxRows: 1000 },
      headers: { 'X-Context-SiteUuid': siteSelected },
    })
    if (response.data.Items) {
      return response.data.Items
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchContent(action) {
  try {
    yield put(contentActions.toggleLoading(true))
    let contents
    if (action.payload.screen === 'home') {
      contents = yield call(fetchGeneralContent, action.payload.uuid)
      if (contents) {
        yield put(contentActions.saveContent(contents))
        yield put(contentActions.saveContentUuid(action.payload.uuid))
      }
    }
    if (action.payload.screen === 'location') {
      contents = yield call(fetchSiteContent, action.payload.uuid)
      if (contents) {
        yield put(
          contentActions.saveMyModContent({
            contents,
            uuid: action.payload.uuid,
          })
        )
        yield put(contentActions.saveMyContentUuid(action.payload.uuid))
      }
    }
  } catch (error) {
    console.log(error)
    yield put(uiActions.emitError(error, 'Content fetchContent workers'))
  } finally {
    yield put(contentActions.toggleLoading(false))
  }
}

export function* fetchWebsite(payload) {
  try {
    const response = yield call(apiContent.fetchWebSite, payload)
    if (response.data.Item) {
      const website = response.data.Item.Website
      Platform.OS === 'web'
        ? window.open(
            website.search('http') >= 0 ? website : `https://${website}`,
            '_blank'
          )
        : Linking.openURL(
            website.search('http') >= 0 ? website : `https://${website}`
          )
    }
  } catch (error) {
    uiActions.emitError(error, 'Content fetchSecondLevelContent workers')
  }
}

export function* fetchContentListItem(payload) {
  try {
    if (payload.screen === 'home') {
      const contents = yield call(fetchGeneralContent, payload.uuid)
      return {
        menuItemUuid: payload.uuid,
        contents,
      }
    }
    if (payload.screen === 'location') {
      const contents = yield call(fetchSiteContent, payload.uuid)
      return {
        menuItemUuid: payload.uuid,
        contents,
      }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchOpeningHours(payload) {
  try {
    const response = yield call(apiContent.fetchOpeningHours, payload)
    if (response.data) {
      return { openingHours: response.data }
    } else {
      return { openingHours: null }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchContentImages(payload) {
  try {
    const response = yield call(apiContent.fetchContentItemsDocuments, {
      contentItemUuid: payload,
      DocumentTypeId: 1,
    })
    if (response.data.Items) {
      return { contentImages: response.data.Items }
    } else {
      return { contentImages: null }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchContentPdf(payload) {
  try {
    const response = yield call(apiContent.fetchContentItemsDocuments, {
      contentItemUuid: payload,
      DocumentTypeId: 2,
    })
    if (response.data.Items && response.data.Count > 0) {
      const result = yield all(
        response.data.Items.map((item) => call(fetchContentPdfData, item.Uuid))
      )
      return { contentPdf: result }
    } else {
      return { contentPdf: null }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchContentPdfData(payload) {
  try {
    const response = yield call(apiContent.fetchContentPdf, payload)
    if (response.data.Item) {
      return response.data.Item
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchContentWebsite(payload) {
  try {
    const response = yield call(apiContent.fetchWebSite, payload)
    if (response.data.Item) {
      return { contentWebsite: response.data.Item }
    } else {
      return { contentWebsite: null }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchContentProducts(payload) {
  try {
    const response = yield call(apiContent.fetchProducts, payload)
    if (response.data.Items && response.data.Count !== 0) {
      return { products: response.data.Items }
    } else {
      return { products: null }
    }
  } catch (error) {
    console.log(error)
  }
}

export function* fetchOpenLink(action) {
  try {
    yield put(contentActions.toggleLoading(true))
    yield call(fetchWebsite, action.payload)
  } catch (error) {
    yield put(uiActions.emitError(error, 'Content fetchOpenLink workers'))
  } finally {
    yield put(contentActions.toggleLoading(false))
  }
}
