const RUBY = '#E5235F'
const RUBY_TRANSPARENT = 'rgba(229, 35, 95, 0.8)'
const CYAN = '#95c6c0'
const TRADEWIND = '#6bbbae'

const GREY = '#c7c7c7'
const TINT_GREY = '#e5e5e5'
const LIGHT_GREY = '#bcbcbc'
const DARK_GRAY = '#575756'

const BLACK = '#000000'
const WHITE = '#FFFFFF'

export default {
  primary: RUBY,
  secondary: TRADEWIND,
  //text
  defaultText: DARK_GRAY,
  defaultBackground: WHITE,
  actionText: RUBY,
  actionBorder: RUBY,
  textBlack: BLACK,
  locationText: TRADEWIND,
  //button
  buttonBackgroundDefault: RUBY,
  buttonBackgroundOutline: 'transparent',
  buttonTextDefault: WHITE,
  buttonTextOutline: RUBY,
  buttonBorderDefault: RUBY,
  buttonBorderOutline: RUBY,
  //button change language
  buttonChangeLangModal: WHITE,
  buttonChangeLangModalOverlay: 'rgba(0,0,0,0.5)',
  buttonChangeLangModalItemBorder: TINT_GREY,
  buttonChangeLangModalItemText: DARK_GRAY,
  buttonChangeLangModalItemTextActive: RUBY,
  buttonChangeLangModalShadow: BLACK,
  //icon button
  iconButtonIcon: RUBY,
  iconButtonText: DARK_GRAY,
  //TextInput
  textInputBorder: RUBY,
  TextInputBackground: 'transparent',
  textInputLabel: RUBY,
  textInputColor: DARK_GRAY,
  textInputErrorColor: RUBY,
  //preview profile
  previewProfileIcon: RUBY,
  previewProfileText: '#6D7278',
  //title card
  titleCardBackground: RUBY,
  titleCardText: WHITE,
  //benefits card
  benefitsCardBackground: RUBY,
  benefitsCardColor: WHITE,
  benefitsCardButtonBackground: WHITE,
  benefitsCardButtonColor: DARK_GRAY,
  //card
  cardBackground: RUBY,
  cardBackgroundTransparent: RUBY_TRANSPARENT,
  cardText: WHITE,
  cardIcon: WHITE,
  cardLocationBackground: TRADEWIND,
  //container
  containerBackground: WHITE,
  //cart
  cartBoxBorder: 'rgba(0,0,0,0.3)',
  divider: 'rgba(0,0,0,0.3)',
  cartModalBackground: 'rgba(255,255,255,0.9)',
  //autocomplete
  autocompleteListBorder: 'rgba(0,0,0,0.7)',
  autocompleteListBackground: 'rgba(255,255,255,1)',
  //image slider
  dot: DARK_GRAY,
  //alert
  alertBorder: RUBY,
  alertText: RUBY,
  alertMessageBorder: RUBY,
  alertMessageText: DARK_GRAY,
}
