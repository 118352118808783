import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View, Text } from 'react-native'

//local
import { Icon, IconNames } from 'components/src/components'
import { useStyles } from './styles'

export const ButtonIcon = ({ label, style, icon, onPress }) => {
  const { styles, iconButtonIconColor } = useStyles()

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <Icon color={iconButtonIconColor} size={30} name={icon} />
      <View style={styles.labelContainer}>
        <Text style={styles.label}>{label}</Text>
      </View>
    </TouchableOpacity>
  )
}

ButtonIcon.propType = {
  icon: PropTypes.oneOf([IconNames]).isRequired,
  onPress: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
}
