import { StyleSheet } from 'react-native'
//Local
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      container: { flex: 1, backgroundColor: '#ffffff' },
      wrapper: { flex: 1 },
      emptyContainer: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
      },
      emptyTitle: {
        ...fontFamilies.cl_regular,
        fontSize: 20,
      },
    }),
  }
}
