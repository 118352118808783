export default {
  logo: [
    {
      path:
        'M462.9,149.8 462.9,257.9 531.9,257.9 531.9,228.4 495.1,228.4 495.1,217.1 524.2,217.1 524.2,191.2   495.1,191.2 495.1,179.8 531.9,179.8 531.9,149.8',
    },
    {
      path:
        'M382.9,149.8 382.9,257.9 452,257.9 452,228.4 415.2,228.4 415.2,217.1 444.2,217.1 444.2,191.2   415.2,191.2 415.2,179.8 452,179.8 452,149.8 ',
    },
    {
      path:
        'M462.9,149.8 462.9,257.9 531.9,257.9 531.9,228.4 495.1,228.4 495.1,217.1 524.2,217.1 524.2,191.2   495.1,191.2 495.1,179.8 531.9,179.8 531.9,149.8',
    },
    {
      path:
        'M331.6,147.6h-6.4c-28.6,0-41.3,12.3-41.3,39.1v34.5c0,26.8,12.7,39.1,41.3,39.1h6.4  c28.6,0,41.3-12.3,41.3-39.1v-34.5C372.9,160.3,360.2,147.6,331.6,147.6z M337.9,220.2c0,7.7-2.7,10-9.5,10c-6.8,0-9.1-2.3-9.1-10  v-33.2c0-7.7,2.3-10,9.5-10c6.4,0,8.6,2.3,8.6,10v33.2H337.9z',
    },
    {
      path:
        'M233.5,147.6h-6.4c-28.6,0-41.3,12.3-41.3,39.1v34.5c0,26.8,12.7,39.1,41.3,39.1h6.4  c28.6,0,41.3-12.3,41.3-39.1v-34.5C274.8,160.3,262.1,147.6,233.5,147.6z M239.8,220.2c0,7.7-2.7,10-9.5,10s-9.1-2.3-9.1-10v-33.2  c0-7.7,2.3-10,9.5-10c6.4,0,8.6,2.3,8.6,10v33.2H239.8z',
    },
    {
      path:
        'M149.4,149.8c-28.6,0-41.3,12.3-41.3,39.1v30c0,26.8,12.7,39.1,41.3,39.1h26.3v-30H154c-6.8,0-10-3.2-10-10.4  v-26.8c0-7.7,3.2-10.9,10-10.9h21.8v-30H149.4z',
    },
  ],
  transcription: [
    {
      path:
        'M516.9,271.6 516.9,279.7 523.3,279.7 523.3,322 516.9,322 516.9,330.2 531.9,330.2 531.9,271.6',
    },
    {
      path:
        'M509.2,292.5c-3.2,0-5.9,2.7-5.9,5.9s2.7,5.9,5.9,5.9s5.9-2.7,5.9-5.9C515.1,294.7,512.8,292.5,509.2,292.5',
    },
    {
      path:
        'M509.2,308.8c-3.2,0-5.9,2.7-5.9,5.9c0,3.2,2.7,5.9,5.9,5.9s5.9-2.7,5.9-5.9  C515.1,311.5,512.8,308.8,509.2,308.8',
    },
    {
      path: 'M479.7,282.5 481,298.4 486.5,298.4 487.8,282.5',
    },
    {
      path:
        'M471.5,292.5c-3.2,0-5.9,2.7-5.9,5.9s2.7,5.9,5.9,5.9c3.2,0,5.9-2.7,5.9-5.9  C476.9,294.7,474.7,292.5,471.5,292.5',
    },
    {
      path:
        'M471.5,308.8c-3.2,0-5.9,2.7-5.9,5.9c0,3.2,2.7,5.9,5.9,5.9c3.2,0,5.9-2.7,5.9-5.9  C476.9,311.5,474.7,308.8,471.5,308.8',
    },
    {
      path:
        'M435.1,294.3v15c0,8.2,5.9,10.9,13.2,10.9c7.7,0,13.2-2.7,13.2-10.9v-15h-9.1v13.6c0,3.2-0.9,5.5-4.5,5.5  s-4.5-2.3-4.5-5.5v-13.6H435.1z',
    },
    {
      path:
        'M410.6,282.5 401.5,282.5 401.5,319.3 410.6,319.3 410.6,309.3 420.2,319.3 432.9,319.3 419.2,305.6   432,294.3 419.7,294.3 410.6,303.4',
    },
    {
      path:
        'M382.9,271.6 382.9,330.2 397.9,330.2 397.9,322 392,322 392,279.7 397.9,279.7 397.9,271.6',
    },
  ],
}
