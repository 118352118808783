import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Animated,
  View,
  Text,
  TextInput as NativeTextInput,
} from 'react-native'

//local
import { useStyles } from './styles'

const BLUR_ANIMATION_DURATION = 180
const FOCUS_ANIMATION_DURATION = 150

export const TextInput = ({
  label,
  style,
  value,
  onChange,
  onFocus,
  onBlur,
  error,
  ...props
}) => {
  const { styles, selectionColor } = useStyles()

  const [labelSize, setLabelSize] = useState({ width: 0, height: 0 })

  const isLabelUp = value

  const labeled = useRef(new Animated.Value(isLabelUp ? 1 : 0)).current

  const handlerFocus = () => {
    Animated.timing(labeled, {
      toValue: 1,
      duration: FOCUS_ANIMATION_DURATION,
      useNativeDriver: true,
    }).start()
    onFocus()
  }

  const handlerBlur = () => {
    if (!value) {
      Animated.timing(labeled, {
        toValue: 0,
        duration: BLUR_ANIMATION_DURATION,
        useNativeDriver: true,
      }).start()
    }

    onBlur()
  }

  const handlerLayoutLabel = ({
    nativeEvent: {
      layout: { width, height },
    },
  }) => {
    setLabelSize({ width, height })
  }

  const labelTransformY = labeled.interpolate({
    inputRange: [0, 1],
    outputRange: [38 - labelSize.height, -labelSize.height / 2],
  })

  const affixLeftTranslateX = labeled.interpolate({
    inputRange: [0, 1],
    outputRange: [0, -(labelSize.width / 2 - 3)],
  })

  const affixRightTranslateX = labeled.interpolate({
    inputRange: [0, 1],
    outputRange: [0, labelSize.width / 2 + 6],
  })

  return (
    <>
      <View style={[styles.wrapper, style]}>
        <View
          style={[
            styles.affixContainer,
            { width: labelSize.width / 2, left: 0 },
          ]}
        >
          <Animated.View
            style={[
              styles.affix,
              {
                transform: [{ translateX: affixLeftTranslateX }],
              },
            ]}
          />
        </View>
        <View
          style={[
            styles.affixContainer,
            { right: 0, left: labelSize.width / 2 },
          ]}
        >
          <Animated.View
            style={[
              styles.affix,
              {
                transform: [{ translateX: affixRightTranslateX }],
              },
            ]}
          />
        </View>

        <Animated.Text
          onLayout={handlerLayoutLabel}
          style={[
            styles.label,
            { transform: [{ translateY: labelTransformY }] },
          ]}
        >
          {label}
        </Animated.Text>
        <NativeTextInput
          {...props}
          style={styles.input}
          selectionColor={selectionColor}
          value={value}
          onChangeText={onChange}
          onFocus={handlerFocus}
          onBlur={handlerBlur}
        />
      </View>
      {error && (
        <View style={styles.error}>
          <Text style={styles.errorText}>{error}</Text>
        </View>
      )}
    </>
  )
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

TextInput.defaultProps = {
  error: null,
  onBlur: () => {},
  onFocus: () => {},
}
