import { StyleSheet } from 'react-native'
// Local
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      content: {
        flexGrow: 1,
        flexShrink: 0,
      },
      wrapper: {
        alignItems: 'center',
        flex: 1,
      },
      container: {
        flex: 1,
        width: 300,
        marginTop: 55,
        justifyContent: 'space-between',
      },
      preview: {
        marginBottom: 30,
      },
      form: {
        flexGrow: 1,
        flexShrink: 0,
        justifyContent: 'flex-start',
      },
      input: {
        marginTop: 25,
      },
      button: {
        height: 42,
        justifyContent: 'center',
        marginTop: 25,
      },
      controlContainer: {
        flexGrow: 0,
        flexShrink: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 15,
      },
      textError: {
        ...fontFamilies.cl_bold,
        marginTop: 2,
        fontSize: 12,
        color: 'rgb(229, 35, 95)',
      },
    }),
    buttonProps: {
      width: 300,
      height: 42,
    },
  }
}
