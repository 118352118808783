import { createRef } from 'react'
import { StackActions } from '@react-navigation/routers'

export const navigationRef = createRef()

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params)
}

export function goBack() {
  navigationRef.current?.goBack()
}

export function pushNavigate(name, params) {
  navigationRef.current?.dispatch(StackActions.push(name, params))
}

export function routeName() {
  const state = navigationRef.current.getRootState()
  const index = state.index
  const routes = state.routes
  return routes[index].name
}
