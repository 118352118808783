import { contentActions, contentSelectors } from 'components/src/bus/content'
import { useCallback, useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { useStoreSelector } from 'components/src/hooks'
import { useTranslation } from 'react-i18next'

const storeSelector = (state) => ({
  myContent: contentSelectors.getContent(state),
  listMyLocation: contentSelectors.getListHome(state),
})

export const useFetchData = ({ params }) => {
  const [contentItems, setContentItems] = useState(null)
  const { myContent, listMyLocation } = useStoreSelector(storeSelector)
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const _setChildContents = useCallback(
    (routeParams, itemsList) => {
      if (itemsList.listItems.length > 0) {
        if (params.hasOwnProperty('contentUuid')) {
          const getContent = itemsList.listItems
            .filter(
              (item) => item.menuItemUuid === routeParams.childItemUuid
            )[0]
            .contents.filter((item) => item.Uuid === routeParams.contentUuid)
          setContentItems(getContent)
          return
        }
        const getContent = itemsList.listItems.filter(
          (item) => item.menuItemUuid === routeParams.childItemUuid
        )[0].contents
        setContentItems(getContent)
      }
    },
    [params]
  )

  const onChangeLanguage = useCallback(() => {
    if (params.hasOwnProperty('singleItemUuid')) {
      dispatch(
        contentActions.fetchContentAsync({
          uuid: params.singleItemUuid,
          screen: 'home',
        })
      )
    }
  }, [dispatch, params])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  useEffect(() => {
    if (params.hasOwnProperty('singleItemUuid')) {
      setContentItems(myContent)
    } else {
      _setChildContents(params, listMyLocation)
    }
  }, [_setChildContents, listMyLocation, myContent, params])

  return {
    contentItems,
  }
}
