import React, { useState, useLayoutEffect, useEffect } from 'react'
import { ScrollView, View, Text } from 'react-native'

import {
  HeaderNavigator,
  ListContentCard,
  Logo,
  Preloader,
} from 'components/src/components'

import { useFetchData } from './hooks'
import { useStyles } from './styles'

export const ListHomeScreen = ({ navigation, route }) => {
  const [loading, setLoading] = useState(true)
  const { styles } = useStyles()
  const { isLoading, parentUuid, parentTitle, listContents } = useFetchData(
    route.params.uuid
  )

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addBackButton {...props} />,
      title: 'Home COOEE',
    })
  }, [navigation])

  useEffect(() => {
    if (loading !== isLoading) {
      setLoading(isLoading)
    }
  }, [isLoading, loading])

  const _goNext = (props) => {
    navigation.navigate('Content', props)
  }
  return (
    <ScrollView style={styles.wrapper}>
      <View style={styles.container}>
        <Logo style={styles.logo} height={100} />
        <View style={styles.titleWrapper}>
          <Text style={styles.title}>{parentTitle}</Text>
        </View>
        {isLoading ? (
          <Preloader />
        ) : (
          listContents && (
            <>
              {listContents.listItems.map(
                (item) =>
                  item.contents.length > 0 && (
                    <ListContentCard
                      key={item.menuItemUuid}
                      screen="home"
                      data={item}
                      onPress={() =>
                        _goNext({
                          parentItemUuid: parentUuid,
                          childItemUuid: item.menuItemUuid,
                        })
                      }
                    />
                  )
              )}
            </>
          )
        )}
      </View>
    </ScrollView>
  )
}
