import { StyleSheet } from 'react-native'
import { useTheme } from 'components/src/hooks'
import { toCapitalize } from 'components/src/helpers'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = ({ width, height, mode }) => {
  mode = toCapitalize(mode)

  const { pallete } = useTheme()

  return {
    styles: StyleSheet.create({
      wrapper: {
        backgroundColor: pallete[`buttonBackground${mode}`],
        borderColor: pallete[`buttonBorder${mode}`],
        borderWidth: 1,
        borderRadius: 0,
        width,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
      },
      text: {
        fontSize: 20,
        color: pallete[`buttonText${mode}`],
        ...fontFamilies.cl_bold,
        textTransform: 'uppercase',
      },
      loader: {
        marginRight: 10,
      },
    }),
    loaderColor: pallete[`buttonText${mode}`],
  }
}
