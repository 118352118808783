import { useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { contentSelectors, contentActions } from 'components/src/bus/content'
import { useTranslation } from 'react-i18next'

import { useStoreSelector } from 'components/src/hooks'

const contentStoreSelector = (state) => ({
  isLoading: contentSelectors.isLoading(state),
  menuItems: contentSelectors.getHomeMenuItems(state),
  listContents: contentSelectors.getListHome(state),
})

export const useFetchData = (uuid) => {
  const [params, setParams] = useState(null)
  const dispatch = useDispatch()
  const { menuItems, isLoading, listContents } = useStoreSelector(
    contentStoreSelector
  )
  const { i18n } = useTranslation()
  const parentUuid = menuItems.filter((item) => item.Uuid === uuid)[0].Uuid
  const parentTitle = menuItems.filter((item) => item.Uuid === uuid)[0]
    .DisplayName

  useEffect(() => {
    if (menuItems) {
      if (!params || uuid !== params.parentUuid) {
        setParams({
          screen: 'home',
          parentUuid: uuid,
          menuItems: menuItems
            .filter((item) => item.ParentUuid === uuid)
            .map((item) => item.Uuid),
        })
      }
    }
  }, [menuItems, params, uuid])

  const onChangeLanguage = useCallback(() => {
    if (params) {
      dispatch(contentActions.fetchContentListAsync(params))
    }
  }, [dispatch, params])

  useEffect(() => {
    if (menuItems && !listContents && params) {
      dispatch(contentActions.fetchContentListAsync(params))
    }
  }, [menuItems, dispatch, listContents, params])

  useEffect(() => {
    if (listContents && listContents.parentItemUuid !== uuid && params) {
      dispatch(contentActions.fetchContentListAsync(params))
    }
  }, [dispatch, listContents, params, uuid])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  return {
    isLoading,
    parentUuid,
    parentTitle,
    listContents,
  }
}
