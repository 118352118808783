import { StyleSheet } from 'react-native'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
      },
      safeArea: {
        flex: 1,
        backgroundColor: pallete.defaultBackground,
      },
    }),
  }
}
