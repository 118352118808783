import { StyleSheet } from 'react-native'

export const useStyles = () => {
  return {
    styles: StyleSheet.create({
      container: { flex: 1, backgroundColor: '#ffffff' },
      wrapper: { flex: 1 },
    }),
  }
}
