//components

import {
  Button,
  ButtonChangeLanguage,
  ButtonIcon,
  HeaderNavigator,
  PasswordInput,
  ProfilePreview,
  TextInput,
} from 'components/src/components'
import { Controller, useForm } from 'react-hook-form'
import React, { useLayoutEffect } from 'react'
import { Text, View } from 'react-native'
import { profileActions, profileSelectors } from 'components/src/bus/profile'
import { useDispatch, useSelector } from 'react-redux'

import { BaseLayout } from 'components/src/layouts'
import { resolver } from './resolver'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

//store

//local

export const SignInScreen = ({ navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addLogo addBackButton {...props} />,
    })
  })
  const dispatch = useDispatch()
  const isLoading = useSelector(profileSelectors.isLoading)
  const isWrongData = useSelector(profileSelectors.isWrongData)

  const { styles, buttonProps } = useStyles()
  const { t } = useTranslation()

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      Username: '',
      Password: '',
    },
    resolver,
  })

  const onSubmit = handleSubmit((data) => {
    dispatch(profileActions.signInAsync(data))
  })

  const onFocus = () => {
    if (isWrongData) {
      dispatch(profileActions.toggleIsWrongData(false))
    }
  }

  return (
    <BaseLayout contentContainerStyle={styles.content}>
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <View style={styles.form}>
            <ProfilePreview style={styles.preview} />
            <Controller
              control={control}
              name="Username"
              render={({ onChange, value }) => (
                <TextInput
                  onFocus={onFocus}
                  value={value}
                  onChange={onChange}
                  style={styles.input}
                  autoCapitalize="none"
                  label={t('form.label.username')}
                  error={errors?.Username && t(errors?.Username?.message)}
                />
              )}
            />
            <Controller
              control={control}
              name="Password"
              render={({ onChange, value }) => (
                <PasswordInput
                  onFocus={onFocus}
                  value={value}
                  onChange={onChange}
                  style={styles.input}
                  label={t('form.label.password')}
                  error={errors?.Password && t(errors?.Password?.message)}
                />
              )}
            />
            {isWrongData && (
              <Text style={styles.textError}>
                {t('form.error.email_or_password_wrong')}
              </Text>
            )}
            <Button
              style={styles.button}
              {...buttonProps}
              loading={isLoading}
              onPress={onSubmit}
            >
              {t('buttons.login')}
            </Button>
            <Button
              style={styles.button}
              {...buttonProps}
              onPress={() => navigation.navigate('SignUp')}
              mode="outline"
            >
              {t('buttons.register')}
            </Button>
          </View>
          <View style={styles.controlContainer}>
            <ButtonChangeLanguage />
            <ButtonIcon
              icon="question"
              label={t('buttons.forgot_password')}
              onPress={() => navigation.navigate('RestorePassword')}
            />
          </View>
        </View>
      </View>
    </BaseLayout>
  )
}
