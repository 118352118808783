import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createStackNavigator } from '@react-navigation/stack'

//screens
import {
  SignInScreen,
  RestorePassword,
  SignUpScreen,
} from 'components/src/screens'
import { profileActions } from '../../bus/profile'

const AuthStack = createStackNavigator()

export const AuthNavigator = ({ route, navigation }) => {
  const { params } = route

  const dispatch = useDispatch()

  useEffect(() => {
    if (params && params.redirect) {
      dispatch(
        profileActions.createRedirect({ ...params.redirect, navigation })
      )
    }
  }, [dispatch, navigation, params])

  return (
    <AuthStack.Navigator initialRouteName="SignIn">
      <AuthStack.Screen name="SignIn" component={SignInScreen} />
      <AuthStack.Screen name="SignUp" component={SignUpScreen} />
      <AuthStack.Screen name="RestorePassword" component={RestorePassword} />
    </AuthStack.Navigator>
  )
}
