import httpAdapter from 'axios/lib/adapters/http'
import settle from 'axios/lib/core/settle'

import cacheStore from './cacheStore'

const whiteList = [
  'Salutations',
  'Countries',
]

const isUrlInWhiteList = (url) => {
  return whiteList.includes(url.split('/')[1])
}

const keyGenerate = (config) => {
  if (config.hasOwnProperty('params')) {
    if (config.headers.hasOwnProperty('X-Context-SiteUuid')) {
      return `${config.url},${JSON.stringify(config.params)},${
        config.headers['Accept-Language']
      },${config.headers['X-Context-SiteUuid']}`
    }
    return `${config.url},${JSON.stringify(config.params)},${
      config.headers['Accept-Language']
    }`
  }
  return `${config.url},${config.headers['Accept-Language']}`
}

const store = async (key, value) => {
  const finalValue = `${value}${SEPARATOR}${Date.now().toString()}`
  await cacheStore.setItem(key, finalValue)
}

const isValid = async (key) => {
  const value = await cacheStore.getItem(key)
  if (value === null) {
    return { isValid: false }
  }
  const values = value.split(SEPARATOR)
  const timestamp = Number(values[1])
  if (Number.isNaN(timestamp)) {
    return {
      isValid: false,
    }
  }
  const date = new Date(timestamp)
  if (date.toString() === 'Invalid Date') {
    return {
      isValid: false,
    }
  }
  if (Date.now() - date.getTime() < CACHE_INTERVAL) {
    return {
      isValid: true,
      value: values[0],
    }
  }
  await cacheStore.removeItem(key)
  return {
    isValid: false,
  }
}

const SEPARATOR = '//**//'
const CACHE_INTERVAL = 5 * 60 * 1000

export const cacheAdapter = async (config) => {
  if (isUrlInWhiteList(config.url)) {
    const checkIsValidResponse = await isValid(keyGenerate(config) || '')
    if (
      (config.method === 'GET' || 'get') &&
      checkIsValidResponse.isValid &&
      isUrlInWhiteList(config.url)
    ) {
      config.headers.cached = true
      config.data = JSON.parse(checkIsValidResponse.value || '{}')
      return Promise.resolve(config)
    }
  }
  return new Promise((resolve, reject) => {
    httpAdapter(config)
      .then((response) => {
        if (isUrlInWhiteList(config.url)) {
          store(keyGenerate(config), JSON.stringify(response.data))
        }
        settle(resolve, reject, response)
      })
      .catch(reject)
  })
}
