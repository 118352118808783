import { useColorByScreen, useTheme } from 'components/src/hooks'

import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = (screen) => {
  const { pallete } = useTheme()
  const color = useColorByScreen(screen)

  return {
    styles: StyleSheet.create({
      container: {
        backgroundColor: pallete.containerBackground,
        maxWidth: 500,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 10,
        paddingRight: 10,
      },
      headerWrapper: {
        paddingTop: 8,
        paddingRight: 10,
      },
      button: {
        width: '100%',
        maxWidth: 300,
        marginTop: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 8,
        paddingBottom: 8,
        alignItems: 'center',
        backgroundColor: pallete.primary,
        borderWidth: 0,
        textAlign: 'center',
      },
      buttonLabel: {
        ...fontFamilies.cl_bold,
        fontSize: 22,
        color: pallete.buttonTextDefault,
      },
      contentCardWrapper: {
        width: '100%',
        maxWidth: 325,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 4,
        paddingRight: 4,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 30,
      },
      productsContainer: {
        marginTop: 20,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      productsDivider: {
        width: '100%',
        height: 1,
        backgroundColor: pallete.divider,
        marginBottom: 10,
      },
      sliderWrapper: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 440,
        alignItems: 'center',
        marginTop: 20,
      },
    }),
  }
}
