import { StyleSheet } from 'react-native'
import { useTheme } from 'components/src/hooks'
import { fontFamilies } from '../../configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()

  return {
    styles: StyleSheet.create({
      container: {
        justifyContent: 'center',
        alignItems: 'center',
      },
      text: {
        ...fontFamilies.cl_regular,
        color: pallete.previewProfileText,
        fontSize: 22,
        marginTop: 14,
        textAlign: 'center',
      },
    }),
    iconColor: pallete.previewProfileIcon,
  }
}
