import { home, myLocation, profile } from './screensLinking'

export const linking = {
  config: {
    Welcome: '/',
    Tabs: {
      screens: {
        ...home,
        ...myLocation,
        ...profile,
      },
    },
  },
}
