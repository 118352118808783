import { useSelector } from 'react-redux'
import { settingsSelectors } from 'components/src/bus/settings'

export const useCountriesData = () => {
  const countries = useSelector((state) =>
    settingsSelectors.getCountries(state)
  )

  return {
    countries,
  }
}
