import React from 'react'

export const WebFonts = () => {
  return (
    <style type="text/css">
      {`
        @font-face {
          font-family: 'fontello';
          src: url(${require('../../../assets/fonts/fontello.ttf')}) format('truetype')
        }
        @font-face {
          font-family: 'ChampagneLimousines';
          font-style: 'normal';
          font-weight: 'normal';
          src: url(${require('../../../assets/fonts/ChampagneLimousines.ttf')}) format('truetype')
        }
        @font-face {
          font-family: 'ChampagneLimousinesItalic';
          font-style: 'italic';
          font-weight: 'normal';
          src: url(${require('../../../assets/fonts/ChampagneLimousinesItalic.ttf')}) format('truetype')
        }
        @font-face {
          font-family: 'ChampagneLimousinesBold';
          font-style: 'normal';
          font-weight: 'bold';
          src: url(${require('../../../assets/fonts/ChampagneLimousinesBold.ttf')}) format('truetype')
        }
        @font-face {
          font-family: 'ChampagneLimousinesBoldItalic';
          font-style: 'italic';
          font-weight: 'bold';
          src: url(${require('../../../assets/fonts/ChampagneLimousinesBoldItalic.ttf')}) format('truetype')
        }
        @font-face {
          font-family: 'OpenSans';
          font-style: normal;
          font-weight: normal;
          src: url(${require('../../../assets/fonts/OpenSans-Regular.ttf')})  format('truetype')
        }
        @font-face {
          font-family: 'OpenSans';
          font-style: normal;
          font-weight: bold;
          src: url(${require('../../../assets/fonts/OpenSans-Bold.ttf')})  format('truetype')
        @font-face {
          font-family: 'OpenSans';
          font-style: normal;
          font-weight: 300;
          src: url(${require('../../../assets/fonts/OpenSans-Light.ttf')}) format('truetype')
        }
      `}
    </style>
  )
}

//TODO: Regenerate font
//https://www.glyphrstudio.com/online/
