import { StyleSheet } from 'react-native'

import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      wrapper: {
        backgroundColor: '#ffffff',
      },
      backgroundImage: {
        flex: 1,
        resizeMode: 'cover',
      },
      cardsWrapper: {
        flexShrink: 1,
        width: '100%',
        maxWidth: 337,
        marginLeft: 'auto',
        marginRight: 'auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginTop: 20,
        paddingLeft: 6,
        paddingRight: 6,
      },
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 460,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10,
      },
      logo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 22,
      },
      hotelTitle: {
        ...fontFamilies.cl_regular,
        fontSize: 21,
        color: pallete.textBlack,
        marginBottom: 14,
        marginLeft: 8,
      },
      titleWrapper: {
        paddingTop: 6,
        paddingBottom: 6,
        marginBottom: 14,
        marginLeft: 8,
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 22,
        color: pallete.primary,
        textTransform: 'uppercase',
      },
    }),
    pallete
  }
}
