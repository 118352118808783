import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import Swiper from 'react-native-web-swiper'

import { useUriImage } from 'components/src/hooks'
import { CustomImage } from 'components/src/components'

import { useStyles } from './styles'

export const ImageSlider = ({ images, height, width }) => {
  const { styles } = useStyles({ height, width })
  const { getUriImage } = useUriImage()

  if (images.length === 0) {
    return null
  }

  if (images.length === 1) {
    return (
      <View style={styles.singleImage}>
        <View key={images[0].Uuid} style={styles.imageContainer}>
          <View style={styles.imageBackground} />
          <CustomImage
            style={styles.image}
            source={getUriImage({
              uuid: images[0].DocumentUuid,
              width: width,
            })}
          />
        </View>
      </View>
    )
  }

  return (
    <Swiper
      innerContainerStyle={{ width: width, height: height }}
      slideWrapperStyle={{ paddingLeft: 5, paddingRight: 5 }}
      controlsProps={{
        PrevComponent: () => null,
        NextComponent: () => null,
        dotsWrapperStyle: styles.dotsWrapper,
        DotComponent: ({ isActive }) => (
          <View style={[styles.dot, isActive && styles.dotActive]} />
        ),
      }}
    >
      {images.map((image) => (
        <View key={image.Uuid} style={styles.imageContainer}>
          <View style={styles.imageBackground} />
          <CustomImage
            style={styles.image}
            source={getUriImage({
              uuid: image.DocumentUuid,
              width: width,
            })}
          />
        </View>
      ))}
    </Swiper>
  )
}

ImageSlider.defaultProps = {
  width: 300,
  height: 190,
}

ImageSlider.propTypes = {
  images: PropTypes.array.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
}
