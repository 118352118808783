import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { View, Linking, Platform, Text } from 'react-native'

import { Card, Preloader } from 'components/src/components'
import { contentSelectors } from 'components/src/bus/content'

import { useChildrenSettings } from '../../hooks'
import { useStyles } from '../../styles'

export const ChildrenElement = ({ parentUuid, navigate, tilesShouldBeClickable }) => {
  const menuItems = useSelector(contentSelectors.getMyLocationMenuItems)
  const myContent = useSelector(contentSelectors.getMyContent)

  const { styles, pallete } = useStyles()

  const { loading, structureItems, handleSingleItem } = useChildrenSettings(
    menuItems
  )

  const _nextScreen = (data) => {
    if (tilesShouldBeClickable) {
      if (data.actionType === 'single') {
        handleSingleItem(data.Uuid)
      } else {
        const ismenuItemParent =
          menuItems?.filter((item) => item.ParentUuid === data.Uuid).length > 0 ||
          false
        const isContentParent =
          myContent?.filter((item) => item.ParentUuid === data.Uuid).length > 0 ||
          false
        const isWebsite = data.MenuItemTypeName === 'Webseite'

        if (!ismenuItemParent && !isContentParent && !isWebsite) {
          navigate('Content', { singleItemUuid: data.Uuid })
        } else {
          if (ismenuItemParent || isContentParent) {
            navigate('List', { uuid: data.Uuid, isContentParent: isContentParent })
          } else {
            Platform.OS === 'web'
              ? window
                .open(
                  data.Website.search('http') >= 0
                    ? data.Website
                    : `https://${data.Website}`,
                  '_blank'
                )
                .focus()
              : Linking.openURL(
                data.Website.search('http') >= 0
                  ? data.Website
                  : `https://${data.Website}`
              )
          }
        }
      }
    }
  }

  const items = useMemo(() => {
    if (menuItems) {
      return structureItems(
        menuItems.filter((item) => item.ParentUuid === parentUuid)
      )
    }
    return null
  }, [menuItems, parentUuid, structureItems])

  return (
    <>
      {items && items.length <= 4 && (
        <View style={styles.cardsWrapperSpacing} />
      )}
      {loading ? (
        <Preloader scree="location" containerStyles={styles.preloader} />
      ) : (
        items &&
        items.map((item) => (
          <Card
            key={item.Uuid}
            data={item}
            background={pallete.secondary}
            onPress={_nextScreen}
          />
        ))
      )}
    </>
  )
}

ChildrenElement.propTypes = {
  parentUuid: PropTypes.string,
  navigate: PropTypes.func.isRequired,
}
