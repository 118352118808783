import { all, call, put } from 'redux-saga/effects'
import {
  fetchContentImages,
  fetchContentPdf,
  fetchContentProducts,
  fetchContentWebsite,
  fetchOpeningHours,
  fetchWebsite,
} from './fetch.workers'

// Local Dir
import { apiContent } from '../../api'
// Local
import { contentActions } from 'components/src/bus/content'
import { uiActions } from 'components/src/bus/ui'

export function* fetchAdditionalContent(action) {
  try {
    yield put(contentActions.toggleAdditionalLoading(true))

    if (action.payload.screen === 'home') {
      yield put(contentActions.clearAdditionalContent())
      yield put(contentActions.saveContentUuid(action.payload.uuid))
    }
    if (action.payload.screen === 'location') {
      yield put(contentActions.clearMyAdditionalContent())
      yield put(contentActions.saveMyContentUuid(action.payload.uuid))
    }

    const result = yield all([
      call(fetchOpeningHours, action.payload.uuid),
      call(fetchContentImages, action.payload.uuid),
      call(fetchContentPdf, action.payload.uuid),
      call(fetchContentProducts, action.payload.uuid),
      call(fetchContentWebsite, action.payload.uuid),
    ])
    let resultObject = { onlyPdf: null }
    result.map((item) => {
      if (item) {
        resultObject = { ...resultObject, ...item }
      }
    })

    if (action.payload.screen === 'home') {
      yield put(contentActions.saveAdditionalContent(resultObject))
    }
    if (action.payload.screen === 'location') {
      yield put(contentActions.saveMyAdditionalContent(resultObject))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error, 'Content fetchAdditionalContent workers')
    )
  } finally {
    yield put(contentActions.toggleAdditionalLoading(false))
  }
}

export function* fetchContentSinglePdf(action) {
  try {
    yield put(contentActions.toggleAdditionalLoading(true))
    yield put(contentActions.clearAdditionalContent())
    const response = yield call(apiContent.fetchSinglePdf, action.payload.uuid)
    if (response.data) {
      if (action.payload.type === 'home') {
        yield put(contentActions.saveSinglePdf(response.data))
      } else {
        yield put(contentActions.saveMySinglePdf(response.data))
      }
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error, 'Content fetchContentSinglePdf workers')
    )
  } finally {
    yield put(contentActions.toggleAdditionalLoading(false))
  }
}
