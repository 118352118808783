import { createSlice } from '@reduxjs/toolkit'
// Local
import { types } from './types'

const initialState = {
  theme: 'cooee',
}

const slice = createSlice({
  name: 'Themes',
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      state.theme = action.payload
    },
  },
})

export default slice.reducer

// Action Creators Async
export const themesActions = {
  // Change
  changeTheme: slice.actions.changeTheme,
  //Async
  fetchThemeAsync: () => ({
    type: types.FETCH_THEME,
  }),
  changeThemeAsync: (payload) => ({
    type: types.CHANGE_THEME,
    payload,
  }),
}
