// Workers

import {
  addItemToCart,
  changeItemCartCount,
  changeOptionsCart,
  fetchOptions,
  removeAllItems,
  removeItemFromCart,
  sendGuestOrders,
} from './workers'
import { all, call, takeLatest } from 'redux-saga/effects'

import { types } from '../types'

//Core

// Types

function* watchAddItemToCart() {
  yield takeLatest(types.MUTATION_ADD_ITEM, addItemToCart)
}

function* watchRemoveItemFromCart() {
  yield takeLatest(types.MUTATION_REMOVE_ITEM, removeItemFromCart)
}

function* watchChangeItemCartCount() {
  yield takeLatest(types.MUTATION_CHANGE_COUNT_ITEM, changeItemCartCount)
}

function* watchRemoveAllItems() {
  yield takeLatest(types.MUTATION_REMOVE_ALL_ITEMS, removeAllItems)
}

function* watchSendGuestOrders() {
  yield takeLatest(types.SEND_GUEST_ORDERS, sendGuestOrders)
}

function* watchFetchOptions() {
  yield takeLatest(types.FETCH_OPTIONS, fetchOptions)
}

function* watchChangeOption() {
  yield takeLatest(types.MUTATION_SAVE_OPTIONS, changeOptionsCart)
}

export function* watchCart() {
  yield all([
    call(watchAddItemToCart),
    call(watchRemoveItemFromCart),
    call(watchChangeItemCartCount),
    call(watchRemoveAllItems),
    call(watchSendGuestOrders),
    call(watchFetchOptions),
    call(watchChangeOption),
  ])
}
