import { StyleSheet } from 'react-native'

import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      wrapper: {
        backgroundColor: '#ffffff',
      },
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: 460,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 10,
      },
      logo: {
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 22,
      },
      hotelTitle: {
        ...fontFamilies.cl_bold,
        fontSize: 15,
        color: pallete.defaultText,
        marginBottom: 14,
        marginLeft: 8,
      },
      titleWrapper: {
        paddingTop: 6,
        paddingBottom: 6,
        marginBottom: 14,
        marginLeft: 8,
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 22,
        color: pallete.actionText,
        textTransform: 'uppercase',
      },
    }),
  }
}
