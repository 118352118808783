const actionCreators = {
  FETCH_HOME_MENU_ITEMS: 'CONTENT/FETCH_HOME_MENU_ITEMS',
  FETCH_MY_LOCATION_MENU_ITEMS: 'CONTENT/FETCH_MY_LOCATION_MENU_ITEMS',
  FETCH_CONTENT: 'CONTENT/FETCH_CONTENT',
  FETCH_ADDITIONAL_CONTENT: 'CONTENT/FETCH_ADDITIONAL_CONTENT',
  FETCH_SINGLE_ITEM: 'CONTENT/FETCH_SINGLE_ITEM',
  FETCH_CONTENT_SINGLE_PDF: 'CONTENT/FETCH_CONTENT_SINGLE_PDF',
  FETCH_OPEN_LINK: 'CONTENT/FETCH_OPEN_LINK',
  FETCH_CONTENT_LIST: 'CONTENT/FETCH_CONTENT_LIST',
}

export const types = Object.freeze(actionCreators)
