/* eslint-disable react-hooks/exhaustive-deps */

import {
  Dimensions,
  Platform,
  Text,
  TextInput,
  View,
  useWindowDimensions,
} from 'react-native'
import { FlatList, TouchableOpacity } from 'react-native-gesture-handler'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Icon } from 'components/src/components'
import PropTypes from 'prop-types'
import { useCountriesData } from './hooks'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

export const HotelAutocomplete = ({
  defaultValue,
  onChange = () => {},
  data = [],
}) => {
  const [value, setValue] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [inputFocused, setInputFocused] = useState(false)
  const [options, setOptions] = useState([])
  const { countries } = useCountriesData()
  const { styles } = useStyles()
  const input = useRef()
  const { t } = useTranslation()
  const windowWidth = useWindowDimensions().width

  useEffect(() => {
    if (defaultValue) {
      setSelectedCountry(
        countries.filter((country) => country.Code === defaultValue)[0]
      )
    } else {
      setSelectedCountry(worldObj)
    }
  }, [countries, defaultValue])

  const _handleOnChangeValue = (newValue) => {
    setValue(newValue)
  }

  const _handleSelectOption = (selectItem) => {
    input.current.blur()
    setValue(selectItem.DisplayName)
    setSelectedCountry(selectItem)
    onChange(selectItem)
  }

  const _handelBlur = () => {
    const timer = setTimeout(
      () => {
        setInputFocused(false)
      },
      Platform.OS === 'web' ? 200 : 50
    )
    return () => clearTimeout(timer)
  }

  const worldObj = {
    Citizenship: false,
    Code: '',
    CurrencyCode: '',
    DisplayCitizenshipName: '',
    DisplayName: t('selection.world'),
    Enabled: true,
    Id: null,
    Name: '',
    PhoneCode: '',
    Uuid: '',
  }

  useEffect(() => {
    if (data.length > 0) {
      const hotelCountryCodes = [
        ...new Set(
          data
            .filter((hotel) => hotel.CountryCode)
            .map((hotel) => hotel.CountryCode)
        ),
      ]
      let filteredCountries = [worldObj].concat(
        countries.filter((country) => hotelCountryCodes.includes(country.Code))
      )
      setOptions(filteredCountries)
    } else {
      setOptions([worldObj].concat(countries))
    }
  }, [countries, data, defaultValue])

  useEffect(() => {
    if (!inputFocused) {
      if (selectedCountry) {
        setValue(selectedCountry.DisplayName)
      } else {
        setValue('')
      }
    }
  }, [inputFocused, selectedCountry])

  const getDynamicStyles = () => {
    const isSM = windowWidth < 420
    return {
      container: { flexDirection: isSM ? 'column' : 'row', maxWidth: 500 },
      inputContainer: {
        flexDirection: 'row',
        position: isSM ? 'relative' : null,
      },
      input:
        Platform.OS === 'web'
          ? {
              outline: 'unset',
              minWidth: isSM ? 195 : 180,
              marginTop: isSM ? 10 : 0,
            }
          : { minWidth: isSM ? 195 : 180, padding: 0 },
      icon: isSM
        ? {
            margin: 'auto',
            position: 'absolute',
            bottom: 4,
            right: 0,
            marginLeft: 4,
          }
        : {},
    }
  }

  const renderData = ({ item }) => (
    <TouchableOpacity
      style={
        item !== selectedCountry
          ? styles.autocompleteItem
          : styles.autocompleteItemActive
      }
      onPress={() => _handleSelectOption(item)}
    >
      <Text
        style={
          item !== selectedCountry
            ? styles.autocompleteItemLabel
            : styles.autocompleteItemLabelActive
        }
      >
        {item.DisplayName}
      </Text>
    </TouchableOpacity>
  )

  return (
    <View style={[styles.container, getDynamicStyles().container]}>
      <Text style={styles.label}>{t('titles.hotel_autocomplete')}</Text>
      <View style={getDynamicStyles().inputContainer}>
        <TextInput
          ref={input}
          style={[styles.input, getDynamicStyles().input]}
          autoCompleteType="off"
          value={value}
          onChangeText={(text) => _handleOnChangeValue(text)}
          onFocus={() => setInputFocused(true)}
          onBlur={_handelBlur}
        />
        <Icon
          name="search"
          color="black"
          size={14}
          style={getDynamicStyles().icon}
        />
        {inputFocused && options.length > 0 && (
          <View style={styles.autocompleteList}>
            <View style={styles.autoCompleteContainer}>
              <FlatList
                autoFocus={false}
                disableScrollViewPanResponder
                data={options}
                renderItem={renderData}
                keyExtractor={(item) => item.Uuid}
                nestedScrollEnabled
              />
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

HotelAutocomplete.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  data: PropTypes.array,
}
