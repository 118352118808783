import { Linking, Platform } from 'react-native'
import { contentActions, contentSelectors } from 'components/src/bus/content'
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'

import { useDispatch } from 'react-redux'
import { useStoreSelector } from 'components/src/hooks'
import { useTranslation } from 'react-i18next'

const contentStoreSelector = (state) => ({
  isLoading: contentSelectors.additionalIsLoading(state),
  contentUuid: contentSelectors.getMyContentUuid(state),
  additionalContent: contentSelectors.getMyAdditionalContent(state),
  myLocationMenuItems: contentSelectors.getMyLocationMenuItems(state),
})

export const useFetchData = (contentItems) => {
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { contentUuid, isLoading, additionalContent, myLocationMenuItems } =
    useStoreSelector(contentStoreSelector)
  const navigation = useNavigation()
  const { params } = useRoute()

  const onChangeLanguage = useCallback(() => {
    fetchData()
  }, [dispatch, params])

  useEffect(() => {
    i18n.on('languageChanged', onChangeLanguage)
    return () => {
      i18n.off('languageChanged', onChangeLanguage)
    }
  }, [i18n, onChangeLanguage])

  useEffect(() => {
    fetchData()
  }, [contentItems, contentUuid, dispatch])

  const fetchData = () => {
    if (contentItems && contentItems.length === 1) {
      if (
        contentItems[0].Uuid !== contentUuid &&
        contentItems[0].ContentItemTypeId !== 4
      ) {
        dispatch(contentActions.saveMyContentUuid(contentItems[0].Uuid))
        dispatch(
          contentActions.fetchAdditionalContentAsync({
            uuid: contentItems[0].Uuid,
            screen: 'location',
          })
        )
      }
      if (
        contentItems[0].Uuid !== contentUuid &&
        contentItems[0].ContentItemTypeId === 4
      ) {
        dispatch(contentActions.saveMyContentUuid(contentItems[0].Uuid))
        dispatch(
          contentActions.fetchSinglePdf({
            uuid: contentItems[0].Uuid,
            screen: 'location',
          })
        )
      }
    }
  }

  const _actionContentCard = (item) => {
    if (item.ContentItemTypeId !== 3) {
      navigation.push('Content', { ...params, contentUuid: item })
    } else {
      const website = item.Translations[0].Website
      Platform.OS === 'web'
        ? window
            .open(
              website.search('http') >= 0 ? website : `https://${website}`,
              '_blank'
            )
            .focus()
        : Linking.openURL(
            website.search('http') >= 0 ? website : `https://${website}`
          )
    }
  }

  const _contentImages = additionalContent
    ? additionalContent.contentImages
    : null

  const _contentPdf = additionalContent ? additionalContent.contentPdf : null

  const _singlePdf = additionalContent ? additionalContent.singlePdf : null

  const _contentWebsite = additionalContent
    ? additionalContent.contentWebsite
    : null

  const _contentGeoPosition =
    contentItems.length > 0 && contentItems[0]
      ? contentItems[0].Latitude && contentItems[0].Longitude
        ? {
            lat: contentItems[0]?.Latitude || 0,
            lng: contentItems[0]?.Longitude || 0,
          }
        : null
      : null

  const _contentProducts = additionalContent
    ? additionalContent.products
      ? additionalContent.products.filter((product) => product.GrossPrice !== 0)
      : null
    : null

  let _contentContacts = null

  useEffect(() => {
    if (contentItems && contentItems.length > 0) {
      _contentContacts =
        contentItems[0].Street ||
        contentItems[0].City ||
        contentItems[0].Phone ||
        contentItems[0].Email
          ? {
              street: contentItems[0].Street ? contentItems[0].Street : null,
              city: contentItems[0].City ? contentItems[0].City : null,
              postalCode: contentItems[0].PostalCode
                ? contentItems[0].PostalCode
                : null,
              phone: contentItems[0].Phone ? contentItems[0].Phone : null,
              email: contentItems[0].Email ? contentItems[0].Email : null,
            }
          : null
    }
  }, [contentItems])

  return {
    isLoading,
    contentImages: _contentImages,
    singlePdf: _singlePdf,
    contentPdf: _contentPdf,
    contentWebsite: _contentWebsite,
    contentGeoPosition: _contentGeoPosition,
    contentProducts: _contentProducts,
    contentContacts: _contentContacts,
    actionContentCard: _actionContentCard,
    myLocationMenuItems: myLocationMenuItems,
  }
}
