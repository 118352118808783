import { Storage } from './Storage'
import {KEY_PREFIX} from "../../constants/AuthService";

class Auth {
  constructor() {
    this.storage = new Storage()
  }

  KEY_TOKEN = `${KEY_PREFIX}-t`
  KEY_LOGIN = `${KEY_PREFIX}-l`
  KEY_PASSWORD = `${KEY_PREFIX}-p`

  setToken(token) {
    return this.storage.set(this.KEY_TOKEN, token)
  }

  getToken() {
    return this.storage.get(this.KEY_TOKEN)
  }

  deleteToken() {
    return this.storage.remove(this.KEY_TOKEN)
  }

  setAuthData({ Username, Password }) {
    return Promise.all([
      this.storage.set(this.KEY_LOGIN, Username),
      this.storage.set(this.KEY_PASSWORD, Password),
    ])
  }

  getAuthData() {
    return Promise.all([
      this.storage.get(this.KEY_LOGIN),
      this.storage.get(this.KEY_PASSWORD),
    ]).then((values) => {
      if (values.length === 2) {
        return {
          Username: values[0],
          Password: values[1],
        }
      }
      return null
    })
  }

  deleteAuthData() {
    return Promise.all([
      this.storage.remove(this.KEY_LOGIN),
      this.storage.remove(this.KEY_PASSWORD),
    ])
  }
}

export const AuthService = new Auth()
