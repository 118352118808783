import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { View, Dimensions } from 'react-native'
import { Document, Page } from 'react-pdf'

import { useStyles } from './styles'

export const ContainerPdf = ({ uuid }) => {
  const [pages, setPages] = useState(null)
  const { stylesWeb: styles } = useStyles()

  const _handleNumPages = ({ numPages }) => {
    setPages(numPages)
  }
  const _viewPdfPages = () => {
    const contentPages = []
    if (pages) {
      for (let i = 1; i <= pages; i++) {
        contentPages.push(
          <View key={i} style={styles.pageWrapper}>
            <Page
              renderTextLayer={false}
              pageNumber={i}
              scale={1}
              width={Dimensions.get('window').width}
            />
          </View>
        )
      }
      return contentPages
    }
    return null
  }

  if (uuid) {
    return (
      <View style={styles.pdfWrapper}>
        <Document
          file={`https://api.betterspace.de/v1/Documents/${uuid}/Data`}
          onLoadSuccess={_handleNumPages}
        >
          {_viewPdfPages()}
        </Document>
      </View>
    )
  }

  return <View />
}

ContainerPdf.propTypes = {
  uuid: PropTypes.string.isRequired,
}
