export const useSettings = () => {
  const _getHotelName = (sites, selectedSite) => {
    let selectedSiteData = sites.filter((site) => site.Uuid === selectedSite)
    return selectedSiteData.length > 0 ? selectedSiteData[0].Name : ''
  }

  return {
    getHotelName: _getHotelName,
  }
}
