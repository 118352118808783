import { Animated, Modal, Text, TouchableOpacity, View } from 'react-native'
import { Icon, Select } from 'components/src/components'
import React, { useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'
import { useStyles } from '../styles'
import { useTranslation } from 'react-i18next'

export const Description = ({ data }) => {
  const [animValue, setAnimValue] = useState(0)
  const scaleAnim = useRef(new Animated.Value(0)).current
  useEffect(() => {
    const animation = Animated.timing(scaleAnim, {
      toValue: animValue,
      duration: 300,
      useNativeDriver: true,
    })
    animation.start()
  }, [animValue, scaleAnim])
  const _handleAnimation = () => {
    if (animValue === 0) {
      setAnimValue(1)
    } else {
      setAnimValue(0)
    }
  }

  const { t } = useTranslation()
  const { cartItemStyles: styles, pallete } = useStyles()

  const isEnglish = `${t('cart.description')}` == 'Description'

  return (
    <TouchableOpacity onPress={_handleAnimation}>
      <View style={styles.containerDescription}>
        <Text style={styles.descriptionTitle}>{`${t(
          'cart.description'
        )}`}</Text>
        <Animated.View
          style={[
            styles.selectIcon,
            isEnglish ? { left: 75 } : { left: 90 },
            {
              transform: [
                {
                  rotate: scaleAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['270deg', '90deg'],
                  }),
                },
              ],
            },
          ]}
        >
          <Icon name="chevron-back" size={14} color="rgba(0,0,0,0.45)" />
        </Animated.View>
      </View>
      {animValue === 1 && (
        <View style={styles.descriptionTextContainer}>
          <Text style={styles.descriptionTitle}>{data.DisplayDescription}</Text>
        </View>
      )}
    </TouchableOpacity>
  )
}
