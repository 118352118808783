import { Platform, StyleSheet } from 'react-native'

import { fontFamilies } from '../../configs/fontFamilies'
import { getStatusBarHeight } from 'components/src/services/IphoneXHelper'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        paddingTop: Platform.OS === 'ios' ? getStatusBarHeight() + 18 : 18,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: pallete.containerBackground,
        paddingLeft: 16,
      },
      headerLeft: {
        alignItems: 'flex-start',
        flex: 1,
      },
      headerRight: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        flex: 1,
      },
      headerCenter: {
        alignItems: 'center',
        flex: 2,
      },
      button: {
        width: '25%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        marginEnd: 8,
      },
      cartCounter: {
        top: -15,
        right: 40,
        position: 'relative',
        width: 20,
        height: 20,
        borderColor: pallete.alertBorder,
        borderWidth: 1,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      },
      shoppingBasket: {
        height: 24,
        width: 24,
      },
      alertCounter: {
        position: 'relative',
        top: -15,
        right: 40,
        width: 20,
        height: 20,
        borderColor: pallete.alertBorder,
        borderWidth: 1,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
      },
      alertCounterText: {
        color: pallete.alertBorder,
        fontSize: 15,
        ...fontFamilies.cl_bold,
      },
      message: {
        borderColor: pallete.alertMessageBorder,
        backgroundColor: pallete.defaultBackground,
        borderWidth: 1,
        padding: 4,
        marginBottom: 10,
      },
      messageText: {
        color: pallete.alertMessageText,
        fontSize: 12,
        ...fontFamilies.cl_regular,
        textAlign: 'center',
      },
      messageWrapper: {
        position: 'absolute',
        top: Platform.OS === 'ios' ? getStatusBarHeight() + 52 : 52,
        right: 16,
        width: 100,
        maxHeight: 300,
      },
    }),
  }
}
