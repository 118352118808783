import { cartSelectors } from 'components/src/bus/cart'
import { useStoreSelector } from 'components/src/hooks'

const storeSelector = (state) => ({
  cartItems: cartSelectors.getCartItems(state),
})

export const useCart = () => {
  const { cartItems } = useStoreSelector(storeSelector)
  return {
    cartItems,
  }
}
