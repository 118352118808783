import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView, View, ImageBackground, SafeAreaView } from 'react-native'

import { styles } from './styles'

//assets
const backgroundImage = require('components/assets/images/background_butterfly.png')

export const MainLayout = ({ children }) => {
  return (
    <ImageBackground source={backgroundImage} style={styles.backgroundImage}>
      <SafeAreaView style={styles.safeArea}>
        <View style={styles.fadeOut} />
        <View style={styles.container}>{children}</View>
      </SafeAreaView>
    </ImageBackground>
  )
}

MainLayout.propType = {
  children: PropTypes.node,
}
