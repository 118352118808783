//Core
import { all, call, takeEvery, takeLatest } from 'redux-saga/effects'
// Types
import { types } from '../types'
// Workers
import { fetchTheme, changeTheme } from './workers'

function* watchFetchTheme() {
  yield takeLatest(types.FETCH_THEME, fetchTheme)
}

function* watchChangeTheme() {
  yield takeEvery(types.CHANGE_THEME, changeTheme)
}

export function* watchThemes() {
  yield all([call(watchFetchTheme), call(watchChangeTheme)])
}
