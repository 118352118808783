import React from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View, Text } from 'react-native'
import { SvgXml } from './SvgXml'

import { CardIcon } from 'components/src/components'
import { useStyles } from './styles'

const defaultIcon =
  '<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="265.000000pt" height="265.000000pt" viewBox="0 0 265.000000 265.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,265.000000) scale(0.100000,-0.100000)" fill="#ffffff" stroke="none"><path d="M1095 2634 c-192 -34 -390 -118 -547 -231 -99 -71 -258 -235 -322 -331 -96 -145 -166 -311 -203 -485 -25 -115 -25 -408 0 -524 110 -512 486 -903 989 -1029 142 -35 379 -44 529 -20 560 92 997 526 1095 1086 25 145 15 411 -20 545 -29 112 -54 179 -103 278 -185 373 -553 645 -963 712 -118 19 -346 18 -455 -1z m518 -167 c395 -105 703 -392 831 -777 19 -58 40 -143 47 -189 16 -117 6 -353 -20 -452 -114 -436 -442 -763 -874 -870 -107 -26 -324 -36 -438 -19 -572 83 -1009 588 -1009 1165 0 562 411 1055 967 1160 126 23 372 14 496 -18z"></path><path d="M1266 1949 c-28 -22 -36 -73 -16 -109 16 -29 76 -42 116 -25 60 25 71 80 25 126 -36 36 -86 39 -125 8z"></path><path d="M1275 1585 l-25 -24 0 -416 0 -416 25 -24 c30 -30 64 -32 99 -4 l26 20 0 423 0 423 -23 21 c-31 29 -72 28 -102 -3z"></path></g></svg>'

export const Card = ({ data, onPress, background }) => {
  const { styles } = useStyles()

  return (
    <TouchableOpacity
      style={[styles.container, background && { backgroundColor: background }]}
      onPress={() => onPress(data)}
      activeOpacity={0.8}
    >
      <View style={styles.box}>
        {data.LogoDocumentUuid ? (
          <CardIcon
            uuid={data.LogoDocumentUuid}
            height={70}
            width={70}
            fill="#ffffff"
          />
        ) : data.IconSvg ? (
          <SvgXml
            xml={data.IconSvg.replace('#000000', '#ffffff')}
            height={70}
            width={70}
            fill="#ffffff"
          />
        ) : (
          <SvgXml xml={defaultIcon} height={70} width={70} fill="#ffffff" />
        )}
      </View>
      <View style={styles.labelWrapper}>
        <Text style={styles.label}>{data.DisplayName}</Text>
      </View>
    </TouchableOpacity>
  )
}

Card.propTypes = {
  data: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
  background: PropTypes.string,
}
