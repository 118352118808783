const DEVELOP = 'Develop'
const STAGE = 'Stage'
const PRODUCTION = 'Production'

const DSN =
  'https://716485838c9c477a9e321ca2f904d3bc@o454267.ingest.sentry.io/5558109'

const host = window.location.host

const getEnv = () => {
  const isDevelop = host.includes('localhost')
  const isStage = host.includes('.betterspace360.io')

  if (isDevelop) {
    return DEVELOP
  } else if (isStage) {
    return STAGE
  } else {
    return PRODUCTION
  }
}

const ENV = getEnv()

export const sentryConfig = {
  environment: ENV,
  dsn: DSN,
  autoSessionTracking: true,
}
