import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'
export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: pallete.buttonChangeLangModalOverlay,
      },
      container: {
        backgroundColor: pallete.buttonChangeLangModal,
        paddingTop: 30,
        paddingBottom: 40,
        width: 300,
        borderWidth: 1,
        borderColor: pallete.buttonChangeLangModalItemBorder,
        shadowOffset: {
          width: 0,
          height: 0,
        },
        shadowColor: pallete.buttonChangeLangModalShadow,
        shadowRadius: 10,
        shadowOpacity: 0.3,
        elevation: 10,
      },
      item: {
        paddingHorizontal: 10,
        paddingVertical: 6,
        borderBottomColor: pallete.buttonChangeLangModalItemBorder,
      },
      itemText: {
        textAlign: 'center',
        fontSize: 18,
        ...fontFamilies.cl_bold,
        color: pallete.buttonChangeLangModalItemText,
      },
      itemTextSelected: {
        color: pallete.buttonChangeLangModalItemTextActive,
      },
      title: {
        fontSize: 22,
        ...fontFamilies.cl_bold,
        marginBottom: 30,
        textAlign: 'center',
      },
    }),
  }
}
