import {
  Button,
  ContentHeader,
  HeaderNavigator,
  KeyboardAware,
  Select,
} from 'components/src/components'
import { FlatList, Text, TextInput, View } from 'react-native'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { cartActions, cartSelectors } from 'components/src/bus/cart'
import { useCart, useFetchData } from './hooks'
import { useDispatch, useSelector } from 'react-redux'

import { CartItem } from './components'
import { profileSelectors } from 'components/src/bus/profile'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useUriImage } from 'components/src/hooks'

export const CartScreen = ({ navigation }) => {
  const {
    cartItems,
    cartOptions,
    cartOptionsFetchigFlag,
    handleRemoveCartItem,
    handleChangeCartItemCount,
    total,
    handleChangeOptionItem,
  } = useCart()
  const { sites, selectedSite } = useFetchData()
  //don't use useState for hotelName and imageDocument;
  //it will create an infinite loop
  let hotelName = ''
  let imageDocumentUuid = ''
  const { getUriImage } = useUriImage()
  const { t } = useTranslation()
  const [confirmationTypeID, setConfirmationTypeID] = useState(2)
  const [comment, setComment] = useState('')
  const [roomNotValidated, setRoomNotValidated] = useState(false)
  const [phoneNotValidated, setPhoneNotValidated] = useState(false)
  const [room, setRoom] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [isPhoneNoVisible, setIsPhoneNoVisible] = useState(false)
  const { styles, cartItemStyles } = useStyles()
  const dispatch = useDispatch()
  const profile = useSelector(profileSelectors.getCurrent)
  const [heightsArray, setHeightsArray] = useState([])
  const flatListRef = useRef()
  const y = useRef(0)

  const getSplitedOptionsArray = () => {
    let splitedArray = []
    let optionsSetUuid = new Set(
      cartOptions.map((option) => {
        return option.ProductOptionSetUuid
      })
    )

    for (const setUuid of optionsSetUuid) {
      splitedArray.push(
        cartOptions.filter((option) => {
          return option.ProductOptionSetUuid === setUuid
        })
      )
    }

    return splitedArray
  }

  const checkConditions = () => {
    let errors = []
    for (const optionSet of getSplitedOptionsArray()) {
      //check if set is of freeText
      if (optionSet[0].isCommentary) {
        let isMandatoryConditionNotMatch = false
        for (const freeText of optionSet) {
          if (freeText.IsMandatory) {
            if (!(freeText.Text && freeText.Text.trim().length > 0)) {
              isMandatoryConditionNotMatch = true
            }
          }
        }
        if (isMandatoryConditionNotMatch) {
          errors.push(optionSet[0].ProductOptionSetUuid)
        }
      } else {
        const setIsMandatory = optionSet[0].IsMandatory
        const countSelected = optionSet.filter((box) => {
          return box.Selected
        })
        if (setIsMandatory && countSelected < 1) {
          errors.push(optionSet[0].ProductOptionSetUuid)
        }
      }
    }
    return errors
  }

  sites.map((site) => {
    if (site.Uuid == selectedSite) {
      hotelName = site.Name
      imageDocumentUuid = site.ImageDocumentUuid
    }
  })

  let positions = []
  if (cartItems) {
    for (const product of cartItems) {
      const data = product.data
      const amount = product.count
      const productOptions = cartOptions.filter((option) => {
        return option.ProductUuid === data.Uuid
      })
      let options = []
      let optionFreeText = null
      let optionBoxedSetUuid = null
      let optionSelecteedBoxes = []
      for (const option of productOptions) {
        if (option.Selected) {
          optionBoxedSetUuid = option.ProductOptionSetUuid
          optionSelecteedBoxes.push(option.Uuid)
        }
        if (option.isCommentary && option.Text && option.Text !== '') {
          optionFreeText = {
            ProductOptionSetUuid: option.ProductOptionSetUuid,
            ValueText: option.Text,
          }
        }
      }

      optionFreeText && options.push(optionFreeText)
      optionSelecteedBoxes.length > 0 &&
        options.push({
          ProductOptionSetUuid: optionBoxedSetUuid,
          ProductOptionUuids: optionSelecteedBoxes,
        })

      const productObject = {
        ProductUuid: data.Uuid,
        ContentItemUuid: selectedSite,
        Amount: amount,
        Date: new Date(),
        Options: options.length > 0 ? options : null,
      }
      positions.push(productObject)
    }
  } else {
    navigation.goBack()
  }

  const fetchFlag = cartOptionsFetchigFlag
  if (!fetchFlag) {
    dispatch(cartActions.toggleFetchOptionsFlag())
    dispatch(
      cartActions.fetchOptionsAsync({ data: cartItems, site: selectedSite })
    )
  }

  React.useEffect(() => {
    return () => {
      dispatch(cartActions.toggleFetchOptionsFlag())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation])

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addBackButton addLogo {...props} />,
      title: 'Cart COOEE',
    })
  })

  const sendHeight = (_y) => {
    y.current = _y
  }

  const _scrollToInput = () => {
    const offset = 299.2 //height until Shopping Cart title
    this.scroll.scrollTo({
      y: offset + y.current,
      animated: true,
    })
  }

  const handleClick = () => {
    if (isPhoneNoVisible) {
      if (phoneNo == '') {
        setPhoneNotValidated(true)
        return
      }
    }
    if (room !== '') {
      let orderData = {
        FirstName: profile.FirstName,
        LastName: profile.LastName,
        RoomUnitName: room,
        Comment: comment || null,
        ConfirmationTypeId: confirmationTypeID,
        ConfirmationPhone: isPhoneNoVisible ? phoneNo : null,
        ConfirmationEmail: profile.Email,
        Positions: positions,
      }

      if (checkConditions().length === 0) {
        dispatch(
          cartActions.sendGuestOrdersAsync({
            data: orderData,
            headers: { 'X-Context-SiteUuid': selectedSite },
          })
        )

        navigation.navigate('ThanksCart', {
          hotelName,
          imageDocumentUuid,
          total,
          cartOptions,
        })
      } else {
        _scrollToInput()
      }
    } else {
      setRoomNotValidated(true)
    }
  }

  const renderCartItems = ({ item }) => (
    <CartItem
      data={item.data}
      count={item.count}
      removeItem={handleRemoveCartItem}
      changeCountItem={handleChangeCartItemCount}
      changeOptionItem={handleChangeOptionItem}
      options={cartOptions?.filter((option) => {
        return item.data.Uuid === option.ProductUuid
      })}
      errors={checkConditions()}
      sendHeight={sendHeight}
    />
  )

  return (
    <KeyboardAware
      innerRef={(ref) => {
        this.scroll = ref
      }}
      style={styles.container}
    >
      <View style={styles.wrapper}>
        <ContentHeader
          source={getUriImage({ uuid: imageDocumentUuid, width: 340 })}
          title={t('cart.title')}
        />
        <View style={{ marginBottom: 10 }}>
          <Text style={styles.title}>{hotelName}</Text>
        </View>
        {cartItems && (
          <FlatList
            ref={flatListRef}
            data={cartItems}
            CellRendererComponent={renderCartItems}
            keyExtractor={(item) => item.data.Uuid.toString()}
          />
        )}
        <View style={styles.totalContainer}>
          <Text style={styles.title}>{t('cart.total')}</Text>
          <View style={styles.priceContainer}>
            <Text style={styles.title}>{`${total} €`}</Text>
            <Text style={styles.taxPrice}>{t('product.tax')}</Text>
          </View>
        </View>
        <View style={styles.box}>
          <Text
            style={[
              styles.label,
              {
                backgroundColor: roomNotValidated ? '#E5235F' : 'white',
              },
            ]}
          >{`${t('cart.room_name')}*`}</Text>
          <TextInput
            style={styles.textRoomArea}
            defaultValue={room}
            textAlignVertical="top"
            onChangeText={(changeValue) => {
              setRoomNotValidated(false)
              setRoom(changeValue)
            }}
          />
        </View>
        <View style={styles.box}>
          <Text style={styles.label}>{`${t('cart.confirmation_by')}*`}</Text>
          <Select
            defaultValue="email"
            onChange={(value) => {
              var confirmationID = -1
              switch (value) {
                case 'email':
                  confirmationID = 2
                  break
                case 'phone':
                  confirmationID = 3
                  break
              }
              setIsPhoneNoVisible(value === 'phone')
              setConfirmationTypeID(confirmationID)
            }}
            options={[
              { value: 'email', label: `${t('cart.e-mail')}` },
              { value: 'phone', label: `${t('cart.phone_no_choice')}` },
            ]}
            selectStyle={styles.selectStyle}
          />
        </View>
        {isPhoneNoVisible && (
          <View style={styles.box}>
            <Text
              style={[
                styles.label,
                {
                  backgroundColor: phoneNotValidated ? '#E5235F' : 'white',
                },
              ]}
            >{`${t('cart.phone_no')}*`}</Text>
            <TextInput
              style={styles.textRoomArea}
              textAlignVertical="top"
              defaultValue={phoneNo}
              onChangeText={(changeValue) => {
                setPhoneNotValidated(false)
                setPhoneNo(changeValue)
              }}
            />
          </View>
        )}
        <View style={styles.box}>
          <Text style={styles.label}>{t('cart.comment')}</Text>
          <TextInput
            multiline={true}
            style={styles.textArea}
            defaultValue={comment}
            textAlignVertical="top"
            onChangeText={(changeValue) => setComment(changeValue)}
          />
        </View>
        <Text style={cartItemStyles.mandatory}>{`*${t(
          'cart.mandatory'
        )}`}</Text>
      </View>
      <View style={styles.buttonsContainer}>
        {cartItems && (
          <Button style={styles.button} onPress={handleClick}>
            {t('cart.order_now')}
          </Button>
        )}
        <Button
          style={styles.button}
          onPress={() => {
            navigation.navigate('Start')
          }}
        >
          {t('cart.cancel')}
        </Button>
      </View>
    </KeyboardAware>
  )
}
