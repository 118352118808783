import { createSlice } from '@reduxjs/toolkit'
import { types } from './types'

const initialState = {
  cartItems: null,
  cartModal: false,
  options: [],
  fetchOptionsFlag: false,
}

const slice = createSlice({
  name: 'CART',
  initialState,
  reducers: {
    saveItems: (state, action) => {
      state.cartItems = action.payload
    },
    toggleModal: (state, action) => {
      if (state.cartItems && state.cartItems.length > 0) {
        state.cartModal = !state.cartModal
      }
    },
    saveOptions: (state, action) => {
      state.options = action.payload
    },
    toggleFetchOptionsFlag: (state, action) => {
      state.fetchOptionsFlag = !state.fetchOptionsFlag
    },
  },
})

export default slice.reducer

export const cartActions = {
  ...slice.actions,
  // Mutation
  changeOptionsCartMutation: (payload) => ({
    type: types.MUTATION_SAVE_OPTIONS,
    payload,
  }),
  addItemCartMutation: (payload) => ({
    type: types.MUTATION_ADD_ITEM,
    payload,
  }),
  removeItemCartMutation: (payload) => ({
    type: types.MUTATION_REMOVE_ITEM,
    payload,
  }),
  changeItemCartCountMutation: (payload) => ({
    type: types.MUTATION_CHANGE_COUNT_ITEM,
    payload,
  }),
  removeAllItems: (payload) => ({
    type: types.MUTATION_REMOVE_ALL_ITEMS,
    payload,
  }),
  sendGuestOrdersAsync: (payload) => ({
    type: types.SEND_GUEST_ORDERS,
    payload,
  }),
  fetchOptionsAsync: (payload) => ({
    type: types.FETCH_OPTIONS,
    payload,
  }),
}
