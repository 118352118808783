import React from 'react'
import PropTypes from 'prop-types'
import { View, Text, TouchableOpacity } from 'react-native'
import { useTranslation } from 'react-i18next'

import {
  Icon,
  CustomImage,
  ViewShortDescription,
} from 'components/src/components'
import { useUriImage } from 'components/src/hooks'
import { useStyles } from './styles'

export const HotelCard = ({ content, nextScreen, handleFavorite }) => {
  const { styles } = useStyles()
  const { getUriImage } = useUriImage()
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <TouchableOpacity style={styles.favourite} onPress={handleFavorite}>
          <Icon
            name={content.IsFavorite ? 'star' : 'star-outlined'}
            size={20}
            color="#E5235F"
          />
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.imageContainer}
          onPress={nextScreen}
        >
          <CustomImage
            style={styles.contentImage}
            source={getUriImage({
              uuid: content.ImageDocumentUuid,
              width: 200,
            })}
          />
        </TouchableOpacity>
        <View style={styles.contentDescription}>
          <View style={styles.shortDescriptionWrapper}>
            <TouchableOpacity style={styles.button} onPress={nextScreen}>
              <Text style={styles.title}>{content.Name}</Text>
            </TouchableOpacity>
            <ViewShortDescription
              style={styles.shortDescription}
              html={content.Description}
            />
          </View>
          <TouchableOpacity style={styles.button} onPress={nextScreen}>
            <Text style={styles.buttonLabel}>{t('buttons.continue')}...</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

HotelCard.propTypes = {
  content: PropTypes.object.isRequired,
  nextScreen: PropTypes.func.isRequired,
  handleFavorite: PropTypes.func.isRequired,
}
