import { StyleSheet } from 'react-native'

import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme, useColorByScreen } from 'components/src/hooks'

export const useStyles = (screen) => {
  const { pallete } = useTheme()
  const color = useColorByScreen(screen)
  return {
    styles: StyleSheet.create({
      container: {
        flexDirection: 'row',
        backgroundColor: pallete.primary,
        width: '100%',
        maxWidth: 300,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: 10,
        paddingBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
      },
      label: {
        ...fontFamilies.cl_bold,
        fontSize: 22,
        color: pallete.buttonTextDefault,
        marginLeft: 15,
      },
    }),
  }
}
