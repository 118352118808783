export const getHomeMenuItems = (state) => state.entities.content.homeMenuItems
export const getMyLocationMenuItems = (state) =>
  state.entities.content.myLocationMenuItems
export const isLoading = (state) => state.entities.content.loading
export const getContent = (state) => state.entities.content.content
export const getContentMenuItemUuid = (state) =>
  state.entities.content.contentMenuItemUuid
export const additionalIsLoading = (state) =>
  state.entities.content.additionalLoading
export const getContentUuid = (state) => state.entities.content.contentUuid
export const getListMyLocation = (state) =>
  state.entities.content.listMyLocation
export const getListHome = (state) => state.entities.content.listHome
export const getAdditionalContent = (state) =>
  state.entities.content.additionalContent

export const getMyContent = (state) => state.entities.content.myContent
export const getMyContentUuid = (state) => state.entities.content.myContentUuid
export const getMyAdditionalContent = (state) =>
  state.entities.content.myAdditionalContent
