import React, { useLayoutEffect, useEffect } from 'react'
import { View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
//components
import {
  ProfilePreview,
  HeaderNavigator,
  PasswordInput,
  Button,
  ButtonChangeLanguage,
} from 'components/src/components'

import { BaseLayout } from 'components/src/layouts'

//store
import { profileActions, profileSelectors } from 'components/src/bus/profile'

//local
import { useStyles } from './styles'
import { resolver } from './resolver'

export const ChangePasswordScreen = ({ navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addLogo addBackButton {...props} />,
    })
  })

  const dispatch = useDispatch()

  const isLoading = useSelector(profileSelectors.isLoading)

  const { t } = useTranslation()

  const { styles, buttonProps } = useStyles()

  const { control, errors, handleSubmit, reset } = useForm({
    defaultValues: {
      OldPassword: '',
      NewPassword: '',
      NewPasswordConfirm: '',
    },
    resolver,
  })

  useEffect(() => {
    if (!isLoading) {
      reset()
    }
  }, [isLoading, reset])

  const onSubmit = handleSubmit((data) => {
    dispatch(profileActions.updatePasswordAsync(data))
  })

  return (
    <BaseLayout contentContainerStyle={styles.content}>
      <View style={styles.layout}>
        <View style={styles.container}>
          <ProfilePreview style={styles.preview} />
          <Controller
            control={control}
            name="OldPassword"
            render={(props) => (
              <PasswordInput
                style={styles.input}
                label={t('form.label.password_old')}
                error={errors?.OldPassword && t(errors.OldPassword.message)}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="NewPassword"
            render={(props) => (
              <PasswordInput
                style={styles.input}
                label={t('form.label.password_new')}
                error={errors?.NewPassword && t(errors.NewPassword.message)}
                {...props}
              />
            )}
          />
          <Controller
            control={control}
            name="NewPasswordConfirm"
            render={(props) => (
              <PasswordInput
                style={styles.input}
                label={t('form.label.password_confirm_new')}
                error={
                  errors?.NewPasswordConfirm &&
                  t(errors.NewPasswordConfirm.message)
                }
                {...props}
              />
            )}
          />

          <Button
            loading={isLoading}
            style={styles.button}
            {...buttonProps}
            onPress={onSubmit}
          >
            {t('buttons.save')}
          </Button>
        </View>
        <View style={styles.control}>
          <ButtonChangeLanguage />
        </View>
      </View>
    </BaseLayout>
  )
}
