import { StyleSheet } from 'react-native'

import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

import {
  getStatusBarHeight,
  getBottomSpace,
} from 'components/src/services/IphoneXHelper'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flex: 1,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: pallete.cartModalBackground,
      },
      wrapper: {
        flex: 1,
        paddingTop: 60 + getStatusBarHeight() + 18,
        paddingBottom: getBottomSpace() + 50,
      },
      box: {
        flexGrow: 1,
        borderWidth: 1,
        borderColor: pallete.cartBoxBorder,
        paddingLeft: 22,
        paddingRight: 22,
        paddingTop: 100,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 22,
        color: pallete.defaultText,
        marginBottom: 12,
      },
      description: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: pallete.defaultText,
        paddingTop: 23,
        paddingBottom: 11,
        paddingLeft: 4,
        paddingRight: 4,
      },
      textDescription: {
        ...fontFamilies.cl_regular,
        fontSize: 22,
        color: pallete.defaultText,
      },
      buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
      },
      button: {
        marginTop: 20,
        width: 160,
        flexShrink: 1,
        paddingTop: 5,
        paddingBottom: 5,
        marginLeft: 4,
        marginRight: 4,
        backgroundColor: pallete.buttonBackgroundDefault,
      },
      buttonLabel: {
        ...fontFamilies.cl_bold,
        fontSize: 20,
        color: pallete.buttonTextDefault,
        textAlign: 'center',
      },
    }),
  }
}
