import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, Linking, Text, Platform } from 'react-native'

import { Icon } from 'components/src/components'

import { useStyles } from './styles'

export const RoutePlaneButton = ({ position, screen }) => {
  const { styles } = useStyles(screen)
  const { t } = useTranslation()
  const link = () => {
    Platform.OS === 'web'
      ? window.open(
          `https://www.google.com/maps/dir/?api=1&destination=${position.lat},${position.lng}`,
          '_blank'
        )
      : Linking.openURL(
          `https://www.google.com/maps/dir/?api=1&destination=${position.lat},${position.lng}`
        )
  }
  return (
    <TouchableOpacity onPress={link} style={styles.container}>
      <Icon name="marker" color="#ffffff" size={16} />
      <Text style={styles.label}>{t('content.route_plane')}</Text>
    </TouchableOpacity>
  )
}

RoutePlaneButton.defaultProps = {
  screen: 'home',
}

RoutePlaneButton.propTypes = {
  position: PropTypes.object.isRequired,
  screen: PropTypes.oneOf(['location', 'home']),
}
