import { useColorByScreen, useTheme } from 'components/src/hooks'

import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = (screen) => {
  const { pallete } = useTheme()
  const color = useColorByScreen(screen)
  return {
    styles: StyleSheet.create({
      container: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      title: {
        ...fontFamilies.cl_bold,
        fontSize: 18,
        marginBottom: 4,
        color: pallete.primary,
      },
      paragraph: {
        ...fontFamilies.os_light,
        fontSize: 16,
        color: pallete.defaultText,
      },
      br: {
        height: 24,
        width: 4,
      },
      table: {
        borderWidth: 1,
        borderColor: pallete.defaultText,
        marginBottom: 16,
        marginTop: 12,
      },
      th: {
        borderWidth: 1,
        borderColor: pallete.defaultText,
        flex: 1,
        paddingLeft: 4,
        paddingTop: 3,
        paddingBottom: 3,
      },
      tr: {
        flexDirection: 'row',
      },
    }),
    pallete,
  }
}
