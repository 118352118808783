import { createSlice } from '@reduxjs/toolkit'
import { types } from './types'

const initialState = {
  salutations: null,
  countries: null,
  sites: null,
  selectedSite: null,
  loading: false,
  memberSites: null,
  currentCountries: null,
}

const slice = createSlice({
  name: 'SETTINGS',
  initialState,
  reducers: {
    saveSalutations: (state, action) => {
      state.salutations = action.payload
    },
    saveCountries: (state, action) => {
      state.countries = action.payload
    },
    saveSites: (state, action) => {
      state.sites = action.payload
    },
    clearSalutations: (state) => {
      state.salutations = null
    },
    clearCountries: (state) => {
      state.countries = null
    },
    clearSites: (state) => {
      state.sites = null
    },
    saveSelectedSite: (state, action) => {
      state.selectedSite = action.payload
    },
    saveMemberSites: (state, action) => {
      state.memberSites = action.payload
    },
    saveCurrentCountries: (state, action) => {
      state.currentCountries = action.payload
    },
    toggleLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export default slice.reducer

// Action Creators
export const settingsActions = {
  ...slice.actions,
  // Async
  fetchSalutations: () => ({
    type: types.FETCH_SALUTATION_ASYNC,
  }),
  fetchCountries: () => ({
    type: types.FETCH_COUNTRIES_ASYNC,
  }),
  fetchSites: () => ({
    type: types.FETCH_SITES_ASYNC,
  }),
  fetchMemberSites: () => ({
    type: types.FETCH_MEMBER_SITES_ASYNC,
  }),
}
