import React, { useCallback, useLayoutEffect } from 'react'
import { FlatList, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
//app
import { HotelCard, HeaderNavigator } from 'components/src/components'
import { profileActions, profileSelectors } from 'components/src/bus/profile'

//local
import { useStyles } from './styles'

export const FavoriteHotelScreen = ({ navigation }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { styles } = useStyles()

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addLogo addBackButton {...props} />,
    })
  })

  const hotels = useSelector(profileSelectors.getFavoriteSites)

  const handleFavorite = useCallback(
    (id) => {
      dispatch(profileActions.deleteBookmarkedSitesAsync(id))
    },
    [dispatch]
  )

  return (
    <FlatList
      keyExtractor={(item) => item.Uuid}
      style={styles.container}
      contentContainerStyle={styles.content}
      ListEmptyComponent={() => (
        <View style={styles.emptyList}>
          <Text style={styles.emptyListLabel}>
            {t('titles.empty_favourite')}
          </Text>
        </View>
      )}
      data={hotels}
      renderItem={({ item }) => (
        <HotelCard
          content={item}
          nextScreen={() =>
            navigation.navigate('FavoriteHotelContent', {
              siteUuid: item.Uuid,
              hideAutoComplete: true,
            })
          }
          handleFavorite={() => handleFavorite(item.Uuid)}
        />
      )}
    />
  )
}
