import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text } from 'react-native'

//components
import { Icon } from 'components/src/components'

//local
import { useStyles } from './styles'

export const ProfilePreview = ({ style }) => {
  const { styles, iconColor } = useStyles()
  const { t } = useTranslation()
  return (
    <View style={[styles.container, style]}>
      <Icon size={80} name="person-pin" color={iconColor} />
      <Text style={styles.text}>{t('profile.title')}</Text>
    </View>
  )
}
