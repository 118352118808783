import React, { useLayoutEffect } from 'react'
import { View, ScrollView } from 'react-native'

import { MainLayout } from 'components/src/layouts'
import { HeaderNavigator, Logo, Card } from 'components/src/components'

import { useStyles } from './styles'
import { useGetData, useActions } from './hooks'

export const ItemsHomeScreen = ({ navigation, route: { params } }) => {
  const { styles } = useStyles()
  const { menuItems, listHome } = useGetData(params.itemUuid)

  const { nextScreen } = useActions(menuItems)

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator addBackButton {...props} />,
    })
  })

  return (
    <MainLayout>
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.logoContainer}>
            <Logo height={100} />
          </View>
          <View style={styles.cardsWrapper}>
            {menuItems &&
              menuItems.map((item) => (
                <Card
                  key={item.Uuid}
                  data={item}
                  onPress={() => nextScreen(item)}
                />
              ))}
          </View>
        </View>
      </ScrollView>
    </MainLayout>
  )
}
