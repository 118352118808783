import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ScrollView, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import AsyncStorage from '@react-native-async-storage/async-storage'
//Local
import { MainLayout } from 'components/src/layouts'
import { HeaderNavigator, HotelsSelector, Logo, Preloader } from 'components/src/components'
import { useStyles } from './styles'
import { useFetchData } from './hooks'
import { ChildrenElement } from './components'

export const MainMyLocationScreen = ({ route, navigation }) => {
  const { sites, selectedSite, handleSelectSite, loading } = useFetchData()
  const { styles } = useStyles()
  const [isDistanceLoading, setIsDistanceLoading] = useState(true)
  const [tilesShouldBeClickable, setTilesShouldBeClickable] = useState(true)
  const { t } = useTranslation()

  const params = route.params

  const getDistanceFromLatLngInKm = (lat1, lng1, lat2, lng2) => {
    const getDeg2rad = deg => {
      return deg * (Math.PI / 180);
    }

    const getSquare = x => {
      return Math.pow(x, 2);
    }

    const radiusOfEarthInKm = 6371;

    lat1 = getDeg2rad(lat1);
    lat2 = getDeg2rad(lat2);
    const latDif = lat2 - lat1;
    const lngDif = getDeg2rad(lng2 - lng1);
    const a = getSquare(Math.sin(latDif / 2)) + Math.cos(lat1) * Math.cos(lat2) * getSquare(Math.sin(lngDif / 2));
    const distanceInKm = 2 * radiusOfEarthInKm * Math.asin(Math.sqrt(a));
    return distanceInKm; // return km
  }

  async function handleStoredSelectedSite() {
    try {
      const value = await AsyncStorage.getItem('selectedSite');
      if (value !== null) {
        handleSelectSite(value);
      }
    } catch (error) {
    }
  }

  useEffect(() => {
    // prevent race conditions with menu tiles display
    setTimeout(() => {
      handleStoredSelectedSite()
    }, 500)
  }, []);

  useEffect(() => {
    const shouldSelectSite = !selectedSite && "geolocation" in navigator;
    if (shouldSelectSite) {
      navigator.geolocation.getCurrentPosition(position => {
        let nearestHotel = null;

        for (const site of sites) {
          let distance = getDistanceFromLatLngInKm(position.coords.latitude, position.coords.longitude, site.Latitude, site.Longitude)
          if (distance < 50 && (!nearestHotel || distance < nearestHotel.distance)) { // only consider hotels within 50km radius
            nearestHotel = { uuid: site.Uuid, distance: distance }
          }
        }
        if (nearestHotel) {
          _changedSelect(nearestHotel.uuid);
        }
        setIsDistanceLoading(false);
      }, err => {
        console.log(err)
        setIsDistanceLoading(false);
      }, { timeout: 10000 });
    } else {
      setIsDistanceLoading(false);
    }

  }, [sites, selectedSite])

  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => (
        <HeaderNavigator addBackButton={!!params} {...props} />
      ),
      title: 'My location COOEE',
    })
  }, [navigation, params])

  const _changedSelect = (value) => {
    handleSelectSite(value)
  }

  const _setTilesShouldBeClickable = (value) => {
    value ?
      setTilesShouldBeClickable(true)
      :
      setTilesShouldBeClickable(false);
  }

  const _renderTiles = () => {
    if (selectedSite) {
      return (
        <View style={styles.cardsWrapper}>
          <ChildrenElement
            parentUuid={params?.parent || null}
            navigate={navigation.navigate}
            tilesShouldBeClickable={tilesShouldBeClickable}
          />
        </View>
      )
    }
    return (
      <View style={styles.emptyContainer}>
        <Text style={styles.emptyText}>{t('my_location.empty_list')}</Text>
      </View>
    )
  }

  return (
    <MainLayout>
      <ScrollView>
        <View style={styles.container}>
          <Logo height={100} />
          {loading ? (
            <Preloader screen="location" containerStyles={styles.preloader} />
          ) : (
            <>
              <View style={styles.spacing} />
              {isDistanceLoading ? <Preloader containerStyles={styles.preloader} /> : <>{sites &&
                <HotelsSelector
                  data={sites}
                  onChange={_changedSelect}
                  defaultValue={selectedSite}
                  _setTilesShouldBeClickable={_setTilesShouldBeClickable}
                />
              }
                {_renderTiles()}
              </>}
            </>
          )}
        </View>
      </ScrollView>
    </MainLayout>
  )
}
