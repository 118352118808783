import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        width: '100%',
        maxWidth: 440,
        paddingLeft: 10,
        paddingRight: 10,
        position: 'relative',
        zIndex: 10,
      },
      selectContainer: {
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0.0)',
        backgroundColor: 'rgba(255,255,255,.65)',
        width: '100%',
        height: 30,
        paddingLeft: 10,
        paddingRight: 40,
        position: 'relative',
        overflow: 'hidden',
      },
      selectContainerActive: {
        borderWidth: 1,
        borderColor: pallete.cartBoxBorder,
        width: '100%',
        height: 30,
        justifyContent: 'center',
        paddingLeft: 10,
        paddingRight: 40,
        position: 'relative',
        overflow: 'hidden',
        backgroundColor: 'rgba(255,255,255,.65)',
      },
      selectLabel: {
        ...fontFamilies.cl_regular,
        marginVertical: 'auto',
        fontSize: 21,
      },
      optionsContainer: {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 4,
        paddingBottom: 4,
        borderWidth: 1,
        top: 4,
        left: 0,
        right: 0,
        marginHorizontal: 0,
        borderColor: pallete.cartBoxBorder,
        backgroundColor: pallete.autocompleteListBackground,
        position: 'absolute',
        zIndex: 10,
      },
      option: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2,
        paddingBottom: 2,
        elevation: 10,
      },
      optionActive: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 2,
        paddingBottom: 2,
        elevation: 10,
        backgroundColor: pallete.primary,
      },
      optionLabel: {
        ...fontFamilies.cl_regular,
        fontSize: 18,
      },
      optionLabelActive: {
        ...fontFamilies.cl_bold,
        fontSize: 18,
        color: pallete.defaultBackground,
      },
      selectIcon: {
        position: 'absolute',
        top: 6,
        right: 10,
      },
    }),
  }
}
