import { call, put, take } from 'redux-saga/effects'
// Local
import { profileActions } from 'components/src/bus/profile'
import { uiActions } from 'components/src/bus/ui'
// Local Dir
import { apiProfile } from '../../api'
import { types } from '../../types'

export function* fetchProfile() {
  try {
    const response = yield call([apiProfile, 'fetchProfile'])
    if (response.data && response.data.Item) {
      yield put(profileActions.saveCurrentProfile(response.data.Item))
      yield put(profileActions.fetchBookmarkedSitesAsync())
    } else {
      throw 'Error not found Data Profile'
    }
  } catch (error) {
    yield put(profileActions.forceLogoutAsync())
    yield take(types.END_FORCE_LOGOUT)
    yield put(uiActions.emitError(error, 'Profile fetchProfile workers'))
  } finally {
    yield put({ type: types.END_FETCH_PROFILE })
  }
}

export function* fetchBookmarkedSites() {
  try {
    const response = yield call([apiProfile, 'fetchBookmarkedSites'])
    if (response.data && response.data.Items) {
      yield put(profileActions.saveBookmarkedSites(response.data.Items))
    }
  } catch (error) {
    yield put(
      uiActions.emitError(error, 'Profile fetchBookmarkedSites workers')
    )
  }
}
