import { CustomImage, ViewShortDescription } from 'components/src/components'
import { Text, TouchableOpacity, View } from 'react-native'

import PropTypes from 'prop-types'
import React from 'react'
import { useSettings } from './hooks'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { useUriImage } from 'components/src/hooks'

export const ListContentCard = ({ data, onPress, screen }) => {
  const { styles } = useStyles(screen)
  const { getUriImage } = useUriImage()
  const { getTitle } = useSettings(screen)
  const { t } = useTranslation()
  const content = data.contents[0]

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.imageContainer}
          onPress={onPress}
        >
          <CustomImage
            style={styles.contentImage}
            source={getUriImage({
              uuid: content.ImageDocumentUuid,
              width: 170,
            })}
          />
        </TouchableOpacity>
        <View style={styles.contentRight}>
          <View style={styles.shortDescriptionWrapper}>
            <TouchableOpacity onPress={onPress}>
              <Text style={styles.title}>{content.DisplayName}</Text>
            </TouchableOpacity>
            {content.DisplayText && (
              <ViewShortDescription
                style={styles.shortDescription}
                html={content.DisplayText}
              />
            )}
          </View>
          <TouchableOpacity style={styles.buttonMore} onPress={onPress}>
            <Text style={styles.buttonMoreLabel}>{t('buttons.continue')}…</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
}

ListContentCard.propTypes = {
  data: PropTypes.object,
  onPress: PropTypes.func,
  screen: PropTypes.oneOf(['home', 'location']),
}
