import PropTypes from 'prop-types'
import parse from 'html-react-parser'
// Local
import { useColorByScreen } from 'components/src/hooks'
import { useDisplayHtml } from './hooks'
// Local Dir
import { useStyles } from './styles'

export const ViewHtml = ({ html, screen }) => {
  const { pallete } = useStyles()

  const { displayHtml } = useDisplayHtml(html)

  const color = useColorByScreen(screen)

  return parse(`
        <section>
          <style>
            section {
              width: 100%; color: ${
                pallete.defaultText
              }; font-family: 'OpenSans'; font-weight: light; font-size: 16px;
            }
            h1, h2, h3, h4, h5, h6 {
              color: ${color}
            }
          </style>
          ${displayHtml.replace(/(font-size:\w*,?)/gi, '')}
        </section>`)
}

ViewHtml.propTypes = {
  html: PropTypes.string.isRequired,
  screen: PropTypes.oneOf(['location', 'home']),
}
