import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { View } from 'react-native'

import { Card } from 'components/src/components'
import { contentSelectors } from 'components/src/bus/content'

import { useStyles } from '../../styles'

export const ParentElement = ({ navigate }) => {
  const menuItems = useSelector(contentSelectors.getMyLocationMenuItems)
  const { styles, pallete } = useStyles()
  const items = useMemo(() => {
    if (menuItems) {
      return menuItems.filter(
        (item) => item.MenuTypeId === 2 && !item.ParentUuid
      )
    }
    return null
  }, [menuItems])

  const _nextScreen = (data) => {
    navigate('Start', { parent: data.TargetMenuItemUuid })
  }

  return (
    <>
      {items && items.length <= 4 && (
        <View style={styles.cardsWrapperSpacing} />
      )}
      {items &&
        items.map((item) => (
          <Card
            key={item.Uuid}
            data={item}
            background={pallete.secondary}
            onPress={_nextScreen}
          />
        ))}
    </>
  )
}

ParentElement.propType = {
  navigate: PropTypes.func.isRequired,
}
