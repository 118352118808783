import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView } from 'react-native'

import { useStyles } from './styles'

export const KeyboardAware = ({ children, style, ...props }) => {
  const { styles } = useStyles()
  return (
    <ScrollView {...props} style={[styles.container, style]}>
      {children}
    </ScrollView>
  )
}

KeyboardAware.propTypes = {
  children: PropTypes.node,
}
