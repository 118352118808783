import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Image, View } from 'react-native'

import { Preloader } from 'components/src/components'

import { useStyles } from './styles'

export const CustomImage = ({ style, source }) => {
  const [loading, setLoading] = useState(true)
  const _loadEnd = () => {
    setLoading(false)
  }
  const { styles } = useStyles()

  return (
    <View style={[styles.container, style]}>
      {loading && <Preloader />}
      <Image style={styles.image} source={source} onLoadEnd={_loadEnd} />
    </View>
  )
}

CustomImage.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  source: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([false])]),
}
