import { createSlice } from '@reduxjs/toolkit'
import { types } from './types'

const initialState = {
  alert: [],
  isStartScreen: true,
}

const slice = createSlice({
  name: 'UI',
  initialState,
  reducers: {
    addAlert: (state, action) => {
      state.alert = [...state.alert, { read: false, ...action.payload }]
    },
    readAlert: (state, action) => {
      state.alert = state.alert.map((item, index) =>
        action.payload.includes(index) ? { ...item, read: true } : item
      )
    },
    deleteReadAlert: (state) => {
      state.alert = state.alert.filter((item) => !item.read)
    },
    deleteAlert: (state) => {
      state.alert = []
    },
    closeStartScreen: (state) => {
      state.isStartScreen = false
    },
  },
})

export default slice.reducer

// Action Creators
export const uiActions = {
  ...slice.actions,
  emitError: (error, meta = null) => ({
    type: types.EMIT_ERROR,
    payload: error,
    error: true,
    meta,
  }),
  openedNotificationMessage: (notification) => ({
    type: types.OPEN_NOTIFICATION,
    payload: notification,
  }),
}
