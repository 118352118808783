import { StyleSheet } from 'react-native'
import { fontFamilies } from 'components/src/configs/fontFamilies'
import { useTheme } from 'components/src/hooks'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      container: {
        flexDirection: 'row',
        marginTop: 20,
        paddingLeft: 6,
        paddingRight: 6,
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        position: 'relative',
        zIndex: 100,
      },
      label: {
        ...fontFamilies.cl_regular,
        fontSize: 18,
        color: pallete.defaultText,
      },
      input: {
        width: '100%',
        maxWidth: 120,
        borderBottomWidth: 1,
        borderColor: pallete.cartBoxBorder,
        fontSize: 18,
        ...fontFamilies.cl_regular,
        paddingLeft: 6,
        paddingRight: 6,
      },
      autocompleteList: {
        position: 'absolute',
        zIndex: 10,
        borderWidth: 1,
        borderColor: pallete.autocompleteListBorder,
        paddingLeft: 10,
        paddingRight: 10,
        left: 10,
        right: 10,
        top: 30,
        backgroundColor: pallete.autocompleteListBackground,
      },
      autocompleteItem: {
        marginTop: 4,
        marginBottom: 4,
      },
      autocompleteItemLabel: {
        ...fontFamilies.cl_regular,
        fontSize: 18,
        color: pallete.defaultText,
      },
      autocompleteItemActive: {
        paddingTop: 4,
        paddingBottom: 4,
        marginTop: 4,
        marginBottom: 4,
        paddingLeft: 4,
        backgroundColor: pallete.cardBackground,
      },
      autocompleteItemLabelActive: {
        ...fontFamilies.cl_bold,
        fontSize: 18,
        color: pallete.cardText,
      },
      autoCompleteContainer: {
        height: 200,
      },
    }),
  }
}
