import React from 'react'
import { useTranslation } from 'react-i18next'
import { View, Text, Modal, TouchableOpacity } from 'react-native'

//local
import { Button } from 'components/src/components'
import { useStyles } from './styles'

export const ModalLogout = ({ modalVisible, onCancel, onConfirm }) => {
  const { styles } = useStyles()
  const { t } = useTranslation()
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={modalVisible}
      onRequestClose={onCancel}
    >
      <TouchableOpacity
        style={styles.centeredView}
        onPress={onCancel}
        activeOpacity={1}
      >
        <View style={styles.container}>
          <Text style={styles.title}>{t('titles.logout')}</Text>
          <Button
            width={250}
            height={42}
            onPress={onConfirm}
            style={styles.button}
          >
            {t('buttons.logout')}
          </Button>
          <Button
            mode="outline"
            width={250}
            height={42}
            onPress={onCancel}
            style={styles.button}
          >
            {t('buttons.cancel')}
          </Button>
        </View>
      </TouchableOpacity>
    </Modal>
  )
}
