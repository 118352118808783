import React, { useLayoutEffect } from 'react'
import { View, ScrollView } from 'react-native'

//components
import { HeaderNavigator, Logo, Card } from 'components/src/components'
import { MainLayout } from 'components/src/layouts'
//local
import { useStyles } from './styles'
import { useData } from './hooks'

export const ProfileStartScreen = ({ navigation }) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: (props) => <HeaderNavigator {...props} />,
    })
  })

  const { MenuItems } = useData()

  const { styles } = useStyles()

  return (
    <MainLayout>
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.logoContainer}>
            <Logo height={100} />
          </View>
          <View style={styles.cardContainer}>
            {MenuItems.map((item, index) => (
              <Card
                key={`profile-menu-${index}`}
                data={item}
                onPress={item.onPress}
              />
            ))}
          </View>
        </View>
      </ScrollView>
    </MainLayout>
  )
}
