import { StyleSheet } from 'react-native'

import { useTheme } from 'components/src/hooks'
import { fontFamilies } from 'components/src/configs/fontFamilies'

export const useStyles = () => {
  const { pallete } = useTheme()
  return {
    styles: StyleSheet.create({
      box: {
        height: 70,
      },
      container: {
        height: 150,
        width: 150,
        backgroundColor: pallete.cardBackground,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 15,
        paddingBottom: 20,
        marginBottom: 25,
        alignItems: 'center',
      },
      label: {
        textTransform: 'uppercase',
        textAlign: 'center',
        ...fontFamilies.cl_bold,
        color: pallete.cardText,
        fontSize: 19,
      },
      labelWrapper: {
        height: 60,
        width: '100%',
        justifyContent: 'center',
      },
      image: {
        width: 120,
        height: 120,
      },
    }),
  }
}
