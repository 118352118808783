import { StyleSheet } from 'react-native'

import {
  getBottomSpace,
  isIphoneX,
} from 'components/src/services/IphoneXHelper'

export const tabBarStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 8,
    height: 50 + getBottomSpace(),
    backgroundColor: '#ffffff',
  },
})

export const tabItemStyles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 60,
    paddingBottom: isIphoneX() ? 0 : 10,
  },
})
